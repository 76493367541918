import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SignupComponent} from "./signup.component";
import {ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {ProfilePicModule} from "../../components/profile-pic/profile-pic.module";
import {MatButtonModule} from "@angular/material/button";
import {LangModule} from "../../../../lang.module";



@NgModule({
  declarations: [SignupComponent],
  exports: [SignupComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatAutocompleteModule,
    ProfilePicModule,
    MatButtonModule,
    LangModule
  ]
})
export class SignupModule { }
