import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ProductImagesComponent} from "./product-images.component";
import {NgxDropzoneModule} from "ngx-dropzone";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {LangModule} from "../../../../lang.module";



@NgModule({
  declarations: [ProductImagesComponent],
  exports: [ProductImagesComponent],
  imports: [
    CommonModule,
    NgxDropzoneModule,
    MatButtonModule,
    MatIconModule,
    LangModule
  ]
})
export class ProductImagesModule { }
