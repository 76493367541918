import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {LoginComponent} from "./login.component";
import {ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {RouterModule} from "@angular/router";
import {AngularTelegramLoginWidgetModule} from 'angular-telegram-login-widget';
import {TelegramLoginModule} from '../../components/telegram-login/telegram-login.module';
import {LangModule} from "../../../../lang.module";
import {MatDialogModule} from "@angular/material/dialog";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";



@NgModule({
  declarations: [LoginComponent],
  exports: [
    LoginComponent
  ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        RouterModule,
        TelegramLoginModule,
        LangModule,
        MatDialogModule,
      FontAwesomeModule
    ]
})
export class LoginModule { }
