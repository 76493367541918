import {Component, ElementRef, HostBinding, OnInit, ViewChild} from '@angular/core';
import {RegisterService} from './modules/auth/service/register.service';
import {StorageService} from './core/services/storage.service';
import {AuthService} from './modules/auth/service/auth.service';
import {RegisteredUserGuard} from './core/guards/auth/registered-user.guard';
import {AuthGuard} from './core/guards/auth/auth.guard';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter } from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmUserComponent} from './modules/auth/components/confirm-user/confirm-user.component';
import {environment} from '../environments/environment';
import {TranslateService} from '@ngx-translate/core';
declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  search = false;
  @ViewChild('drawer') drawer;
  @ViewChild('container') container;
  title = 'serdo';
  selectedTab = 0;
  constructor(
    private translate: TranslateService,
    private storageService: StorageService,
    private register: RegisterService,
    private auth: AuthService,
    private storage: StorageService,
    private authGuard: AuthGuard,
    private activeRoute: ActivatedRoute,
    private dialog: MatDialog,
    private elRef: ElementRef,
    public router: Router){

    this.storageService.setLanguage(this.storageService.getLanguage('lang') || 'en');
    translate.setDefaultLang(this.storageService.getLanguage('lang'));
    translate.use(this.storageService.getLanguage('lang'));
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(() => {
      gtag('config', 'UA-163414282-1');
      if (!!this.auth.isLogedIn() && !this.auth.decodeToken()?.isUnregistered){
        if (!this.auth.userVerified()) {
          this.dialog.open(ConfirmUserComponent, {
            width: '380px',
            disableClose: true,
            id: 'confirmation'
          });
        }
      }
    });
  }
  ngOnInit(): void {
    if (!this.authGuard.canActivate(null, null)){
      this.register.createUnregisteredUser().subscribe();
    } else{
        this.auth.refreshToken(this.storage.getRefreshToken()).subscribe();
    }
    const that = this;
    window.addEventListener('storage', (e) => {
      if (!!that.auth.isLogedIn() && !that.auth.decodeToken()?.isUnregistered){
        if (!that.auth.userVerified()) {
          that.dialog.open(ConfirmUserComponent, {
            width: '380px',
            disableClose: true,
            id: 'confirmation'
          });
        }
      }
    });
    if (!!that.auth.isLogedIn() && !that.auth.decodeToken()?.isUnregistered){
      if (!that.auth.userVerified()) {
        that.dialog.open(ConfirmUserComponent, {
          width: '380px',
          disableClose: true,
          id: 'confirmation'
        });
      }
    }
    // this.activeRoute.queryParams.subscribe(
    //   (queryParam) => {
    //     console.log(queryParam);
    //     if (queryParam.confirm === 'true'){
    //       console.log(this.auth.userVerified());
    //       // this.step = 2;
    //       if (!this.auth.userVerified()) {
    //         this.dialog.open(ConfirmUserComponent, {
    //           width: '380px',
    //           disableClose: true,
    //         });
    //       }
    //     }
    //   }
    // );
  }
  @HostBinding('class')
  get themeMode() {
    return 'dark-heme';
  }

  call() {
    window.open('tel:9596');
  }

  onActivate($event: any): void {
    this.elRef.nativeElement.querySelector('.mat-drawer-content').scrollTop = 0;
    console.log(this.elRef.nativeElement.querySelector('.mat-drawer-content').scrollTop );
    // this.drawer.close();
    // this.top.nativeElement.scrollIntoView();
  }
}
