import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PaymentFormDialogComponent} from "./payment-form-dialog.component";
import {PayFormModule} from "../../forms/pay-form/pay-form.module";



@NgModule({
  declarations: [PaymentFormDialogComponent],
  exports: [PaymentFormDialogComponent],
  imports: [
    CommonModule,
    PayFormModule
  ]
})
export class PaymentFormDialogModule { }
