import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmUserDialogComponent } from './confirm-user-dialog.component';
import {ConfirmUserModule} from '../../components/confirm-user/confirm-user.module';



@NgModule({
  declarations: [ConfirmUserDialogComponent],
    imports: [
        CommonModule,
        ConfirmUserModule
    ]
})
export class ConfirmUserDialogModule { }
