import {Component, Input, OnInit} from '@angular/core';
import {StarRatingComponent} from 'ng-starrating';
import { support_webp_format } from '../../../../core/util/image';

@Component({
  selector: 'app-product-tumbniel',
  templateUrl: './product-tumbniel.component.html',
  styleUrls: ['./product-tumbniel.component.scss']
})
export class ProductTumbnielComponent implements OnInit {
  @Input() product: any;
  @Input() row = false;
  @Input() ratting = true;
  @Input() width = '160px';
  productImages;
  imageUrl = '/assets/placeholders/product-image-place holder.svg';

  constructor() { }

  ngOnInit(): void {
    this.productImages = this.product?.node?.productimageSet?.edges;
    if  (!!this.productImages && this.productImages.length > 0) {
      const img = this.product.node.productimageSet.edges[0].node;
      // if (!!img) {
      if (support_webp_format() && img.imgTumbWebpUrl){
        this.imageUrl = img.imgTumbWebpUrl;
      }else{
        this.imageUrl = img.imgTumbUrl;
      }
      // }
    }
  }

  onRate($event: { oldValue: number; newValue: number; starRating: StarRatingComponent }) {
    console.log($event);
  }
}
