<div *ngIf="view">
  <h1 style="margin-left: 2em">{{title| translate}}</h1>
  <div class="flex" style="flex-flow: wrap; justify-content: center" *ngIf="!loading">
    <mat-card
      *ngFor="let business of businesses"
      style="width:160px;margin: 0.5em" class="flex-col">
      <app-business-tumbniel
        [business]="business.node"
        (click)="selectBusiness.emit(business.node)"
      ></app-business-tumbniel>
    </mat-card>
    <mat-card style="width:160px;margin: 0.5em" (click)="view = false" *ngIf="addBusiness || superUserGuard.canActivate(null, null)">
      <div>
        <mat-icon style="
          text-align: center;
          font-size: 5em;
          background: -webkit-linear-gradient(yellow, orange);
          -webkit-background-clip: text;
           -webkit-text-fill-color: transparent;
          width: 100%;
          height: 100%;">add_circle_outline
        </mat-icon>
      </div>
      <div style="padding: 0 1em">
        <mat-card-title style="font-size: 1.2em">{{'Add Business'| translate}}</mat-card-title>
        <mat-card-subtitle>{{'Add your new business'| translate}}</mat-card-subtitle>
      </div>
    </mat-card>
  </div>
</div>
<div class="flex" *ngIf="loading" style="width: 100%; justify-content: space-around;height: 380px">
  <mat-spinner></mat-spinner>
</div>
<div *ngIf="!view">
  <button mat-button (click)="view = true">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <mat-card id="add-business-form">
    <mat-card-content>
      <app-business-form *ngIf="!view" (created)="selectBusiness.emit($event)" [phone]="true"></app-business-form>
    </mat-card-content>
  </mat-card>
</div>
