import { Component } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import {CategoryService} from '../../../modules/product/service/category.service';
import {map} from 'rxjs/operators';

/** File node data with possible child nodes. */
export interface FileNode {
  id: string;
  icon: string;
  parentCategory: string;
  name: string;
  type?: string;
  children?: FileNode[];
}

/**
 * Flattened tree node that has been created from a FileNode through the flattener. Flattened
 * nodes include level index and whether they can be expanded or not.
 */
export interface FlatTreeNode {
  id: string;
  icon: string;
  parentCategory: string;
  name: string;
  level: number;
  expandable: boolean;
}

@Component({
  selector: 'app-category-tree-list',
  templateUrl: './category-tree-list.component.html',
  styleUrls: ['./category-tree-list.component.scss']
})
export class CategoryTreeListComponent {

  /** The TreeControl controls the expand/collapse state of tree nodes.  */
  treeControl: FlatTreeControl<FlatTreeNode>;

  /** The TreeFlattener is used to generate the flat list of items from hierarchical data. */
  treeFlattener: MatTreeFlattener<FileNode, FlatTreeNode>;

  /** The MatTreeFlatDataSource connects the control and flattener to provide data. */
  dataSource: MatTreeFlatDataSource<FileNode, FlatTreeNode>;

  categories = [];
  constructor(private categoryService: CategoryService) {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren);

    this.treeControl = new FlatTreeControl(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    // this.dataSource.data = files;
    this.categoryService.getCategories(false, null, null, false)
      .pipe(
        map( (categories) => categories.map((e) => e.node))
      )
      .subscribe(
      (categories) => {

        this.categories = categories.map((e) => {
          e.children = [];
          return e;
        });
        this.dataSource.data = this.categories;
      }
    );
  }
  buildTree(categories): any{
    categories.forEach(
      (e) => this.getCategoryChildren(e));
    return categories;
  }
  async getCategoryChildren(category){
    category.children = await this.categoryService
      .getCategories(false, category.id, null, false)
      .pipe(
        map( (categories) => categories.map((e) => e.node))
      ).toPromise();
    this.dataSource.data = this.categories;
    // if (category.children.length === 0){return; }
    // category.children.forEach((e)  => {
    //   this.getCategoryChildren(e);
    // });
  }
  /** Transform the data to something the tree can read. */
  transformer(node: FileNode, level: number) {
    return {
      id: node.id,
      icon: node.icon,
      parentCategory: node.parentCategory,
      name: node.name,
      level: level,
      expandable: (node.children.length > 0)
    };
  }

  /** Get the level of the node */
  getLevel(node: FlatTreeNode) {
    return node.level;
  }

  /** Get whether the node is expanded or not. */
  isExpandable(node: FlatTreeNode) {
    return node.expandable;
  }

  /** Get whether the node has children or not. */
  hasChild(index: number, node: FlatTreeNode) {
    return node.expandable;
  }

  /** Get the children for the node. */
  getChildren(node: FileNode): FileNode[] | null | undefined {
    return node.children;
  }
}
