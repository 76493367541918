import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeftNavDrawerComponent } from './left-nav-drawer/left-nav-drawer.component';
import { FooterComponent } from './footer/footer.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {TopNavBarComponent} from './top-nav-bar/top-nav-bar.component';



@NgModule({
  declarations: [LeftNavDrawerComponent, FooterComponent, TopNavBarComponent],
  imports: [
    CommonModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
  ],
  exports: [
    TopNavBarComponent,
    LeftNavDrawerComponent
  ]
})
export class LayoutModule { }
