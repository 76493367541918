import {Injectable} from '@angular/core';
import {Apollo} from 'apollo-angular';
import gql from 'graphql-tag';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private apollo: Apollo) {
  }

  editUser(name: string) {
    const EDIT_USER_MUATION = gql`
      mutation editUser($input: EditUserMutationInput!){
        editUser(input: $input){
          user{
            name
          }
        }
      }
    `;
    const input = {
      name: name
    };
    return this.apollo.mutate({
      mutation: EDIT_USER_MUATION,
      variables: {
        input: input
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.editUser.user)
    );
  }

  searchUsers(phone: string, first = null) {
    const SEARCH_USER_QUERY = gql`
      query serdoUsers($phone: String, $first:  Int) {
        serdoUsers(phone_Icontains: $phone, first: $first){
          edges{
            node{
              id
              name
              phone
              profilePic
            }
          }
        }
      }
    `;
    return this.apollo.query({
      query: SEARCH_USER_QUERY,
      variables: {
        phone: phone,
        first: first
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.serdoUsers.edges)
    );
  }

  staffs(start, end) {
    const STAFFS_USER_QUERY = gql`
      query serdoUsers($start: String, $end: String){
        serdoUsers(isStaff: true, start: $start, end: $end){
          edges{
            node{
              id
              name
              phone
              profilePic
              credit
            }
          }
        }
      }
    `;
    return this.apollo.query({
      query: STAFFS_USER_QUERY,
      variables: {
        start: start,
        end: end,
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.serdoUsers.edges)
    );
  }

  staffCreditUsers(id, start = null, end = null) {
    const STAFFS_USER_QUERY = gql`
      query serdoUser($id: ID!, $start: String, $end: String){
        serdoUsers(referredBy: $id, createdAtStart: $start, createdAtEnd: $end){
          edges{
            node{
                id
                name
                phone
                profilePic
                credit
                businessSet{
                  edges{
                    node{
                      id
                      name
                     }
                  }
                }
              }
           }
        }
      }
    `;
    return this.apollo.query({
      query: STAFFS_USER_QUERY,
      variables: {
        id: id,
        start: start,
        end: end,
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.serdoUsers.edges)
    );
  }

  user(id) {
    const STAFFS_USER_QUERY = gql`
      query user($id: ID!){
        serdoUser(id: $id){
              id
              name
              phone
              profilePic
              credit
              telegramJobBotChatId
              telegramTenderBotChatId
              businessSet{
                edges{
                  node{
                    id
                    name
                    logo
                    tendersubscriptionSet{
                      edges{
                        node{
                          id
                          startAt
                          expireAt
                        }
                      }
                    }
                  }
                }
              }
              usernotificationSet(first: 50){
                  edges{
                    node{
                      id
                      sentViaTelegram
                      sentViaSms
                      createdAt
                      notification{
                        title
                        type
                        job{
                          id
                          title
                          salaryMax
                          salaryMin
                          salaryType
                          description
                          experience
                          qualification{
                            id
                            name
                          }
                          city{
                            id
                            name
                          }
                          vacancy {
                            id
                            postedAt
                            deadline
                            business {
                              id
                              name
                              email
                              isBroker
                              telegram
                              whatsapp
                              latitude
                              longitude
                            }
                          }
                          employmentType{
                            id
                            name
                          }
                          numberPosition
                          category{
                            id
                            name
                          }
                          skills{
                            edges{
                              node{
                                id
                                name
                              }
                            }
                          }
                          fieldOfStudies{
                            edges{
                              node{
                                id
                                name
                              }
                            }
                          }
                        }
                        tender{
                          id
                          title
                          description
                          openingDate
                          closingDate
                          publishingDate
                          createdAt
                          document
                          category{
                            id
                            name
                          }
                          business{
                            id
                            name
                            logo
                            businessType{
                              id
                              name
                            }
                          }
                        }
                      }
                    }
                }
              }
        }
      }
    `;
    return this.apollo.query({
      query: STAFFS_USER_QUERY,
      variables: {
        id: id
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.serdoUser)
    );
  }

}
