import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {map, shareReplay} from 'rxjs/operators';
import {LeftNavDrawerComponent} from '../left-nav-drawer/left-nav-drawer.component';
import {AuthService} from '../../modules/auth/service/auth.service';

@Component({
  selector: 'app-top-nav-bar',
  templateUrl: './top-nav-bar.component.html',
  styleUrls: ['./top-nav-bar.component.scss']
})

export class TopNavBarComponent implements OnInit {
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  @Output() menu: EventEmitter<any> = new EventEmitter();

  constructor(private breakpointObserver: BreakpointObserver, private auth: AuthService) {}

  ngOnInit(): void {
  }
  open(){
    console.log('Hello world');
  }
}
