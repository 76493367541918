import {Component, Inject, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, Validators} from '@angular/forms';
import {RegisterService} from '../../../auth/service/register.service';
import {of} from 'rxjs';
import {map} from 'rxjs/operators';
import {BusinessService} from '../../../product/service/business.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from "@ngx-translate/core";
import {StorageService} from "../../../../core/services/storage.service";

@Component({
  selector: 'app-add-business-user',
  templateUrl: './add-business-user.component.html',
  styleUrls: ['./add-business-user.component.scss']
})
export class AddBusinessUserComponent implements OnInit {
  userForm;
  error = null;
  loading = false;
  emailExistValidator = (resisterService: RegisterService) => (c: AbstractControl) => {
    if (!c || String(c.value).length === 0) {
      return of(null);
    }
    this.registerService.phoneExist(c.value).pipe(
      map((users) => {
        return users ? {exist: true} : null;
      })
    );
    return this.registerService.emailExist(c.value).pipe(
      map((users) => {
        return users ? {exist: true} : null;
      })
    );
  }
  constructor(
    @Inject(MAT_DIALOG_DATA) private data,
    private formBuilder: FormBuilder,
    private matDialogRef: MatDialogRef<any>,
    private registerService: RegisterService,
    private businessService: BusinessService,
    translate: TranslateService , private storageService: StorageService
  ) {
    translate.use(this.storageService.getLanguage('lang'));
  }

  ngOnInit(): void {
    this.userForm = this.formBuilder.group({
      user: ['', Validators.required]
    });
  }

  addBusinessUser() {
    this.loading = true;
    let phone = this.userForm.get('user').value;
    if (phone.slice(0, 2) === '09') {
      phone = '+251' + phone.slice(1, phone.length);
    }
    const input = {
      user: phone,
      business: this.data.business.id
    };
    this.businessService.addBusinessUser(input).subscribe(
      (data) => {
        this.loading = false;
        if (data.user) {
          this.matDialogRef.close(data);
        }else{
          this.error = 'User dose not exist';
        }
      },
      (error) => {
        this.loading = false;
      }
    );
  }
}
