<div style="margin-bottom: 8em;padding-bottom: 1em;">
  <h1 class="mat-h1">{{'Create Business'| translate}}</h1>
  <form [formGroup]="businessForm" (ngSubmit)="submit()" [class.loading-form]="isLoading">
    <p>
      <mat-error *ngFor="let error of formErrors">{{error}}</mat-error>
    </p>
    <div class="flex-col">
      <div class="flex" id="business-name">
        <mat-form-field style="flex: 3;width: 40%" appearance="outline">
          <mat-label>{{'Business name'| translate}}</mat-label>
          <input matInput type="text" required formControlName="name"/>
        </mat-form-field>
        <mat-form-field style="flex: 1;width: 40%"  appearance="outline">
          <mat-label>{{'Business type'| translate}}</mat-label>
          <mat-select required formControlName="businessType">
            <span *ngFor="let sector of businessSectors | async">
              <div style="text-align: center; margin-top: 1em"><b>{{sector.node.name}}</b></div>
              <mat-option *ngFor="let type of sector.node.businesstypeSet.edges" [value]="type.node.id">
              {{type.node.name}}
            </mat-option>
            </span>

          </mat-select>
        </mat-form-field>
      </div>
      <div class="flex" id="social-media-field">
        <mat-form-field appearance="outline" style="width: 45%"  >
          <mat-label>{{'Telegram Link'| translate}}</mat-label>
          <input matInput type="text" formControlName="telegram"/>
        </mat-form-field>
        <mat-form-field appearance="outline"  style="width: 45%" >
          <mat-label>{{'Whatsapp Link'| translate}}</mat-label>
          <input matInput type="text" formControlName="whatsapp"/>
        </mat-form-field>
      </div>
      <mat-form-field appearance="outline">
        <mat-label>{{'Email'| translate}}</mat-label>
        <input matInput type="email" formControlName="email"/>
      </mat-form-field>
      <mat-form-field class="example-chip-list">
        <mat-chip-list #chipList aria-label="Fruit selection">
          <mat-chip
            *ngFor="let phoneNumber of phoneNumbers"
            [selectable]="true"
            [removable]="true"
            (removed)="removed($event)"
          >
            {{phoneNumber}}
            <mat-icon matChipRemove *ngIf="true">{{'cancel'| translate}}</mat-icon>
          </mat-chip>
          <input
            [formControl]="phoneNumberInputControl"
            [placeholder]="'Enter business phone numbers...'| translate"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
            (matChipInputTokenEnd)="add($event)"
            (focusout)="add($event)"
          >
        </mat-chip-list>

      </mat-form-field>
      <div class="flex-col">
        <agm-map style="height: 350px"
                 (mapClick)="selectLocation($event)"
                 [(zoom)]="zoom"
                 [latitude]="location.lat"
                 [longitude]="location.lng">
          <agm-marker *ngIf="coordinates !== null" [latitude]="coordinates.lat" [longitude]="coordinates.lng">
          </agm-marker>
<!--          <agm-marker [latitude]="location.lat"-->
<!--                      [longitude]="location.lng">-->
<!--          </agm-marker>-->
        </agm-map>
      </div>
      <div class="flex-col" style="margin-top: 1.5em">
        <ckeditor id="editor" [editor]="Editor" formControlName="description"></ckeditor>
      </div>
      <button mat-flat-button color="primary" style="margin-top: 1.5em" [disabled]="businessForm.invalid">{{'Submit'| translate}}</button>
    </div>
  </form>

</div>


