import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {ProductService} from '../../service/product.service';
import {Observable} from 'rxjs';
import {ImageCropperDialogComponent} from '../../dialogs/image-cropper-dialog/image-cropper-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {AuthService} from "../../../auth/service/auth.service";
import {TranslateService} from "@ngx-translate/core";
import {StorageService} from "../../../../core/services/storage.service";

@Component({
  selector: 'app-product-form',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductFormComponent implements OnInit {
  @Input() business = null;
  @Input() category = null;
  @Output() productSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Input() save = false;
  @Input() action = 'Sumbit';
  @Input() uploadImage = true;
  @Input() title = 'Post product';
  @Input() set product(value) {
    if (value){
      const product = (value?.node) ? value.node : value;
      this.productForm.patchValue(product);
    }
  }
  productForm = this.formBuilder.group({
    id: [null],
    name: ['', Validators.required],
    price: ['', Validators.required],
    description: [''],
    isNegotiable: [false],
    isRental: [false],
    isService: [false],
    isTobeImported: [false],
    city: [null]
  });
  productCategories: Observable<any>;
  files: File[] = [];
  isLoading = false;
  error = null;
  cities: any;

  constructor(
    private matDialog: MatDialog,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private productService: ProductService,
    translate: TranslateService , private storageService: StorageService) {
    translate.use(this.storageService.getLanguage('lang'));
  }

  ngOnInit(): void {
    this.cities = this.authService.cities();
    this.productCategories = this.productService.productCategories();
  }

  submit() {
    this.isLoading = true;
    const product = this.productForm.value;
    if (this.business){
      product.business = this.business.id;
    }
    if (this.category) {
      product.category = this.category.id;
    }
    if (this.files.length > 0) {
      product.images = this.files;
    }
    if (this.save) {
      let product$;
      if (!product?.id) {
        product$ = this.productService.postProduct(product);
      }else {
        product$ = this.productService.updateProduct(product);
      }
      product$.subscribe(
        ($product) => {
          this.isLoading = false;
          this.productSubmit.emit($product);
        }, (error) => {
          this.isLoading = false;
        }
      );
    }else{
      this.isLoading = false;
      this.productSubmit.emit(product);
    }
  }

  setBusiness(business) {
    this.business = business;
  }

  onSelect(event) {
    // this.files.push(...event.addedFiles);
    this.matDialog.open(ImageCropperDialogComponent,
      {
        width: '580px',
        data: {
          images: event.addedFiles
        }
      }
    ).afterClosed().subscribe(
      (data) => {
        this.files.push(...data.croppedImages);
      }
    );
    // const formData = new FormData();
    // for (let i = 0; i < this.files.length; i++) {
    //   formData.append('file[]', this.files[i]);
    // }
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }
}
