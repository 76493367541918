<div class="container">
  <div class="item" *ngIf="!registeredGuard.canActivate(null, null)">
    <button mat-button (click)="openDialog()">{{'Login'| translate}}</button>
  </div>
  <div class="item">
    <button color="primary" mat-flat-button [matMenuTriggerFor]="postMenu">{{'Post Ad'| translate}}</button>
    <mat-menu #postMenu="matMenu">
      <button mat-menu-item routerLink="/product/post ad">
        <mat-icon>category</mat-icon>
        <span>{{'Post product or service'| translate}}</span>
      </button>
      <button mat-menu-item routerLink="/job/post">
        <mat-icon>work</mat-icon>
        <span>{{'Post job'| translate}}</span>
      </button>
      <button mat-menu-item routerLink="/tender/post">
        <mat-icon></mat-icon>
        <span>{{'Post tender'| translate}}</span>
      </button>
    </mat-menu>
  </div>
  <div class="item" *ngIf="registeredGuard.canActivate(null, null)">
    <button mat-icon-button style="color: #4f4f4f"  [matMenuTriggerFor]="appMenu"><mat-icon>{{'notifications'| translate}}</mat-icon></button>
    <mat-menu #appMenu="matMenu">
      <ng-template matMenuContent>
        <app-small-noti-list></app-small-noti-list>
      </ng-template>
    </mat-menu>
  </div>
  <div class="item" *ngIf="registeredGuard.canActivate(null, null)" style="padding: 0 0.2em">
    <app-profile-pic
      size="35px"
      [pic]="authUser?.profilePic"
      [matMenuTriggerFor]="menu"></app-profile-pic>
  <mat-menu #menu="matMenu">
    <div class="flex-col" style="padding: 1em 1.7em">
      <div class="flex" style="width: 100%; justify-content: space-around">
        <app-profile-pic
          [pic]="authUser?.profilePic"
          [upload]="!authUser?.profilePic"
          ></app-profile-pic>
      </div>
      <div>
        <h3 style="text-align: center">{{authUser?.name}}</h3>
      </div>
      <button mat-menu-item routerLink="/user/profile">
        <mat-icon>person</mat-icon>
        <span>{{'My profile'| translate}}</span>
      </button>
      <button mat-menu-item routerLink="/resume">
        <mat-icon>work</mat-icon>
        <span>{{'My resume'| translate}}</span>
      </button>
      <button mat-menu-item>
        <mat-icon>money</mat-icon>
        <span>{{'Wallet'| translate}} {{authUser?.credit * 10}}</span>
      </button>
      <button mat-menu-item>
        <app-telegram-login
          (loggedIn)="loggedInWithTelegram()"
        ></app-telegram-login>
      </button>
      <button
        (click)="logout()"
        mat-menu-item
        class="flex" style="align-items: center; display: flex">
        <img src="/assets/images/exit.svg" alt="" style="width: 20px; height: 20px;">
        <span style="margin-left: 0.5em"> {{'Logout'| translate}}</span>
      </button>
    </div>

  </mat-menu>


</div>
  <div class="item">
    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
      <mat-icon>language</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="changeLang('en')" >
        <span>English</span>
      </button>
      <button mat-menu-item (click)="changeLang('amh')">
        <span>አማርኛ</span>
      </button>
    </mat-menu>
  </div>
</div>
