import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmUserFormComponent } from './confirm-user-form.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatIconModule} from '@angular/material/icon';
import {LangModule} from "../../../../lang.module";



@NgModule({
  declarations: [ConfirmUserFormComponent],
  exports: [ConfirmUserFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatIconModule,
    LangModule
  ]
})
export class ConfirmUserFormModule { }
