<div id="profile-pic-container" matRipple style="align-items: center;">
  <div class="flex" style="justify-content: space-around">
    <img
      [style.opacity]="loading?0.4:1"
      [style.width]="size"
      [style.height]="size"
      [style.border-radius]="(rounded)?'50%': 0"
      [src]="(pic)?pic:'/assets/images/person.svg'"
      id="profile-pic"
      style="font-size: 48em; border: 1px solid #efefef">
  </div>
  <div class="flex" style="justify-content: space-around" *ngIf="upload">
    <mat-icon
      *ngIf="!loading"
      [style.width]="'calc('+size+'/2)'"
      [style.height]="'calc('+size+'/2)'"
      style="font-size: 50px;
            opacity: .8;
            z-index: 9;
            box-shadow: 0 0 20px rgba(255,255,255,1);
            border-radius: 25%;
            background: rgba(255,255,255,.5);">
      add_a_photo</mat-icon>
    <mat-spinner *ngIf="loading" style="width: 10px; height: 10px;" diameter="5"></mat-spinner>
  </div>
  <ngx-dropzone
    (change)="onSelect($event)"
    [accept]="'image/*'"
    [multiple]="false"
    *ngIf="upload"
    style="height: 110px; overflow: hidden; background: transparent; z-index: 10">
    <ngx-dropzone-image-preview
      ngProjectAs="ngx-dropzone-preview"
      *ngIf="profilePic !== null"
      [file]="profilePic">
      <ngx-dropzone-label>{{ profilePic?.name }}</ngx-dropzone-label>
    </ngx-dropzone-image-preview>
  </ngx-dropzone>
</div>
