import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CategoryPropertyDialogComponent} from "./category-property-dialog.component";
import {CategoryPropertyFormModule} from "../../form/category-property-form/category-property-form.module";



@NgModule({
  declarations: [CategoryPropertyDialogComponent],
  exports: [CategoryPropertyDialogComponent],
  imports: [
    CommonModule,
    CategoryPropertyFormModule
  ]
})
export class CategoryPropertyDialogModule { }
