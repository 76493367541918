import { Component, OnInit } from '@angular/core';
import {NotificationService} from '../../service/notification.service';
import {TranslateService} from "@ngx-translate/core";
import {StorageService} from "../../../../core/services/storage.service";

@Component({
  selector: 'app-small-noti-list',
  templateUrl: './small-noti-list.component.html',
  styleUrls: ['./small-noti-list.component.scss']
})
export class SmallNotiListComponent implements OnInit {
  notifications;
  constructor(private notificationService: NotificationService, translate: TranslateService , private storageService: StorageService
  ) {

    translate.use(this.storageService.getLanguage('lang'));
  }

  ngOnInit(): void {
    this.notificationService.myNotifications().subscribe(
      ({data}) => {
        this.notifications = data.me.notificationSet.edges;
        console.log(data);
        console.log(this.notifications);
      }
    );
  }

}
