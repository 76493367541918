import {Component, OnInit} from '@angular/core';
import {AdService} from '../../modules/ad/service/ad.service';
import {ASSETS_DIR, HOME_PAGE_SIDE_AD} from '../../config/URI.config';
import {RegisteredUserGuard} from '../../core/guards/auth/registered-user.guard';
import {SuperAdminGuard} from '../../core/guards/auth/super-admin.guard';
import {CanActivate, Router} from '@angular/router';
import {UnregisteredUserGuard} from '../../core/guards/auth/unregistered-user.guard';
import {AuthGuard} from "../../core/guards/auth/auth.guard";

interface Nav {
  name: string;
  route: string;
  guard: CanActivate;
  icon: string;
  isSvg: boolean;
  onClick?: any;
}

@Component({
  selector: 'app-drawer-nav',
  templateUrl: './drawer-nav.component.html',
  styleUrls: ['./drawer-nav.component.scss']
})
export class DrawerNavComponent implements OnInit {
  homePageSideAds = [];
  sideAdPlan = HOME_PAGE_SIDE_AD;
  NAVIGATIONS: Nav[] = [
    {
      name: 'Home',
      icon: 'home',
      guard: this.unregisteredUserGuard,
      route: '/home',
      isSvg: false
    },
    {
      name: 'My Businesses',
      icon: 'storefront',
      guard: this.registeredUserGuard,
      route: '/product/my-business',
      isSvg: false
    },
    {
      name: 'Payment orders',
      icon: 'payments',
      guard: this.registeredUserGuard,
      route: '/payment/order',
      isSvg: false
    },
    {
      name: 'History',
      icon: 'history',
      guard: this.unregisteredUserGuard,
      route: '/histories',
      isSvg: false
    },
    {
      name: 'Saved',
      icon: 'save',
      guard: this.unregisteredUserGuard,
      route: '/histories/saved',
      isSvg: false
    },
    {
      name: 'Pricing',
      icon: 'request_quote',
      guard: this.unregisteredUserGuard,
      route: '/pricing',
      isSvg: false
    },
    {
      name: 'Dashboard',
      icon: 'dashboard',
      guard: this.superAdminGuard,
      route: '/dashboard',
      isSvg: false
    },
    {
      name: 'Download app',
      icon: 'download',
      guard: this.unregisteredUserGuard,
      route: '',
      isSvg: false,
      onClick: () => {
        var link = document.createElement("a");
        link.setAttribute('download', 'Serdo.apk');
        link.href = 'https://play.google.com/store/apps/details?id=com.serdo.app';
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    },
    {
      name: 'Categories',
      icon: 'category',
      guard: this.unregisteredUserGuard,
      route: '',
      isSvg: false,
      onClick: () => {
        this.expand = !this.expand;
      }
    }
  ];
  iconDir = ASSETS_DIR + 'images/';
  expand = true;

  constructor(
    private adService: AdService,
    private router: Router,
    public registeredUserGuard: RegisteredUserGuard,
    public unregisteredUserGuard: UnregisteredUserGuard,
    public  authGuard: AuthGuard,
    public superAdminGuard: SuperAdminGuard) {
  }

  ngOnInit(): void {
  }

  getNavigations() {
    return this.NAVIGATIONS.filter(
      e => e.guard.canActivate(null, null)
    );
  }

  navClick(nav: Nav) {
    this.router.navigate([nav.route]);
  }
}
