import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent implements OnInit {

  constructor(public matDialogRef: MatDialogRef<any>) { }

  ngOnInit(): void {
  }

  close() {
    this.matDialogRef.close();
  }
}
