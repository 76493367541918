import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ResumeListComponent} from "./resume-list.component";
import {MatCardModule} from "@angular/material/card";



@NgModule({
  declarations: [ResumeListComponent],
  exports: [ResumeListComponent],
  imports: [
    CommonModule,
    MatCardModule
  ]
})
export class ResumeListModule { }
