<form [formGroup]="vacancyForm" (ngSubmit)="submit()" [class.loading-form]="loading">
  <div class="flex-col">
    <mat-form-field appearance="outline">
      <mat-label>{{'Vacancy name'| translate}}</mat-label>
      <input matInput type="text" min="2" formControlName="name">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{'Enter a date range'| translate}}</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <input matStartDate [placeholder]="'Start date'| translate" formControlName="postedAt">
        <input matEndDate [placeholder]="'End date'| translate" formControlName="deadline">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
    <button mat-flat-button color="primary">{{'Submit'| translate}}</button>
  </div>
</form>
