import { Component, OnInit, Input } from '@angular/core';
import {ImageCropperDialogComponent} from '../../dialogs/image-cropper-dialog/image-cropper-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import {ProductService} from '../../service/product.service';
import {TranslateService} from "@ngx-translate/core";
import {StorageService} from "../../../../core/services/storage.service";

@Component({
  selector: 'app-product-images',
  templateUrl: './product-images.component.html',
  styleUrls: ['./product-images.component.scss']
})
export class ProductImagesComponent implements OnInit {
  @Input() product;
  files = [];
  constructor(private matDialog: MatDialog, private productService: ProductService,
              translate: TranslateService , private storageService: StorageService) {
    translate.use(this.storageService.getLanguage('lang'));
}

  ngOnInit(): void {
  }

  remove(imageId) {
    const input = {
      images: [imageId]
    };
    this.productService.removeProductImage(input).subscribe(
      (response) => {
        this.product.node.productimageSet.edges = this.product.node.productimageSet.edges.filter(
          (e) => {
            return response.removedImages.map((_e) => _e.id).indexOf(e.node.id) <= -1;
          }
        );
      }
    );
  }


  onSelect(event) {
    // this.files.push(...event.addedFiles);
    this.matDialog.open(ImageCropperDialogComponent,
      {
        width: '580px',
        data: {
          images: event.addedFiles
        }
      }
    ).afterClosed().subscribe(
      (data) => {
        this.files.push(...data.croppedImages);
        const input = {
          product: this.product.node.id,
          images: data.croppedImages
        };
        this.productService.addProductImage(input).subscribe(
          (response) => {
            // console.log(response.product);
            this.product = {node: response.product};
            this.files = [];
          }
        );
      }
    );
    // const formData = new FormData();
    // for (let i = 0; i < this.files.length; i++) {
    //   formData.append('file[]', this.files[i]);
    // }
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }
}
