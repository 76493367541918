import {Component, EventEmitter, Input, Output, OnInit} from '@angular/core';

@Component({
  selector: 'app-ad-plan-list',
  templateUrl: './ad-plan-list.component.html',
  styleUrls: ['./ad-plan-list.component.scss']
})
export class AdPlanListComponent implements OnInit {
  @Input() plans;
  @Output() selectPlan: EventEmitter<any> = new EventEmitter<any>();
  @Input() maxHeight = '1080px';
  constructor() { }

  ngOnInit(): void {
  }

  onSelectPlan(plan) {
    this.selectPlan.emit(plan);
  }
}
