import { Injectable } from '@angular/core';
import {Apollo} from 'apollo-angular';
import gql from 'graphql-tag';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BankService {

  constructor(private apollo: Apollo) { }

  getBankAccounts() {
    const bankAccountsQuery = gql`
      query bankAccounts {
        bankAccounts{
          edges{
            node{
              id
              bankName
              accountName
              accountNumber
            }
          }
        }
      }
    `;
    return this.apollo.query({
      query: bankAccountsQuery
    }).pipe(
      // @ts-ignore
      map(({data}) => data.bankAccounts.edges)
    );
  }
}
