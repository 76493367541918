import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdvertiseProductRoutingModule } from './advertise-product-routing.module';
import { AdvertiseProductComponent } from './advertise-product.component';
import {PackageListModule} from "../../list/package-list/package-list.module";
import {AdPlanListModule} from "../../list/ad-plan-list/ad-plan-list.module";


@NgModule({
  declarations: [AdvertiseProductComponent],
  exports: [
    AdvertiseProductComponent
  ],
  imports: [
    CommonModule,
    AdvertiseProductRoutingModule,
    PackageListModule,
    AdPlanListModule
  ]
})
export class AdvertiseProductModule { }
