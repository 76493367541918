import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import gql from 'graphql-tag';
import {Apollo} from 'apollo-angular';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class JobService {

  constructor(private apollo: Apollo) {
  }

  employmentTypes(): Observable<any> {
    const EMPLOYMENT_TYPES_QUERY = gql`
      query employmentTypes {
        employmentTypes {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `;
    return this.apollo.query({
      query: EMPLOYMENT_TYPES_QUERY
    }).pipe(
      // @ts-ignore
      map(({data}) => data.employmentTypes.edges)
    );
  }

  employmentType(id): Observable<any> {
    const EMPLOYMENT_TYPE_QUERY = gql`
      query employmentTypes($id: ID!) {
        employmentType (id: $id){
          id
          name
        }
      }
    `;
    return this.apollo.query({
      query: EMPLOYMENT_TYPE_QUERY,
      variables: {
        id: id
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.employmentType)
    );
  }

  qualifications(): Observable<any> {
    const QUALIFICATIONS = gql`
      query qualifications{
        qualifications{
          edges{
            node{
              id
              name
            }
          }
        }
      }
    `;
    return this.apollo.query({
      query: QUALIFICATIONS
    }).pipe(
      // @ts-ignore
      map(({data}) => data.qualifications.edges)
    );
  }

  qualification(id): Observable<any> {
    const QUALIFICATIONS = gql`
      query qalification ($id: ID!){
        qualification(id: $id){
          edges{
            node{
              id
              name
            }
          }
        }
      }
    `;
    return this.apollo.query({
      query: QUALIFICATIONS,
      variables: {
        id: id
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.qualification)
    );
  }

  skills(): Observable<any> {
    const SKILLS_QUERY = gql`
      query skillsQuery {
        skills{
          edges{
            node{
              id
              name
            }
          }
        }
      }
    `;
    return this.apollo.query({
      query: SKILLS_QUERY
    }).pipe(
      // @ts-ignore
      map(({data}) => data.skills.edges)
    );
  }

  skill(id): Observable<any> {
    const SKILL_QUERY = gql`
      query skillQuery ($id: ID!){
        skill(id: $id){
          id
          name
        }
      }
    `;
    return this.apollo.query({
      query: SKILL_QUERY,
      variables: {
        id: id
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.skill)
    );
  }

  fieldOfStudies(): Observable<any> {
    const FIELD_OF_STUDIES = gql`
      query fieldOfStudies {
        fieldOfStudies{
          edges{
            node{
              id
              name
            }
          }
        }
      }
    `;
    return this.apollo.query({
      query: FIELD_OF_STUDIES
    }).pipe(
      // @ts-ignore
      map(({data}) => data.fieldOfStudies.edges)
    );
  }

  fieldOfStudy(id): Observable<any> {
    const FIELD_OF_STUDIES = gql`
      query fieldOfStudy($id: ID!) {
        fieldOfStudy(id: $id){
          id
          name
        }
      }
    `;
    return this.apollo.query({
      query: FIELD_OF_STUDIES,
      variables: {
        id: id
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.fieldOfStudy)
    );
  }

  jobCategories(first = null): Observable<any> {
    const JOB_CATEGORY = gql`
      query jobCategories($first: Int) {
        jobCategories(first: $first){
          edges{
            node{
              id
              name
              icon
              jobCount
            }
          }
        }
      }
    `;
    return this.apollo.query({
      query: JOB_CATEGORY
    }).pipe(
      // @ts-ignore
      map(({data}) => data.jobCategories.edges)
    );
  }

  jobCategory(id): Observable<any> {
    const JOB_CATEGORY = gql`
      query jobCategory($id: ID!) {
        jobCategory(id: $id){
          id
          name
        }
      }
    `;
    return this.apollo.query({
      query: JOB_CATEGORY,
      variables: {
        id: id
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.jobCategory)
    );
  }

  vacancies(business = null) {
    const VACANCIES_QUERY = gql`
      query vacanciesQuery($business: ID){
        vacancies(business: $business){
          edges{
            node{
              id
              name
              postedAt
              deadline
              business {
                id
                name
                email
                isBroker
                telegram
                whatsapp
                latitude
                longitude
                logo
                businessType{
                  id
                  name
                }
                businessphoneSet{
                  edges{
                    node{
                      id
                      phone
                    }
                  }
                }
              }
            }
          }
        }
      }
    `;
    return this.apollo.query({
      query: VACANCIES_QUERY,
      variables: {
        business: business
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.vacancies.edges)
    );
  }

  vacancy(id, job = false) {
    const VACANCIES_QUERY = gql`
      query vacanciesQuery($id: ID!, $job: Boolean!){
        vacancy(id: $id){
          id
          name
          postedAt
          deadline
          business {
            id
            name
            email
            isBroker
            telegram
            whatsapp
            latitude
            longitude
            isOwner
            logo
            businessphoneSet{
              edges{
                node{
                  id
                  phone
                }
              }
            }
          }
          jobSet @include(if: $job) {
            edges{
              node{
                id
                title
                salaryMax
                salaryMin
                salaryType
                description
                experience
                city{
                  id
                  name
                }
                vacancy {
                  business {
                    id
                    name
                    email
                    isBroker
                    telegram
                    whatsapp
                    latitude
                    longitude
                    logo
                    businessType{
                      id
                      name
                    }
                    businessphoneSet{
                      edges{
                        node{
                          id
                          phone
                        }
                      }
                    }
                  }
                }
                employmentType{
                  id
                  name
                }
                numberPosition
                category{
                  id
                  name
                }
                skills{
                  edges{
                    node{
                      id
                      name
                    }
                  }
                }
                fieldOfStudies{
                  edges{
                    node{
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    `;
    return this.apollo.query({
      query: VACANCIES_QUERY,
      variables: {
        id: id,
        job: job
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.vacancy)
    );
  }

  saveJob(job): Observable<any> {
    const JOB_MUTATION = gql`
      mutation jobMuation($job: JobMutationInput!){
        job(input: $job){
          job{
            id
            title
            salaryMax
            salaryMin
            salaryType
            description
            experience
            city{
              id
              name
            }
            vacancy {
              business {
                id
                name
                email
                isBroker
                telegram
                whatsapp
                latitude
                longitude
                logo
                businessType{
                  id
                  name
                }
                businessphoneSet{
                  edges{
                    node{
                      id
                      phone
                    }
                  }
                }
              }
            }
            employmentType{
              id
              name
            }
            numberPosition
            category{
              id
              name
            }
            skills{
              edges{
                node{
                  id
                  name
                }
              }
            }
            fieldOfStudies{
              edges{
                node{
                  id
                  name
                }
              }
            }
          }
        }
      }
    `;
    return this.apollo.mutate({
      mutation: JOB_MUTATION,
      variables: {
        job: job
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.job.job)
    );
  }

  deleteJob(id): Observable<any> {
    const DELETE_JOB_MUTATION = gql`
      mutation deleteJobMuation($input: JobDeleteMutationInput!){
        deleteJob(input: $input){
          job{
            id
            title
            salaryMax
            salaryMin
            salaryType
            description
            experience
            numberPosition
          }
        }
      }
    `;
    return this.apollo.mutate({
      mutation: DELETE_JOB_MUTATION,
      variables: {
        input: {
          id: id
        }
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.deleteJob.job)
    );
  }

  jobs(vacancy = null, jobCategory = null, name = null) {
    const JOB_QUERY = gql`
      query jobQuery($vacancy: ID, $jobCategory: ID, $name: String){
        jobs(vacancy: $vacancy, category: $jobCategory, title_Icontains: $name){
          edges{
            node{
              id
              title
              salaryMax
              salaryMin
              salaryType
              description
              experience
              qualification{
                id
                name
              }
              city{
                id
                name
              }
              vacancy {
                id
                postedAt
                deadline
                business {
                  id
                  name
                  email
                  isBroker
                  telegram
                  whatsapp
                  latitude
                  longitude
                  logo
                }
              }
              employmentType{
                id
                name
              }
              numberPosition
              category{
                id
                name
              }
              skills{
                edges{
                  node{
                    id
                    name
                  }
                }
              }
              fieldOfStudies{
                edges{
                  node{
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    `;
    return this.apollo.query({
      query: JOB_QUERY,
      variables: {
        vacancy: vacancy,
        jobCategory: jobCategory,
        name: name
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.jobs.edges)
    );
  }

  job(id) {
    const JOB_QUERY = gql`
      query jobQuery($id: ID!){
        job(id: $id){
          id
          owner
          title
          salaryMax
          salaryMin
          salaryType
          description
          experience
          viewCount
          applied
          isSaved
          city{
            id
            name
          }
          vacancy {
            id
            postedAt
            deadline
            business {
              id
              name
              email
              isBroker
              telegram
              whatsapp
              latitude
              longitude
              logo
              businessType{
                id
                name
              }
              businessphoneSet{
                edges{
                  node{
                    id
                    phone
                  }
                }
              }
            }
          }
          employmentType{
            id
            name
          }
          numberPosition
          category{
            id
            name
          }
          skills{
            edges{
              node{
                id
                name
              }
            }
          }
          fieldOfStudies{
            edges{
              node{
                id
                name
              }
            }
          }

        }
      }
    `;
    return this.apollo.query({
      query: JOB_QUERY,
      variables: {
        id: id,
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.job)
    );
  }

  makeJobsPublic(jobs): Observable<any> {
    const PUBLIC_JOBS_MUTATION = gql`
      mutation makePublicJobMuation($input: PublicJobMutationInput!){
        publicJob(input: $input){
          jobs{
            id
            title
            salaryMax
            salaryMin
            salaryType
            description
            experience
            city{
              id
              name
            }
            employmentType{
              id
              name
            }
            numberPosition
            category{
              id
              name
            }
            skills{
              edges{
                node{
                  id
                  name
                }
              }
            }
            fieldOfStudies{
              edges{
                node{
                  id
                  name
                }
              }
            }
          }
        }
      }
    `;
    const input = {
      jobs: jobs
    };
    return this.apollo.mutate({
      mutation: PUBLIC_JOBS_MUTATION,
      variables: {
        input: input
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.publicJob)
    );
  }

  createVacancy(vacancy): Observable<any> {
    const CREATE_VACANCY_MUTATION = gql`
      mutation createVacancyMutation($vacancy: VacancyMutationInput!){
        vacancy(input: $vacancy) {
          vacancy{
            id
            name
            postedAt
            deadline
          }
        }
      }
    `;
    return this.apollo.mutate({
      mutation: CREATE_VACANCY_MUTATION,
      variables: {
        vacancy: vacancy
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.vacancy)
    );
  }

  apply(input): Observable<any> {
    const JOB_APPLICATION_MUTATION = gql`
      mutation JOB_APPLICATION_MUTATION($input: ApplyMutationInput!){
        jobApplication(input: $input){
          application{
            id
            job{
              id
            }
          }
        }
      }
    `;
    return this.apollo.mutate({
      mutation: JOB_APPLICATION_MUTATION,
      variables: {
        input: input
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.jobApplication.application)
    );
  }

  save_job(input): Observable<any> {
    const SAVE_JOB_MUTATION = gql`
      mutation SAVE_JOB_MUTATION($input: SaveJobMutationInput!){
        saveJob(input: $input) {
          userJobView {
            id
            job {
              id
            }
            user {
              id
            }
          }
        }
      }
    `;
    return this.apollo.mutate({
      mutation: SAVE_JOB_MUTATION,
      variables: {
        input: input
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.saveJob)
    );
  }

  getApplications(job) {
    const JOB_APPLICATION_QUERY = gql`
      query jobApplications($job: ID) {
        jobApplications(job: $job) {
          edges {
            node {
              id
              job{
                id
                title
              }
              resume {
                id
                user {
                  id
                  name
                  profilePic
                  phone
                  userresumeprofile {
                    id
                    firstName
                    fatherName
                    grandFatherName
                    dateOfBirth
                    gender
                  }
                }
              }
              hired
              passed
            }
          }
        }
      }

    `;
    return this.apollo.query({
      query: JOB_APPLICATION_QUERY,
      variables: {
        job: job
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.jobApplications)
    );
  }

  getApplication(id) {
    const JOB_APPLICATION_QUERY = gql`

      query JobApplication($id: ID!){
        jobApplication(id: $id){
          id
          hired
          passed
        }

      }

    `;
    return this.apollo.query({
      query: JOB_APPLICATION_QUERY,
      variables: {
        id: id
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.jobApplications)
    );
  }
}
