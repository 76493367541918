import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {LoginOrSignupDialogComponent} from "./login-or-signup-dialog.component";
import {LoginOrSignupModule} from "../login-or-signup/login-or-signup.module";



@NgModule({
  declarations: [LoginOrSignupDialogComponent],
  exports: [LoginOrSignupDialogComponent],
  imports: [
    CommonModule,
    LoginOrSignupModule
  ]
})
export class LoginOrSignupDialogModule { }
