import {Component, EventEmitter, Input, Output, OnInit} from '@angular/core';
import {PackageService} from '../../service/package.service';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-package-list',
  templateUrl: './package-list.component.html',
  styleUrls: ['./package-list.component.scss']
})
export class PackageListComponent implements OnInit {
  @Input() title = 'Ad packages';
  @Output() selectPackage: EventEmitter<any> = new EventEmitter<any>();
  packages;
  @Input() maxHeight = '1080px';
  @Input() packageCategory = '1';
  loading = false;
  constructor(private packageService: PackageService) { }

  ngOnInit(): void {
    this.loading = true;
    this.packageService.getPackages(this.packageCategory).subscribe(
      (pack) => {
        this.loading = false;
        this.packages = pack;
      }, (error) => {
        this.loading = false;
      }
    );
  }

  onSelectPackage($package: any) {
    this.selectPackage.emit($package);
  }
}
