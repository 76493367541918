import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-login-or-signup-dialog',
  templateUrl: './login-or-signup-dialog.component.html',
  styleUrls: ['./login-or-signup-dialog.component.scss']
})
export class LoginOrSignupDialogComponent implements OnInit {

  constructor(public dialog: MatDialogRef<any>) { }

  ngOnInit(): void {
  }

  close() {
    this.dialog.close({login: true});
  }
}
