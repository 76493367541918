<h2>{{'Add business user'| translate}}</h2>
<form [formGroup]="userForm" (ngSubmit)="addBusinessUser()">
  <mat-form-field appearance="outline" style="width: 100%">
    <mat-label>{{'Business user'| translate}}</mat-label>
    <input matInput type="text" formControlName="user"/>
    <mat-hint>{{'Enter user email or phone'| translate}}</mat-hint>
  </mat-form-field>
  <div class="flex-col">
    <p style="text-align: center"><mat-error>{{error}}</mat-error></p>
    <button mat-flat-button color="primary">{{'Add user'| translate}}</button>
  </div>
</form>
