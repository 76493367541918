<h2 class="mat-h2">{{'Possible values'| translate}}</h2>
<mat-list style="max-height: 320px; overflow-y: scroll; overflow-x: hidden" *ngIf="!loading">
  <mat-list-item *ngFor="let value of possibleValues" class="flex">
    <div style="flex: 3">{{value.node.value }}</div>
    <div style="flex: 1">
        <mat-icon mat-icon-button (click)="edit(value.node)">edit</mat-icon>
        <mat-icon mat-icon-button (click)="trash(value.node.id)" color="warn">close</mat-icon>
    </div>
  </mat-list-item>
</mat-list>
<div class="flex"  *ngIf="loading" style="width: 100%; justify-content: space-around">
  <mat-spinner></mat-spinner>
</div>
<form [formGroup]="possibleValuesForm" (ngSubmit)="submit()" class="flex-col">
  <mat-form-field style="width: 100%">
    <mat-label>{{'Value'| translate}}</mat-label>
    <input matInput type="text" formControlName="value"/>
  </mat-form-field>
</form>
