import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {BankService} from '../../service/bank.service';
import {MatDialogRef} from '@angular/material/dialog';
import {PaymentOrderService} from '../../service/payment-order.service';
import {TranslateService} from "@ngx-translate/core";
import {StorageService} from "../../../../core/services/storage.service";

@Component({
  selector: 'app-pay-form',
  templateUrl: './pay-form.component.html',
  styleUrls: ['./pay-form.component.scss']
})
export class PayFormComponent implements OnInit {

  isLoading = false;
  payForm = this.formBuilder.group({
    bankAccountId: ['', Validators.required],
    transactionId: ['', Validators.required],
  });
  public banks: Observable<any>;
  @Input() order;
  @Output() confirmPayment: EventEmitter<any> = new EventEmitter();
  constructor(
    private formBuilder: FormBuilder,
    private bankService: BankService,
    private paymentOrderService: PaymentOrderService,
    translate: TranslateService , private storageService: StorageService
  ) {
    translate.use(this.storageService.getLanguage('lang'));
  }
  dialog;
  ngOnInit(): void {
    this.banks = this.bankService.getBankAccounts();
  }

  submit() {
    this.isLoading = true;
    const input = this.payForm.value;
    input.paymentOrderId = this.order.id;
    this.paymentOrderService.paymentConfirmationRequest(input).subscribe(
      (paymentOrder) => {
        this.isLoading = false;
        this.confirmPayment.emit(paymentOrder);
      }
    );
  }
}
