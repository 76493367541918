import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from "@ngx-translate/core";
import {StorageService} from "../../../../core/services/storage.service";

@Component({
  selector: 'app-vacancy-form-dialog',
  templateUrl: './vacancy-form-dialog.component.html',
  styleUrls: ['./vacancy-form-dialog.component.scss']
})
export class VacancyFormDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data, private matDialogRef: MatDialogRef<any>,
              translate: TranslateService , private storageService: StorageService
  ) {

    translate.use(this.storageService.getLanguage('lang'));
  }

  ngOnInit(): void {
  }
  close(vacancy) {
    this.matDialogRef.close(vacancy);
  }


}
