import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AdPlanTumbneilComponent} from "./ad-plan-tumbneil.component";
import {PriceModule} from "../../../product/components/price/price.module";
import {MatCardModule} from "@angular/material/card";
import {HttpClient} from "@angular/common/http";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


@NgModule({
  declarations: [AdPlanTumbneilComponent],
  exports: [AdPlanTumbneilComponent],
  imports: [
    CommonModule,
    PriceModule,
    MatCardModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })

  ]
})
export class AdPlanTumbneilModule { }
