import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {BusinessService} from '../../service/business.service';
import {FormControl} from '@angular/forms';
import {ProductService} from '../../service/product.service';
import {MatDialogRef} from '@angular/material/dialog';
import {map} from 'rxjs/operators';
import {TranslateService} from "@ngx-translate/core";
import {StorageService} from "../../../../core/services/storage.service";

@Component({
  selector: 'app-select-products',
  templateUrl: './select-products.component.html',
  styleUrls: ['./select-products.component.scss']
})
export class SelectProductsComponent implements OnInit {
  businesses = [];
  products;
  selectedProducts = [];
  selectedBusiness = null;
  constructor(
    private businessService: BusinessService,
    private productService: ProductService,
    private dialog: MatDialogRef<any>,
    translate: TranslateService , private storageService: StorageService
  ) {
    translate.use(this.storageService.getLanguage('lang'));
  }

  ngOnInit(): void {
    this.businessService.myBusiness().subscribe(
      (businesses) => {
        this.businesses = businesses;
        if (!!this.businesses && this.businesses.length >= 0){
          this.onChangeBusinessSelection({value: this.businesses[0]});
        }
      }
    );
  }

  onChangeBusinessSelection($event: any) {
    if (!!$event.value){
      this.productService.getBusinessProduct($event.value?.node.id).subscribe(
        (products) => {
          this.products = products;
        }
      );
    }
  }
  onChangeProductSelection($event: any) {
    if ($event.product.selected){
      this.selectedProducts.push($event.product);
    }else{
      this.selectedProducts = this.selectedProducts.filter(
        (e) => e.node.id !== $event.product.node.id
      );
    }
  }
  onSelect() {
    console.log(this.selectedProducts);
    this.dialog.close({
      selectedProducts: this.selectedProducts
    });
  }
  onBusinessSelect(business) {
    this.selectedBusiness = business;
  }

}
