import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainNavComponent } from './main-nav/main-nav.component';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {AuthModule} from '../modules/auth/auth.module';
import {PostAdComponent} from '../modules/product/pages/post-ad/post-ad.component';
import {ProductRoutingModule} from '../modules/product/product-routing.module';
import {LoginOrSignupDialogComponent} from '../modules/auth/login-or-signup-dialog/login-or-signup-dialog.component';
import { DrawerNavComponent } from './drawer-nav/drawer-nav.component';
import {MatListModule} from '@angular/material/list';
import {MatIconModule} from '@angular/material/icon';
import {RouterModule} from '@angular/router';
import {AdModule} from '../modules/ad/ad.module';
import { CategoryTreeListComponent } from './components/category-tree-list/category-tree-list.component';
import { MatTreeModule } from '@angular/material/tree';
import {MatMenuModule} from '@angular/material/menu';
import {BannerModule} from '../modules/ad/components/banner/banner.module';
import {ProfilePicModule} from '../modules/auth/components/profile-pic/profile-pic.module';
import {ListsModule} from '../modules/notification/lists/lists.module';
import { IconModule } from '../core/modules/common/icon/icon.module';
import {TelegramLoginModule} from '../modules/auth/components/telegram-login/telegram-login.module';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {HttpClient} from "@angular/common/http";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {MatFormFieldModule} from "@angular/material/form-field";
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
    declarations: [
        MainNavComponent,
        DrawerNavComponent,
        CategoryTreeListComponent
    ],
    exports: [
        MainNavComponent,
        DrawerNavComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatFormFieldModule,
        MatDialogModule,
        MatListModule,
        MatIconModule,
        RouterModule,
        BannerModule,
        MatTreeModule,
        MatMenuModule,
        ProfilePicModule,
        ListsModule,
        IconModule,
        TelegramLoginModule,
        MatSnackBarModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ]
})
export class NavigationModule { }
