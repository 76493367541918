<mat-icon
  [class.gradient]="gradient"
  [style.font-size]="size ? size: '2.5em'"
  *ngIf="!!matIcon">{{matIcon}}</mat-icon>
<mat-icon
  [class.gradient]="gradient"
  [style.font-size]="size ? size: '2.5em'"
  *ngIf="!!fontawsomeIcon"
  [fontSet]="fontawsomeIcon[0]"
  [fontIcon]="fontawsomeIcon[1]"
></mat-icon>
