import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {NavigationModule} from './navigation/navigation.module';
import {PricingComponent} from './shared/pages/pricing/pricing.component';


const routes: Routes = [
  { path: 'histories', loadChildren: () => import('./modules/history/history.module').then(m => m.HistoryModule) },
  { path: 'notification', loadChildren: () => import('./modules/notification/notification.module').then(m => m.NotificationModule) },
  { path: 'resume', loadChildren: () => import('./modules/resume/resume.module').then(m => m.ResumeModule) },
  { path: 'job', loadChildren: () => import('./modules/job/job.module').then(m => m.JobModule) },
  { path: 'product', loadChildren: () => import('./modules/product/product.module').then(m => m.ProductModule) },
  { path: 'home', loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule)},
  { path: 'user', loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)},
  { path: 'search', loadChildren: () => import('./modules/search/search.module').then(m => m.SearchModule)},
  { path: 'ad', loadChildren: () => import('./modules/ad/ad.module').then(m => m.AdModule)},
  { path: 'payment', loadChildren: () => import('./modules/payment/payment.module').then(m => m.PaymentModule)},
  { path: 'dashboard', loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)},
  { path: 'about', loadChildren: () => import('./shared/pages/about-us/about-us.module').then(m => m.AboutUsModule) },
  { path: 'business', loadChildren: () => import('./modules/business/business.module').then(m => m.BusinessModule) },
  { path: 'tender', loadChildren: () => import('./modules/tender/tender.module').then(m => m.TenderModule) },
  { path: 'pricing', loadChildren: () => import('./shared/pages/pricing/pricing.module').then(m => m.PricingModule) },
  { path: '', redirectTo: '/home', pathMatch: 'full'},
  { path: 'employer', loadChildren: () => import('./modules/job/page/employer-page/employer-page.module').then(m => m.EmployerPageModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes), NavigationModule],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
