import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {LoginOrSignupComponent} from "./login-or-signup.component";
import {LoginModule} from "../forms/login/login.module";
import {SignupModule} from "../forms/signup/signup.module";



@NgModule({
  declarations: [LoginOrSignupComponent],
  exports: [LoginOrSignupComponent],
  imports: [
    CommonModule,
    LoginModule,
    SignupModule
  ]
})
export class LoginOrSignupModule { }
