import { Injectable } from '@angular/core';
import {StarRatingConfigService as StarRatingConfigServiceParent} from 'angular-star-rating';

@Injectable({
  providedIn: 'root'
})
export class StarRatingConfigService extends StarRatingConfigServiceParent{

  constructor() {
    super();
    this.assetsPath = 'static/assets/images/';
  }
}
