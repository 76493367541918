import {Component, Inject, OnInit} from '@angular/core';
import {ResumeService} from '../../service/resume.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {TranslateService} from "@ngx-translate/core";
import {StorageService} from "../../../../core/services/storage.service";

@Component({
  selector: 'app-resume-subscription-dialog',
  templateUrl: './resume-subscription-dialog.component.html',
  styleUrls: ['./resume-subscription-dialog.component.scss']
})
export class ResumeSubscriptionDialogComponent implements OnInit {
  loading = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private resumeService: ResumeService,
    public matDialogRef: MatDialogRef<any>,
    private snackBar: MatSnackBar,
    private route: Router,
    private translate: TranslateService , private storageService: StorageService

  ) {

    translate.use(this.storageService.getLanguage('lang'));
  }

  ngOnInit(): void {
  }

  confirm() {
    this.loading = true;
    this.resumeService.createResumeSubscription(this.data.resume.id, this.data.plan.id).subscribe(
      (data) => {
        this.loading = false;
        this.matDialogRef.close();
        const snackBarRef = this.snackBar.open(this.translate.instant('Successfully created resume subscription...'), this.translate.instant('Go to orders'), {
          duration: 5000
        });
        snackBarRef.onAction().subscribe(
          () => {
            this.route.navigate(['/payment/order']);
          }
        );
      }, (error) => {
        this.snackBar.open(this.translate.instant('Failed to create resume subscription...'), this.translate.instant('Retry'), {
          duration: 5000
        });
      }
    );
  }
}
