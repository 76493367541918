import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {BusinessTumbnielComponent} from "./business-tumbniel.component";
import {MatCardModule} from "@angular/material/card";
import {ProfilePicModule} from "../../../auth/components/profile-pic/profile-pic.module";
import {LangModule} from "../../../../lang.module";



@NgModule({
  declarations: [BusinessTumbnielComponent],
  exports: [BusinessTumbnielComponent],
  imports: [
    CommonModule,
    MatCardModule,
    ProfilePicModule,
    LangModule
  ]
})
export class BusinessTumbnielModule { }
