<h3>Change password</h3>
<div [class.loading-form]="isLoading">
  <p *ngIf="message?.success" style="color: green">You have successfully changed password.</p>
  <div *ngIf="message?.success === false">
    <mat-error *ngFor="let err of errors">{{err.message}}</mat-error>
  </div>
  <form [formGroup]="changePasswordForm" (ngSubmit)="submit()" class="flex-col">
    <mat-form-field appearance="outline">
      <mat-label>Current password</mat-label>
      <input matInput type="password" formControlName="oldPassword" required/>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>New password</mat-label>
      <input matInput type="password" formControlName="newPassword1" required/>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Confirm new password</mat-label>
      <input matInput type="password" formControlName="newPassword2" required/>
      <mat-error *ngIf="changePasswordForm.controls['newPassword2'].hasError('match')">
        Confirmation dosen't match.
      </mat-error>
    </mat-form-field>
    <button mat-flat-button color="primary" type="submit" [disabled]="changePasswordForm.invalid">Change</button>
  </form>
</div>
