import {Component, Input, Output, OnInit, EventEmitter} from '@angular/core';
import {NgxDropzoneChangeEvent} from 'ngx-dropzone';
import {ImageCropperDialogComponent} from '../../../product/dialogs/image-cropper-dialog/image-cropper-dialog.component';
import {AuthService} from '../../service/auth.service';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-profile-pic',
  templateUrl: './profile-pic.component.html',
  styleUrls: ['./profile-pic.component.scss']
})
export class ProfilePicComponent implements OnInit {
  @Input() pic = null;
  @Input() upload = false;
  @Input() save = true;
  @Input() size = '100px';
  @Input() rounded = true;
  @Output() selectedImage: EventEmitter<any> = new EventEmitter<any>();

  profilePic = null;
  loading = false;
  toBase64 = file => new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
          })
  constructor(private authService: AuthService, private matDialog: MatDialog) { }

  ngOnInit(): void {
  }


  onSelect($event: NgxDropzoneChangeEvent) {
    this.matDialog.open(ImageCropperDialogComponent,
      {
        width: '580px',
        data: {
          images: $event.addedFiles,
          ratio: {
            w: 1,
            h: 1
          }
        }
      }
    ).afterClosed().subscribe(
      (data) => {
        this.loading = true;
        console.log(data.croppedImages[0]);

        this.toBase64(data.croppedImages[0]).then(
          (base64) =>  this.pic = base64
        );
        if (this.save){
          this.authService.changeProfilePic({
            profilePic: data.croppedImages[0]
          }).subscribe(
            (user) => {
              this.loading = false;
              this.pic = user.profilePic;
            }
          );
        }else{
          this.selectedImage.emit(data.croppedImages[0]);
        }
      }
    );
  }

}
