import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PackageListComponent} from "./package-list.component";
import {MatCardModule} from "@angular/material/card";
import {PackageTumbnielComponent} from "../../components/package-tumbniel/package-tumbniel.component";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";



@NgModule({
  declarations: [PackageListComponent, PackageTumbnielComponent],
  exports: [PackageListComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatProgressSpinnerModule
  ]
})
export class PackageListModule { }
