import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {AuthService} from '../../service/auth.service';

@Component({
  selector: 'app-telegram-login',
  templateUrl: './telegram-login.component.html',
  styleUrls: ['./telegram-login.component.scss']
})
export class TelegramLoginComponent implements OnInit {
  @Input() loading;
  @Output() loadingChange: EventEmitter<any> = new EventEmitter();
  @Output() loggedIn: EventEmitter<any> = new EventEmitter();
  constructor(private auth: AuthService) { }

  ngOnInit(): void {
  }

  onLoad() {
  }

  onLoadError() {
  }

  onLogin(telegram_user) {
    this.loadingChange.emit(true);
    const input = {
      telegramId: telegram_user.id,
      telegramUsername: telegram_user.username,
      hash: telegram_user.hash,
      name: telegram_user.first_name + ' ' + telegram_user.last_name,
    };
    console.log(input);
    this.auth.loginWithTelegram(input).subscribe(
      (response: any) => {
        this.loadingChange.emit(false);
        this.loggedIn.emit();
      }
    );
  }
}
