import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PayFormComponent} from "./pay-form.component";
import {ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {MatInputModule} from "@angular/material/input";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {LangModule} from "../../../../lang.module";



@NgModule({
  declarations: [PayFormComponent],
  exports: [PayFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    LangModule
  ]
})
export class PayFormModule { }
