<mat-list>
  <h2 class="capitalize">{{data.business.name}} {{'phone numbers'| translate}}</h2>
  <mat-list-item *ngFor="let phone of phones">
    <div class="flex" style="justify-content: space-between; width: 100%">
      <button mat-button><mat-icon class="secondery">{{'phone'| translate}}</mat-icon> {{phone.node.phone}}</button>
      <button mat-button color="warn" (click)="removePhone(phone.node)">{{'Remove'| translate}}</button>
    </div>
  </mat-list-item>
  <div class="flex">
    <form [formGroup]="phoneForm" #formDirective="ngForm" (ngSubmit)="addPhone(formDirective)" style="width: 100%" >
      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>{{'Business Phone number'| translate}}</mat-label>
        <input matInput type="tel" formControlName="phone"/>
      </mat-form-field>
      <button style="width: 100%" mat-flat-button color="primary" type="submit">{{'Add'| translate}}</button>
    </form>
  </div>
</mat-list>
