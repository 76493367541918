<div>
  <form [formGroup]="confirmationForm">
    <div class="flex-col">
        <mat-form-field appearance="outline">
          <mat-label style="text-transform:capitalize;">{{confirm}} {{'confirmation code'| translate}}</mat-label>
          <input matInput formControlName="code" max="6">
          <mat-spinner [diameter]="25"  *ngIf="loading" matSuffix>key</mat-spinner>
          <mat-icon *ngIf="$confirmed === true" matSuffix style="color:green">done</mat-icon>
          <mat-error *ngIf="confirmationForm.controls['code'].hasError('confirmed')">
            {{'The confirmation code is not correct.'| translate}}
          </mat-error>
          <mat-hint>
            {{'Confirmation code is sent to'| translate}}
            <b>{{confirm === 'email'? this.authService.email(): this.authService.phone()}}</b>.
          </mat-hint>
        </mat-form-field>
        <div class="flex" style="flex-flow: row-reverse">
          <button mat-button
                  (click)="sendConfirmation()"
                  [disabled]="!resend">{{'Resend'| translate}}</button>
        </div>
    </div>
  </form>
</div>

