import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BusinessService} from '../../service/business.service';
import {SuperAdminGuard} from '../../../../core/guards/auth/super-admin.guard';
import {TranslateService} from "@ngx-translate/core";
import {StorageService} from "../../../../core/services/storage.service";

@Component({
  selector: 'app-business-list',
  templateUrl: './business-list.component.html',
  styleUrls: ['./business-list.component.scss']
})
export class BusinessListComponent implements OnInit {
  @Input() title: any;
  @Output() selectBusiness: EventEmitter<any> = new EventEmitter<any>();
  view = true;
  @Input() businesses = [];
  @Input() loading = false;
  @Input() myBusiness = true;
  @Input() addBusiness = true;

  constructor(
    public superUserGuard: SuperAdminGuard,
    private businessService: BusinessService,
    translate: TranslateService , private storageService: StorageService) {
    translate.use(this.storageService.getLanguage('lang'));

  }

  ngOnInit(): void {
    if (this.myBusiness){
      this.load();
    }
  }
  load(): void{
    this.loading = true;
    this.businessService.myBusiness().subscribe(
      (businesses) => {
        this.businesses = businesses;
        this.loading = false;
      },
      (error) => {},
      () => this.loading = false
    );
  }
}
