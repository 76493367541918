import {Component, Input, OnInit} from '@angular/core';
import {CategoryService} from '../../service/category.service';
import {FormBuilder, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmationDialogComponent} from '../../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import {TranslateService} from "@ngx-translate/core";
import {StorageService} from "../../../../core/services/storage.service";

@Component({
  selector: 'app-possible-values-list',
  templateUrl: './possible-values-list.component.html',
  styleUrls: ['./possible-values-list.component.scss']
})
export class PossibleValuesListComponent implements OnInit {
  possibleValues;
  @Input() propertyId;
  loading = true;
  possibleValuesForm = this.formBuilder.group({
    id: [null],
    value: ['']
  });

  constructor(
    private categoryService: CategoryService,
    private formBuilder: FormBuilder,
    private matDialog: MatDialog,
    translate: TranslateService , private storageService: StorageService
  ) {
    translate.use(this.storageService.getLanguage('lang'));
  }

  ngOnInit(): void {
    this.loading = true;
    this.categoryService.getPossibleValues(this.propertyId).subscribe(
      (value) => {
        this.possibleValues = value;
      },
          (error) => {},
      () => {this.loading = false; }
    );
  }

  submit() {
    const input = this.possibleValuesForm.value;
    if (input.value === ''){
      return;
    }
    input.categoryProperty = this.propertyId;
    this.categoryService.addPossibleValue(input).subscribe(
      (possibleValue) => {
        if (!this.possibleValuesForm.value.id){
          this.possibleValues.push({node: possibleValue});
        }else{
          this.possibleValues.map((e) => {
            if (e.node.id === this.possibleValuesForm.value.id){
              e.node = possibleValue;
            }
            return e;
          });
        }
        this.possibleValuesForm.reset();
      }
    );
  }

  edit(possibleValue) {
    const $value = possibleValue;
    this.possibleValuesForm.setValue({
      id: possibleValue.id,
      value: possibleValue.value
    });
  }

  trash(id) {
    this.matDialog.open(ConfirmationDialogComponent, {
      width: '280px',
      disableClose: true
    }).afterClosed().subscribe(
      (response) => {
        if (!!response.confirmation){
          this.categoryService.deletePossibleValue({id: id}).subscribe(
            (deletedValue) => {
              this.possibleValues = this.possibleValues.filter(
                (e) => {
                  return e.node.id !== id;
                }
              );
            }
          );
        }
      }
    );
    return;
  }
}
