import {Component, OnInit, Input, OnChanges, SimpleChanges} from '@angular/core';
import {HOME_PAGE_SIDE} from '../../../../config/URI.config';
import {AdService} from '../../service/ad.service';
import {GalleryState, ImageItem} from '@ngx-gallery/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit, OnChanges{
  @Input() dimension = {width: '100%', height: '200px'};
  @Input() ratio = 1 / 3;
  @Input() businessAds = [];
  @Input() plan;
  @Input() packageId;
  @Input() id = Math.random();
  bannerImgs = [];
  activeAd = {
    banner: '/assets/placeholders/advertisment%20banner.svg',
    product: null
  };
  galleryId = 'banner-gallery';
  @Input() category;
  constructor(private adService: AdService, private router: Router) { }

  ngOnInit(): void {
    this.bannerImgs = [
      new ImageItem({ src: this.activeAd.banner, thumb:this.activeAd.banner}),
    ];
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.adService.getBusinessAds(this.packageId, this.category).subscribe(
      (businessAds) => {
        this.businessAds = businessAds;
        this.bannerImgs = this.businessAds.map(e => new ImageItem({ src: e.node.business.banner, thumb: e.banner}));
      }
    );
  }

  onIndexChange($event: GalleryState) {

  }

  onItemClick(i: number) {
    this.router.navigate(['/business/' + this.businessAds[i].node.business.id]);
  }
}
