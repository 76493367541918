import {Component, Input, OnInit} from '@angular/core';
import {PackageService} from '../../service/package.service';

@Component({
  selector: 'app-package-tumbniel',
  templateUrl: './package-tumbniel.component.html',
  styleUrls: ['./package-tumbniel.component.scss']
})
export class PackageTumbnielComponent implements OnInit {
  @Input() package;
  constructor(private packageService: PackageService) { }

  ngOnInit(): void {
  }

}
