import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { LayoutModule } from '@angular/cdk/layout';
import { NavigationModule } from './navigation/navigation.module';
import {LayoutModule as AppLayoutModule} from './layout/layout.module';
import { GraphQLModule } from './graphql.module';
import {MatCardModule} from "@angular/material/card";
import {MatIconModule} from "@angular/material/icon";
import {LoginOrSignupDialogModule} from "./modules/auth/login-or-signup-dialog/login-or-signup-dialog.module";
import {MatButtonModule} from "@angular/material/button";
import {VacancyFormDialogModule} from "./modules/job/dialog/vacancy-form-dialog/vacancy-form-dialog.module";
import {PaymentFormDialogModule} from "./modules/payment/dialogs/payment-form-dialog/payment-form-dialog.module";
import {AdvertiseDialogModule} from "./modules/ad/dialog/advertise-dialog/advertise-dialog.module";
import {ConfirmUserDialogModule} from "./modules/auth/dialogs/confirm-user-dialog/confirm-user-dialog.module";
import {ChangePasswordDialogModule} from "./modules/auth/dialogs/change-password-dialog/change-password-dialog.module";
import {AddBusinessUserModule} from "./modules/business/dialog/add-business-user/add-business-user.module";
import {EditBusinessPhoneDialogModule} from "./modules/business/dialog/edit-business-phone-dialog/edit-business-phone-dialog.module";
import {SelectBusinessDialogModule} from "./modules/business/dialog/select-business-dialog/select-business-dialog.module";
import {PossibleValuesDialogModule} from "./modules/product/dialogs/possible-values-dialog/possible-values-dialog.module";
import {ProductDialogModule} from "./modules/product/dialogs/product-dialog/product-dialog.module";
import {ProductImageDialogModule} from "./modules/product/dialogs/product-image-dialog/product-image-dialog.module";
import {SelectCategoryDialogModule} from "./modules/product/dialogs/select-category-dialog/select-category-dialog.module";
import {ResumeSubscriptionDialogModule} from "./modules/resume/dialog/resume-subscription-dialog/resume-subscription-dialog.module";
import {SelectResumeDialogModule} from "./modules/resume/dialog/select-resume-dialog/select-resume-dialog.module";
import {CategoryPropertyDialogModule} from "./modules/product/dialogs/category-property-dialog/category-property-dialog.module";
import {CouponDialogModule} from './modules/payment/dialogs/coupon-dialog/coupon-dialog.module';
import {FooterModule} from './navigation/footer/footer.module';
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {HttpClient} from "@angular/common/http";
import { LangModule } from './lang.module';
import {MatTabsModule} from '@angular/material/tabs';
import {SearchModule} from './modules/search/components/search/search.module';


@NgModule({
  declarations: [
    AppComponent,
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
        LayoutModule,
        NavigationModule,
        AppLayoutModule,
        GraphQLModule,
        LoginOrSignupDialogModule,
        MatCardModule,
        MatIconModule,
        MatButtonModule,
        VacancyFormDialogModule,
        PaymentFormDialogModule,
        AdvertiseDialogModule,
        ConfirmUserDialogModule,
        ChangePasswordDialogModule,
        AddBusinessUserModule,
        EditBusinessPhoneDialogModule,
        SelectBusinessDialogModule,
        VacancyFormDialogModule,
        PossibleValuesDialogModule,
        ProductDialogModule,
        ProductImageDialogModule,
        SelectCategoryDialogModule,
        ResumeSubscriptionDialogModule,
        SelectResumeDialogModule,
        CategoryPropertyDialogModule,
        CouponDialogModule,
        FooterModule,
        LangModule,
        MatTabsModule,
        SearchModule
    ],
  providers: [
    HttpClient
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
