import { Component, OnInit, Input} from '@angular/core';
import {not} from 'rxjs/internal-compatibility';
import {Router} from '@angular/router';

@Component({
  selector: 'app-small-noti-component',
  templateUrl: './small-noti-component.component.html',
  styleUrls: ['./small-noti-component.component.scss']
})
export class SmallNotiComponentComponent implements OnInit {
  @Input() notification;
  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  goto(notification: any) {
    if (notification){
      console.log(notification.node.type);
      switch (notification.node.type){
        case 'A_1':
          this.router.navigate(['/job/view/detail/' + notification.node.job.id]);
          break;
        case 'A_2':
          console.log(notification.node.tender.id);
          this.router.navigate(['/tender/' + notification.node.tender.id]);

      }
    }
  }
}
