import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../service/auth.service';
import {MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm = this.formBuilder.group({
                        oldPassword: ['', Validators.required],
                        newPassword1: ['', Validators.required],
                        newPassword2: ['', Validators.required],
                      }, {validator: this.passwordConfirming});

  isLoading = false;
  message = null;
  errors = [];
  @Output() changePassword: EventEmitter<any> = new EventEmitter<any>();
  constructor(private formBuilder: FormBuilder, private authService: AuthService, private snackBar: MatSnackBar) { }

  ngOnInit(): void {}

  submit() {
    this.isLoading = true;
    this.authService.changePassword(this.changePasswordForm.value).subscribe(
      (response) => {
        this.isLoading = false;
        this.message = {
          success: response.success,
          message: response.errors
        };
        if (response.success){
          this.changePassword.emit();
          this.snackBar.open('Password successfully changed', '', {duration: 3000});
        }else {
          for (const [key, value]  of Object.entries(response.errors)){
            this.errors = this.errors.concat(value);
          }
        }
      },
      error => this.isLoading = false
    );
  }
  passwordConfirming(c: AbstractControl): { match: boolean } {
    if (c.get('newPassword1').value !== c.get('newPassword2').value) {
      c.get('newPassword2').setErrors({
        match: true
      });
      return {match: true};
    }
  }
}
