import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BusinessComponent} from "./business.component";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {MatInputModule} from "@angular/material/input";
import {MatChipsModule} from "@angular/material/chips";
import {MatIconModule} from "@angular/material/icon";
import {AgmCoreModule} from "@agm/core";
import {MatButtonModule} from "@angular/material/button";
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import {LangModule} from "../../../../lang.module";



@NgModule({
  declarations: [BusinessComponent],
  exports: [BusinessComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatChipsModule,
    MatIconModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDpbI9jHY-LAddRmmCji-UW_5S45MNZ55M'
    }),
    MatButtonModule,
    CKEditorModule,
    FormsModule,
    LangModule
  ]
})
export class BusinessFormModule { }
