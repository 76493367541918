import {Component, OnInit, Input, AfterViewInit, EventEmitter, Output} from '@angular/core';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import { support_webp_format } from '../../../../core/util/image';
import {TranslateService} from "@ngx-translate/core";
import {StorageService} from "../../../../core/services/storage.service";

// const Compressor = require('compressorjs');
// @ts-ignore
// import imageCompression from 'browser-image-compression';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss']
})
export class ImageCropperComponent implements OnInit, AfterViewInit {
  images: any = [];
  displayIndex = null;
  croppedImages: any = [];
  croppedImage;
  loading = false;
  @Input() ratio = 4 / 3;
  @Input() imageWidth = 780;
  @Output() finished: EventEmitter<any> = new EventEmitter<any>();
  @Output() crop: EventEmitter<any> = new EventEmitter<any>();

  @Input() set fileChangeEvent(images: any) {
    console.log(images);
    const toBase64 = file => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
    images.forEach(
      (image) => {
        toBase64(image).then(
          (img: string) => {
            const newImage = new Image();
            newImage.src = img;

            newImage.onload = () => {
              const w = newImage.width;
              const h = newImage.height;
              const canvas = document.createElement('canvas');
              canvas.width = this.imageWidth;
              canvas.height = canvas.width * (1 / this.ratio);
              const imageDim = {h: 0, w: 0, x: 0, y: 0};
              if (h > w) {
                imageDim.h = canvas.height;
                imageDim.w = canvas.height * (w / h);
                imageDim.x = (canvas.width - imageDim.w) / 2;
              } else {
                imageDim.h = canvas.width * (h / w);
                imageDim.w = canvas.width;
                imageDim.y = (canvas.height - imageDim.h) / 2;
              }
              const cxt = canvas.getContext('2d');
              cxt.drawImage(newImage, imageDim.x, imageDim.y, imageDim.w, imageDim.h);
              this.images.push(canvas.toDataURL(this.IMAGE_MIME));
            };
          }
        );
      }
    );
  }
  IMAGE_MIME = support_webp_format() ? 'image/webp' : 'image/jpeg';
  constructor(    translate: TranslateService , private storageService: StorageService
  ) {
    translate.use(this.storageService.getLanguage('lang'));
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.displayIndex = 0;
    });
  }

  imageCropped(event: ImageCroppedEvent) {
    this.dataURItoBlob(event.base64);
    // fetch(this.convertImageFormat(event.base64, this.IMAGE_MIME))
    //   .then(res => res.blob())
    //   .then(blob => {
    //     this.croppedImage = blob;
    //   });
  }
  dataURItoBlob(dataURI) {
    const newImage = new Image();
    newImage.src = dataURI;
    newImage.onload = () => {
      const w = newImage.width;
      const h = newImage.height;
      const canvas = document.createElement('canvas');
      canvas.width = newImage.width;
      canvas.height = newImage.height;
      const cxt = canvas.getContext('2d');
      cxt.drawImage(newImage, 0, 0, canvas.width, canvas.height);
      const croppedImageUri = canvas.toDataURL(this.IMAGE_MIME);
      const byteString = atob(croppedImageUri.split(',')[1]);
      // separate out the mime component
      const mimeString = croppedImageUri.split(',')[0].split(':')[1].split(';')[0];
      // write the bytes of the string to an ArrayBuffer
      const ab = new ArrayBuffer(byteString.length);
      // create a view into the buffer
      const ia = new Uint8Array(ab);
      // set the bytes of the buffer to the correct values
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      this.croppedImage = new Blob([ab], {type: mimeString});
    // write the ArrayBuffer to a blob, and you're done
    };

  }
  private convertImageFormat(image, mimeType) {
    const formatedImage = new Image();
    formatedImage.src = image;
    const canvas = document.createElement('canvas');
    canvas.width = formatedImage.width;
    canvas.height = formatedImage.height;
    const cxt = canvas.getContext('2d');
    cxt.drawImage(formatedImage, 0, 0, canvas.width, canvas.height);
    return canvas.toDataURL(mimeType);
  }

  imageLoaded() {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }

  finish() {
    let s = 0;
    this.croppedImages.forEach(
      (e) => s += e.size
    );
    console.log(s / 1024);
    // this.loading = false;
    this.finished.emit(this.croppedImages);
  }

  back() {
    if (0 < this.displayIndex) {
      this.displayIndex -= 1;
    }
  }

  next() {
    const $this = this;
    // let finish = false;
    // const commpress = new Compressor($this.croppedImage, {
    //   quality: 1,
    //   maxWidth: this.imageWidth,
    //   success(result) {
    //     // $this.croppedImage = result;
    //     $this.croppedImages.push(result);
    //     $this.crop.emit(this.croppedImage);
    //     $this.loading = false;
    //     if (finish){ $this.finish(); }
    //   },
    //   error(err) {
    //     $this.loading = false;
    //   },
    // });
    $this.croppedImages.push(this.croppedImage);
    $this.crop.emit(this.croppedImage);
    if ($this.images.length !== ($this.displayIndex + 1)) {
      if ($this.images.length > $this.displayIndex + 1) {
        $this.displayIndex += 1;
      }
    } else {
      // finish = true;
      this.finish();
      this.loading =  true;
    }
  }

  startCropImage($event: void) {
  }
}
