<div class="flex-col">
  <h1 style="margin-left: 2em">{{title |  translate}}</h1>
  <div [style.width]="(isHandset$ | async)? '90%' : '20%'"
       style="margin: 0 auto" *ngIf="flow=='wrap' && products != null && filter && (isHandset$ | async)">
    <div class="flex-col custom-slider" [class.flat-card]="!hideFilter">
      <button mat-button (click)="hideFilter = !hideFilter">
        <mat-icon>filter_alt</mat-icon>
        {{'Filter'| translate}}
      </button>
      <div [hidden]="hideFilter && (isHandset$ | async)">
        <app-search-filter
          (cityChange)="cityFilter($event)"
          (categoryFilter)="categoryFilter($event)"
          (filterRange)="filterRange($event);"></app-search-filter>
        <div class="flex-col">
          <p>{{'City'| translate}}</p>
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>{{'Select city'| translate}}</mat-label>
            <mat-select (selectionChange)="cityFilter($event)">
              <mat-option
                *ngFor="let city of cities | async"
                [value]="city.node.id">{{city.node.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <div [class]="(isHandset$ | async)? 'flex-col' : 'flex'" style="width: 100%">
    <div class="flex-col" [style.width]="(isHandset$ | async)? '100%' : '100%'">
      <div class="grid-container" #listContainer (window:resize)="listContainerResize()">
        <div class="flex-col" id="arrow-left" (click)="leftScroll()" *ngIf="flow !== 'wrap' && scrollPos > 0">
          <mat-icon>keyboard_arrow_left</mat-icon>
        </div>
        <div class="flex"
             id="product-list"
             #productList
             infiniteScroll
             [infiniteScrollContainer]="(flow === 'wrap')?'.sidenav-content': null"
             [fromRoot]="true"
             [infiniteScrollDistance]="1"
             [infiniteScrollThrottle]="500"
             [horizontal]="flow !== 'wrap'"
             [scrollWindow]="flow === 'wrap'"
             (scrolled)="onScroll()"
             (scroll)="scroll()"
             [class.flow]="flow"
             [style.justify-content]="flow === 'wrap'? 'center': 'left'"
             [style.flex-flow]="flow">
          <app-product-tumbniel
            *ngFor="let product of products"
            width="160px"
            [product]="product"
            [class.selected]="product.selected"
            (click)="click($event, product)"
            style="margin:8px"></app-product-tumbniel>
          <mat-card
            *ngFor="let placeHolder of [0,1,2,3,4,5,6,7]"
            style="min-width:160px;height: inherit;padding: 0;margin:8px"
            [style.display]="loading? null:'none'">
            <mat-card-content
              class="progress"
              style="margin: auto; width: 100%; height: 120px; margin-bottom: .5em;">
            </mat-card-content>
            <div style="padding: 0 1em" class="flex-col">
              <div class="progress" style="width: 100%;height: 1em;background: #efefef; margin-bottom: .5em;"></div>
              <div class="progress" style="width: 70%;height: 1em;background: #efefef; margin-bottom: .5em;"></div>
              <div
                style="width: 40%;height: 1em;background: #ffa000; margin-bottom: .5em;align-self: flex-end; opacity: .2"></div>
            </div>
          </mat-card>

        </div>
        <div class="flex-col" id="arrow-right" (click)="rightScroll()" *ngIf="flow !== 'wrap'">
          <mat-icon>keyboard_arrow_right</mat-icon>
        </div>
      </div>

      <div
        *ngIf="products?.length === 0 && !loading"
        class="flex" style="width: 100%; justify-content: space-around">
        <h1 class="mat-secondary" style="text-align: center; padding: 3em 1em">{{'NO PRODUCT IS FOUND' | translate}}</h1>
      </div>
    </div>
    <div [style.width]="(isHandset$ | async)? '90%' : '20%'"
         style="margin: 0 auto" *ngIf="flow=='wrap' && products != null && filter && !(isHandset$ | async)">
      <div class="flat-card flex-col custom-slider">
        <button mat-button (click)="hideFilter = !hideFilter">
          <mat-icon>filter_alt</mat-icon>
          {{'Filter'| translate}}
        </button>
        <div [hidden]="hideFilter && (isHandset$ | async)">
          <app-search-filter (categoryFilter)="categoryFilter($event)" (filterRange)="filterRange($event);"></app-search-filter>
          <div class="flex-col">
            <p>{{'City'| translate}}</p>
            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>{{'Select city'| translate}}</mat-label>
              <mat-select (selectionChange)="cityFilter($event)">
                <mat-option
                  *ngFor="let city of cities | async"
                  [value]="city.node.id">{{city.node.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
