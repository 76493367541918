import {Component, Input, OnInit} from '@angular/core';
import {RatingChangeEvent, StarRatingConfigService} from 'angular-star-rating';
import {ProductService} from '../../service/product.service';
import {$} from 'protractor';
import {StarRatingConfigService as CustomLocalConfigService} from '../../../../core/services/star-rating-config.service';

@Component({
  selector: 'app-product-ratting',
  templateUrl: './product-ratting.component.html',
  styleUrls: ['./product-ratting.component.scss'],
  providers: [{
    provide: StarRatingConfigService, useClass: CustomLocalConfigService
  }]
})
export class ProductRattingComponent implements OnInit {
  @Input() rate = 0;
  @Input() editable = false;
  @Input() productId: string;
  constructor(private productsService: ProductService) { }

  ngOnInit(): void {
    this.rate = 0;
  }

  onRatingChange($event: RatingChangeEvent) {
    if (!this.productId) return;
    const input = {
      product: this.productId,
      ratting: $event.rating
    };
    this.productsService.productRatting(input).subscribe(
      (ratting) => {
        this.rate = ratting.rate;
      }
    );
  }
}
