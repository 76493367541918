import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../service/auth.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ConfirmUserComponent} from '../../components/confirm-user/confirm-user.component';
import {MatSnackBar, MatSnackBarModule} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {StorageService} from '../../../../core/services/storage.service';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';

declare let FB: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  facebook = faFacebook;
  @Output() login: EventEmitter<any> = new EventEmitter<any>();
  @Output() goSignUp: EventEmitter<any> = new EventEmitter<any>();
  isLoading = false;
  loginForm = this.formBuilder.group({
    emailOrPhone: ['', Validators.required],
    password: ['', Validators.required],
  });
  invalidCredentialsErrors = false;
  hideTelegramLogin = false;

  constructor(private formBuilder: FormBuilder, private auth: AuthService, public dialog: MatDialog, private translate: TranslateService , private storageService: StorageService,
              private snackBar: MatSnackBar) {
    translate.use(this.storageService.getLanguage('lang'));
  }

  ngOnInit(): void {
    (window as any).fbAsyncInit = function() {
      FB.init({
        appId      : '257006519392084',
        cookie     : true,
        xfbml      : true,
        version    : 'v11.0'
      });

      FB.AppEvents.logPageView();


    };


    (function(d, s, id){
      let js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {return;}
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));

  }

  submit() {
    this.isLoading = true;
    let emailOrPhone = this.loginForm.value.emailOrPhone.toLowerCase();
    if (emailOrPhone.slice(0, 2) === '09') {
      emailOrPhone = '+251' + emailOrPhone.slice(1, emailOrPhone.length);
    }
    this.auth.login(emailOrPhone, this.loginForm.value.password)
      .subscribe(({data}) => {
        this.isLoading = false;
        if (data.tokenAuth.errors !== null) {
          if (data.tokenAuth.errors.nonFieldErrors.length > 0) {
            this.invalidCredentialsErrors = true;
          }
          return;
        } else {
          this.login.emit();
          this.confirmation();
        }
      }, (error) => {
        // this.invalidCredentialsErrors = true;
        this.isLoading = false;
      });
  }

  confirmation() {
    if (!!this.auth.isLogedIn() && !this.auth.decodeToken()?.isUnregistered) {
      if (!this.auth.userVerified()) {
        this.dialog.open(ConfirmUserComponent, {
          width: '380px',
          disableClose: true,
        });
      }
    }
  }

  loginWithFb() {
    this.isLoading = true;
    FB.login((response)=>{
      if(response.status == 'connected'){
        console.log(response.authResponse.accessToken);
        this.auth.loginWithFacebook({
            accessToken: response.authResponse.accessToken,
            provider: 'facebook'
          }).subscribe((response: any) =>{
          console.log('this is response');
          console.log(response);
          this.storageService.setToken(response.data.socialAuth.token, response.data.socialAuth.refreshToken);

          this.isLoading = false;
          this.login.emit(true);
          this.snackBar.open(this.translate.instant('login was a success'),'',{
            duration: 4000
          });
        }, error => {
          console.log(error);
        });

        // FB.api('/me',{fields: ['last_name', 'email']}, function(response) {
        //   console.log('Successful login for: ' + response.last_name);
        // });
      }else if(response.status == 'not_authorized'){
        this.snackBar.open(this.translate.instant('login failed'),'',{
          duration: 4000
        });
        this.isLoading = false;
      }else{
        this.snackBar.open(this.translate.instant('login failed'), '',{
          duration: 4000
        });
        this.isLoading = false;

      }
    });


  }
}
