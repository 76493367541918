import {Injectable} from '@angular/core';
import {Apollo} from 'apollo-angular';
import gql from 'graphql-tag';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private appolo: Apollo) {
  }

  getCategories(
    properties = true,
    parent = null,
    name = null,
    parentCategorySet = true,
    numberOfProducts = false): Observable<any> {
    const categoriesQuery = gql`
      query categoriesQuery($properties: Boolean!,$parentCategorySet: Boolean!,$parent:ID, $name: String, ,$numberOfProducts: Boolean!) {
        productCategories(name: $name, parent:$parent) {
          edges{
            node{
              id
              name
              icon
              numberOfProducts @include(if: $numberOfProducts)
              parentCategory{
                id
                name
                numberOfProducts
                productcategorySet @include(if: $parentCategorySet){
                  edges{
                    node{
                      id
                      name
                    }
                  }
                }
              }
              categorypropertySet @include(if: $properties){
                edges{
                  node{
                    id
                    name
                    unit
                    isEnumerated
                    isRequired
                    dataType{
                      name
                    }
                    propertypossiblevaluesSet{
                      edges{
                        node{
                          value
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }`;
    return this.appolo.query({
      query: categoriesQuery,
      variables: {
        properties: properties,
        name: name,
        parent: parent,
        parentCategorySet: parentCategorySet,
        numberOfProducts: numberOfProducts
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.productCategories.edges)
    );
  }
  getCategoryByName(name, properties = true){
    return this.getCategories(properties, name);
  }
  getCategoryProperties(categoryId: any): Observable<any> {
    console.log(categoryId);
    const categoryProperty = gql`
      query ($id: ID!) {
        productCategory(id: $id) {
          id
          properties {
            id
            name
            unit
            isEnumerated
            isRequired
            dataType {
              id
              name
            }
            propertypossiblevaluesSet {
              edges {
                node {
                  id
                  value
                }
              }
            }
          }
        }
      }
    `;
    return this.appolo.query({
      query: categoryProperty,
      variables: {
        id: categoryId
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.productCategory?.properties)
    );
  }
  getCategoryChildren($categoryId: string, number = false): Observable<any> {
    return this.getCategories(true, $categoryId, null,true, number);
  }
  getCategory(id: string, properties = false): Observable<any> {
    const categoriesQuery = gql`
      query categoryQuery($categoryId:ID!, $properties: Boolean!) {
        productCategory(id: $categoryId) {
              id
              name
              icon
              parentCategory{
                id
                name
              }
              rootCategory
              categorypropertySet @include(if: $properties){
                edges{
                  node{
                    id
                    name
                    unit
                    isEnumerated
                    isRequired
                    dataType{
                      name
                    }
                    propertypossiblevaluesSet{
                      edges{
                        node{
                          value
                        }
                      }
                    }
                  }
                }
              }
        }
      }`;
    return this.appolo.query({
      query: categoriesQuery,
      variables: {
        categoryId: id,
        properties: properties
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.productCategory)
    );
  }
  createCategory(input, properties= true): Observable<any> {
    const createCategoryMuation = gql`
      mutation createCategoryMuation($input: CategoryCreateMutationInput!, $properties: Boolean!){
        createCategory(input: $input){
          productCategory{
            id
            name
            icon
            parentCategory{
              id
              name
            }
            categorypropertySet @include(if: $properties){
              edges{
                node{
                  id
                  name
                  unit
                  isEnumerated
                  isRequired
                  dataType{
                    name
                  }
                  propertypossiblevaluesSet{
                    edges{
                      node{
                        value
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `;
    return this.appolo.mutate({
      mutation: createCategoryMuation,
      variables: {
        input: input,
        properties: properties
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => {
        // @ts-ignore
        return data.createCategory.productCategory;
      })
    );
  }

  addProperty(input: any): Observable<any> {
    const addPropertyMutation = gql`
      mutation CategoryPropertyMutaion($input: CategoryPropertyMutationInput!){
        categoryProperty(input: $input){
          categoryProperty{
            id
            name
            unit
            isEnumerated
            isRequired
            dataType{
              id
              name
            }
            propertypossiblevaluesSet{
              edges{
                node{
                  value
                }
              }
            }
          }
        }
      }
    `;
    return this.appolo.mutate({
      mutation: addPropertyMutation,
      variables: {
        input: input
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.categoryProperty.categoryProperty)
    );
  }

  getDataTypes(): Observable<any> {
    const dataTypesQury = gql`
      query dataType{
        dataTypes{
          edges{
            node{
              id
              name
            }
          }
        }
      }
    `;
    return this.appolo.query({
      query: dataTypesQury
    }).pipe(
      // @ts-ignore
      map(({data}) => data.dataTypes.edges)
    );
  }

  getPossibleValues(propertyId: any) {
    const possibleValuesQuery = gql`
      query($id: ID){
        propertyPossibleValues(categoryProperty: $id){
          edges{
            node{
              id
              value
            }
          }
        }
      }
    `;
    return this.appolo.query({
      query: possibleValuesQuery,
      variables: {
        id: propertyId
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.propertyPossibleValues.edges)
    );
  }

  addPossibleValue(input: any): Observable<any> {
    const possibleValueMutation = gql`
      mutation($input: PropertyPossibleValueMutationInput!){
        PropertyPossibleValue(input: $input){
          propertyPossibleValue{
            id
            value
          }
        }
      }
    `;
    return this.appolo.mutate({
      mutation: possibleValueMutation,
      variables: {
        input: input
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.PropertyPossibleValue.propertyPossibleValue)
    );
  }

  deletePossibleValue(input: any): Observable<any>{
    const deletePropertyPossibleValue = gql`
      mutation deletePropertyPossibleValue($input: DeletePropertyPossibleValueMutationInput!){
        deletePropertyPossibleValue(input: $input){
          propertyPossibleValue{
            id
            value
          }
        }
      }
    `;
    return this.appolo.mutate({
      mutation: deletePropertyPossibleValue,
      variables: {
        input: input
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.deletePropertyPossibleValue.propertyPossibleValue)
    );
  }

  delete(input: { id: string }) {
    const deleteCategoryMutation = gql`
      mutation deleteCategoryMuation($input: CategoryDeleteMutationInput!){
        deleteCategory(input: $input){
          productCategory{
            id
            name
            icon
            parentCategory{
              id
              name
            }
          }
        }
      }
    `;
    return this.appolo.mutate({
      mutation: deleteCategoryMutation,
      variables: {
        input: input
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => {
        // @ts-ignore
        return data.deleteCategory.productCategory;
      })
    );
  }

  deleteProperty(id: any): Observable<any> {
    const deletePropertyMutation = gql`
      mutation ($input: DeleteCategoryPropertyMutationInput!){
        deleteCategoryProperty(input: $input){
          categoryProperty{
            id
            name
          }
        }
      }
    `;
    return this.appolo.mutate({
      mutation: deletePropertyMutation,
      variables: {
        input: {id: id}
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.deleteCategoryProperty.categoryProperty)
    );
  }
}
