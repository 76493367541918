<h1 class="mat-h1" style="margin: 1em 0.5;text-align: center;font-family: 'Lobster', cursive;">{{'Login'| translate}}</h1>
<form [formGroup]="loginForm" (ngSubmit)="submit()" [class.loading-form]="isLoading">
  <div class="flex-col">
    <mat-error *ngIf="invalidCredentialsErrors" style="text-align: center; margin-bottom: 1em">
      {{'Invalid username or password'| translate}}
    </mat-error>
    <div>
      <mat-form-field appearance="outline">
        <mat-label>{{'Email or phone number'| translate}}</mat-label>
        <input matInput [placeholder]="'Enter your email or phone number' | translate" formControlName="emailOrPhone">
        <mat-icon matSuffix>user</mat-icon>
        <mat-error *ngIf="loginForm.controls['emailOrPhone'].hasError('required')">
          {{'Either email or phone number is required.' | translate}}
        </mat-error>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="outline">
        <mat-label>{{'Password'| translate}}</mat-label>
        <input matInput type="password" [placeholder]="'Enter your password' | translate" formControlName="password">
        <mat-icon matSuffix>eye</mat-icon>
        <mat-error *ngIf="loginForm.controls['password'].hasError('required')">
          {{'Password is required.'| translate}}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="flex-col" *ngIf="!hideTelegramLogin">
      <div class="flex" style="justify-content: space-around">
        <app-telegram-login
          (loadingChange)="isLoading = $event"
          (loggedIn)="login.emit()"
        ></app-telegram-login>
      </div>
    </div>
    <div class="flex" style="flex-flow: row-reverse">
      <button mat-flat-button type="submit" color="primary" [disabled]="loginForm.invalid">{{'Login' | translate}}</button>
      <button mat-flat-button mat-dialog-close (click)="dialog.getDialogById('login-or-signup-dialog')?.close({login:false})">Cancel
      </button>
    </div>
    <div style="text-align: left" class="flex-col">
      <a mat-button color="link" routerLink="/user/forgot-password/"
         (click)="dialog.getDialogById('login-or-signup-dialog').close({login:false})">{{'Forget password ?'| translate}}</a>
      <a mat-button (click)="goSignUp.emit()" color="link">{{'Create new account'| translate}}</a>
      <div style="display: flex; flex-flow: row; justify-content: center; align-content: center" class="loginFb"  (click)="loginWithFb()">
        <fa-icon size="2x" [icon]="facebook" style="margin-right: .5em;"></fa-icon>Login with Facebook</div>
    </div>
  </div>

</form>
