import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {EmailVerifiedGuard} from '../../../../core/guards/auth/email-verified.guard';
import {PhoneVerifiedGuard} from '../../../../core/guards/auth/phone-verified.guard';
import {AuthService} from '../../service/auth.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from "@ngx-translate/core";
import {StorageService} from "../../../../core/services/storage.service";

@Component({
  selector: 'app-confirm-user',
  templateUrl: './confirm-user.component.html',
  styleUrls: ['./confirm-user.component.scss']
})
export class ConfirmUserComponent implements OnInit {
  @Input() user;
  @Input() verifyEmail;
  @Input() verifyPhone;
  @Output() emailOrPhoneConfirmed: EventEmitter<any> = new EventEmitter();
  @Output() confirmed: EventEmitter<any> = new EventEmitter();
  @Output() finish: EventEmitter<any> = new EventEmitter<any>();

  canFinish = false;
  confirm = {
    email: false,
    phone: false
  };
  constructor(
    public emailVerifiedGuard: EmailVerifiedGuard,
    public phoneVerifiedGuard: PhoneVerifiedGuard,
    public authService: AuthService,
    public matDialogRef: MatDialogRef<any>,
    public dialog: MatDialog,
    translate: TranslateService,
    private storageService: StorageService

  ) {
    translate.use(this.storageService.getLanguage('lang'));
  }

  ngOnInit(): void {
  }
  _confirmed(contact: string) {
    this.emailOrPhoneConfirmed.emit();
    this.canFinish = true;
    if (contact === 'email'){
      this.confirm.email = true;
    }else{
      this.confirm.phone = true;
    }
    if (
      (this.confirm.phone && this.confirm.email) ||
      this.confirm.email && !!this.authService.email() && !this.authService.phone() ||
      this.confirm.phone && !this.authService.email() && !!this.authService.phone()
    ){
      this.confirmed.emit();
    }
  }
}
