import {NgModule} from '@angular/core';
import {ApolloModule, APOLLO_OPTIONS, Apollo} from 'apollo-angular';
import {HttpLinkModule, HttpLink} from 'apollo-angular-link-http';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {GRAPHQL_URI} from './config/URI.config';
import {HttpClientModule, HttpHeaders} from '@angular/common/http';
import {ApolloLink, concat, fromPromise, Observable} from 'apollo-link';
import {RegisterService} from './modules/auth/service/register.service';
import {AuthService} from './modules/auth/service/auth.service';
import {onError} from 'apollo-link-error';
import {MatSnackBar, MatSnackBarModule} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import { persistCache } from 'apollo-cache-persist';


@NgModule({
  exports: [ApolloModule, HttpLinkModule, HttpClientModule],
  imports: [
    MatSnackBarModule
  ],
  // providers: [
  //   {
  //     provide: APOLLO_OPTIONS,
  //     useFactory: createApollo,
  //     deps: [HttpLink],
  //   },
  // ],
})

export class GraphQLModule {
  constructor(
    private apollo: Apollo,
    httpLink: HttpLink,
    private authService: AuthService,
    private registerService: RegisterService,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {
    const http = httpLink.create({uri: GRAPHQL_URI});

    const authMiddleware = new ApolloLink((operation, forward) => {
      this.authService.refreshToken(localStorage.getItem('refreshToken'));
      operation.setContext({
        headers: new HttpHeaders().set('Authorization', ('jwt ' + localStorage.getItem('token')) || '')
      });
      return forward(operation);
    });

    let isRefreshing = false;
    let pendingRequests = [];

    const resolvePendingRequests = () => {
      pendingRequests.map(callback => callback());
      pendingRequests = [];
    };

    async function getNewToken($this) {
      if (!localStorage.getItem('refreshToken')) {
        try {
          const user = await $this.registerService.createUnregisteredUser().toPromise();
        } catch (e) {
          console.log(e);
        }
      }
      return $this.authService.refreshToken(localStorage.getItem('refreshToken')).toPromise();
    }

    const errorLink = onError(
      ({graphQLErrors, networkError, operation, forward}) => {
        if (!!graphQLErrors) {
          for (const err of graphQLErrors) {
            switch (err.message) {
              case 'You do not have permission to perform this action':
                // error code is set to UNAUTHENTICATED
                // when AuthenticationError thrown in resolver
                let forward$;

                if (!isRefreshing) {
                  isRefreshing = true;
                  forward$ = fromPromise(
                    getNewToken(this)
                      .then(({data}) => {
                        // Store the new tokens for your auth link
                        resolvePendingRequests();
                        // console.log(data);
                        return data.refreshToken.token;
                      })
                      .catch(error => {
                        pendingRequests = [];
                        // Handle token refresh errors e.g clear stored tokens, redirect to login, ...
                        return;
                      })
                      .finally(() => {
                        isRefreshing = false;
                      })
                  ).filter(value => Boolean(value));
                } else {
                  // Will only emit once the Promise is resolved
                  forward$ = fromPromise(
                    new Promise(resolve => {
                      pendingRequests.push(() => resolve(null));
                    })
                  );
                }

                return forward$.flatMap(() => forward(operation));
            }
          }
        }
        if (networkError) {
          console.log(`[Network error]: ${networkError}`);
          this.snackBar.open(this.translate.instant('Check your internet connection...'), '', {
              duration: 3000
            });
        }
      }
    );

    const cache = new InMemoryCache();
    this.setPersist(cache);
    apollo.create({
      link: errorLink.concat(authMiddleware.concat(http)),
      cache: cache,
    });
  }

  async setPersist(cache) {
    await persistCache({
      cache,
      storage: window.localStorage,
    });
  }
}
