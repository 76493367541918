import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {Apollo} from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private apollo: Apollo) { }

  myNotifications(first = 7): Observable<any> {
    const NOTIFICATIONS_QUERY = gql `
      query notificatiosQuery($first: Int){
        me{
          id
          notificationSet(first: $first){
            edges{
              node{
                id
                title
                body
                type
                job{
                  id
                  title
                }
                tender{
                  id
                  title
                }
              }
            }
          }
        }
      }
    `;
    return this.apollo.query({
      query: NOTIFICATIONS_QUERY,
      variables: {
        first: first
      }
    });
  }
}
