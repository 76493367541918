import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-product-dialog',
  templateUrl: './product-dialog.component.html',
  styleUrls: ['./product-dialog.component.scss']
})
export class ProductDialogComponent implements OnInit {
  product = null;
  constructor(@Inject(MAT_DIALOG_DATA) private data: any, public matDialogRef: MatDialogRef<any>) { }

  ngOnInit(): void {
    this.product = this.data?.product;
  }

}
