import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CouponComponentComponent } from './coupon-component.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormModule} from '../../../resume/form/form.module';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {PriceModule} from '../../../product/components/price/price.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LangModule} from "../../../../lang.module";
import {MatDialogModule} from "@angular/material/dialog";



@NgModule({
  declarations: [
    CouponComponentComponent
  ],
  exports: [
    CouponComponentComponent
  ],
    imports: [
        CommonModule,
        MatFormFieldModule,
        FormsModule,
        MatInputModule,
        MatButtonModule,
        PriceModule,
        ReactiveFormsModule,
        LangModule,
        MatDialogModule
    ]
})
export class CouponComponentModule { }
