import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import gql from 'graphql-tag';
import {Apollo} from 'apollo-angular';
import {map} from 'rxjs/operators';
import {JobService} from '../../job/service/job.service';
import {AuthService} from '../../auth/service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ResumeService {

  constructor(private apollo: Apollo, private authService: AuthService) {
  }

  getQualifications(): Observable<any> {
    const QUALIFICATIONS_QUERY = gql`
      query quailficationQuery {
        qualifications{
          edges{
            node{
              id
              name
            }
          }
        }
      }
    `;
    return this.apollo.query({
      query: QUALIFICATIONS_QUERY
    }).pipe(
      // @ts-ignore
      map(({data}) => data.qualifications.edges)
    );
  }

  getEducationHistories(resume): Observable<any> {
    const EDUCATION_HISTORY_QUERY = gql`
      query educationHistories($resume: ID!){
        educationHistories(resume: $resume){
          edges{
            node{
              id
              institute
              point
              startedAt
              graduateAt
              fieldOfStudy{
                id
                name
              }
              qualification {
                id
                name
              }
            }
          }
        }
      }
    `;
    return this.apollo.query({
      query: EDUCATION_HISTORY_QUERY,
      variables: {
        resume: resume
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.educationHistories.edges)
    );
  }

  getWorkHistories(resume): Observable<any> {
    const WORK_HISTORY_QUERY = gql`
      query workHistories($resume: ID){
        workHistories(resume: $resume){
          edges{
            node{
              id
              company
              jobTitle
              position
              startedAt
              endAt
            }
          }
        }
      }
    `;
    return this.apollo.query({
      query: WORK_HISTORY_QUERY,
      variables: {
        resume: resume
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.workHistories.edges)
    );
  }

  createUserProfile(profile): Observable<any>{
    const USER_RESUME_PROFILE_CREATE_MUTATION = gql`
      mutation createUserProfileMuation($profile: UserResumeProfileMutationInput!){
        userResumeProfile(input: $profile){
          userResumeProfile{
            id
            firstName
            fatherName
            grandFatherName
            dateOfBirth
          }
          resume{
            id
            name
          }
        }
      }
    `;
    return this.apollo.mutate({
      mutation: USER_RESUME_PROFILE_CREATE_MUTATION,
      variables: {
        profile: profile
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.userResumeProfile)
    );
  }

  createEducationHistory(educationHistory): Observable<any> {
    const CREATE_EDUCATION_HISTORY_MUTATION = gql `
      mutation createEducationHistoryMutation($educationHistory: EducationHistoryMutationInput!){
        educationHistory(input: $educationHistory){
          educationHistory{
            id
            institute
            startedAt
            graduateAt
            qualification{
              id
              name
            }
            fieldOfStudy{
              id
              name
            }
            point
            resume{
              id
              name
            }
          }
        }
      }
    `;
    return this.apollo.mutate({
      mutation: CREATE_EDUCATION_HISTORY_MUTATION,
      variables: {
        educationHistory: educationHistory
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.educationHistory)
    );
  }

  createWorkHistory(workHistory): Observable<any> {
    const CREATE_WORK_HISTORY_MUTATION = gql `
      mutation createWorkHistoryMutation($workHistory: WorkHistoryMutationInput!){
        workHistory(input: $workHistory){
          workHistory{
            id
            company
            jobTitle
            position
            startedAt
            endAt
          }
        }
      }
    `;
    return this.apollo.mutate({
      mutation: CREATE_WORK_HISTORY_MUTATION,
      variables: {
        workHistory: workHistory
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.workHistory)
    );
  }
  getFieldOfStudies(): Observable<any>{
    const FIELD_OF_STUDIES = gql `
      query fieldOfStudies {
        fieldOfStudies{
          edges{
            node{
              id
              name
            }
          }
        }
      }
    `;
    return this.apollo.query({
      query: FIELD_OF_STUDIES
    }).pipe(
      // @ts-ignore
      map(({data}) => data.fieldOfStudies.edges)
    );
  }
  myResume(): Observable<any>{
    return this.authService.me(false, false, false, true);
  }
  getResumeSubscriptionPlans(): Observable<any>{
    const RESUME_SUBSCRIPTION_PLANS = gql `
      query RESUME_SUBSCRIPTION_PLANS_QUERY{
        resumeSubscriptionPlans{
          edges{
            node{
              id
              name
              duration
              price
            }
          }
        }
      }
    `;
    return this.apollo.query({
      query: RESUME_SUBSCRIPTION_PLANS
    }).pipe(
      // @ts-ignore
      map(({data}) => data.resumeSubscriptionPlans.edges )
    );
  }
  createResumeSubscription(resume, plan, coupon = null): Observable<any> {
    const CREATE_RESUME_SUBSCRIPTION_MUTATION = gql `
      mutation CREATE_RESUME_SUBSCRIPTION_MUTATION($input: ResumeSubscriptionMutationInput!){
        resumeSubscription(input: $input){
          resumeSubscription{
            id
          }
        }
      }
    `;
    const input = {
      resume: resume,
      resumeSubscriptionPlan: plan,
      coupon: coupon
    };
    console.log(input);
    return this.apollo.mutate({
      mutation: CREATE_RESUME_SUBSCRIPTION_MUTATION,
      variables: {
        input: input
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.resumeSubscription )
    );
  }
  getResume(id): Observable<any>{
    const RESUME_QUERY = gql`
      query resume($id: ID!) {
        resume(id: $id){
          id
          name
          owner
          resumedocumentSet{
            edges{
              node{
                id
                document
              }
            }
          }
           jobs{
              id
              job{
                id
            title
            salaryMax
            salaryMin
            salaryType
            description
            experience
            qualification{
                  id
                  name
                }
            city{
              id
              name
            }
            vacancy {
              id
              postedAt
              deadline
              business {
                id
                name
                email
                isBroker
                telegram
                whatsapp
                latitude
                longitude
              }
            }
            employmentType{
              id
              name
            }
            numberPosition
            category{
              id
              name
            }
            skills{
              edges{
                node{
                  id
                  name
                }
              }
            }
            fieldOfStudies{
              edges{
                node{
                  id
                  name
                }
              }
            }
              }
            }
          user{
            id
            name
            email
            phone
            profilePic
            userresumeprofile{
              id
              firstName
              fatherName
              grandFatherName
              dateOfBirth
              gender
            }
          }
          subscription{
            id
            startAt
            expireAt
            resumeSubscriptionPlan{
              id
              name
            }
          }
          workhistorySet{
            edges{
              node{
                id
                company
                jobTitle
                position
                startedAt
                endAt
                workingAt
              }
            }
          }
          educationhistorySet{
            edges{
              node{
                id
                institute
                startedAt
                graduateAt
                isLearning
                qualification{
                  id
                  name
                }
                fieldOfStudy{
                  id
                  name
                }
                point
              }
            }
          }
        }
      }
    `;
    return this.apollo.query({
      query: RESUME_QUERY,
      variables: {
        id: id
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.resume)
    );
  }
  getResumes(fieldOfStudies): Observable<any>{
    const RESUME_QUERY = gql`
      query resumes($fieldOfStudies: [ID]) {
        resumes(fieldOfStudies: $fieldOfStudies){
          edges{
            node{
              id
              user {
                id
                name
                profilePic
                phone
                userresumeprofile {
                  id
                  firstName
                  fatherName
                  grandFatherName
                  dateOfBirth
                  gender
                }
              }
            }
          }
        }
      }
    `;
    return this.apollo.query({
      query: RESUME_QUERY,
      variables: {
        fieldOfStudies: fieldOfStudies
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.resumes.edges)
    );
  }
  uploadResumeDocument(input): Observable<any> {
    const UploadResumeDocumentMutation = gql `
      mutation UploadResumeDocumentMutation($input: UploadResumeDocumentMutationInput!){
        uploadResumeDocument(input: $input){
          resumeDocuments{
            id
            document
          }
        }
      }
    `;
    return this.apollo.mutate({
      mutation: UploadResumeDocumentMutation,
      variables: {
        input: input
      },
      context: {
        useMultipart: true
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.uploadResumeDocument.resumeDocuments)
    );
  }

  deleteResumeDocument(input): Observable<any> {
    console.log(input);
    const DeleteResumeDocumentMutation = gql `
      mutation UploadResumeDocumentMutation($input: DeleteResumeDocumentMutationInput!){
        deleteResumeDocument(input: $input){
          resumeDocument{
            id
            document
            resume{
              id
            }
          }
        }
      }
    `;
    return this.apollo.mutate({
      mutation: DeleteResumeDocumentMutation,
      variables: {
        input: input
      },
    }).pipe(
      // @ts-ignore
      map(({data}) => data.deleteResumeDocument.resumeDocuments)
    );
  }

  hire(applicationId: string) {
    const ApplicationStatus = gql`
      mutation ApplicationStatus($input:ApplicationStatusMutationInput!){
        jobApplicationStatus(input: $input){
          application{
            id
            passed
            hired

          }
        }
      }`;
    return this.apollo.mutate({
      mutation: ApplicationStatus,
      variables:{
        input: {
          applicationId: applicationId,
          passed: false,
          hired: true
        }
      }
    });

  }
  pass(applicationId: string){

    const ApplicationStatus = gql`
      mutation ApplicationStatus($input:ApplicationStatusMutationInput!){
        jobApplicationStatus(input: $input){
          application{
            id
            passed
            hired

          }
        }
      }`;
    return this.apollo.mutate({
      mutation: ApplicationStatus,
      variables:{
        input: {
          applicationId,
          passed: true,
          hired: false
        }
      }
    });

  }
  getApplicationStatus(applicationId: string){
    const ApplicationStatus = gql`
      mutation ApplicationStatus($input:ApplicationStatusMutationInput!){
        jobApplicationStatus(input: $input){
          application{
            id
            passed
            hired

          }
        }
      }`;
    return this.apollo.mutate({
      mutation: ApplicationStatus,
      variables:{
        input: {
          applicationId
        }
      }
    });
}

}
