<div class="flex-col" [class.loading-form]="loading">
  <h1 class="mat-h1" style="margin-left: 2em">{{'Image cropper'| translate}}</h1>
  <div class="flex" style="justify-content: space-around">
    <div class="flex-col">
      <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
      <image-cropper
        style="max-width: 480px; max-height: 480px;min-width: 200px; min-height: 200px; margin: auto"
        [imageBase64]="images[displayIndex]"
        [maintainAspectRatio]="true"
        [aspectRatio]="!!ratio? ratio: (4/3)"
        format="png"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()"
        (cropperReady)="cropperReady()"
        (loadImageFailed)="loadImageFailed()"
      ></image-cropper>
    </div>
  </div>

  <div class="flex" style="flex-flow: row-reverse">
    <button
      (click)="next()"
      mat-flat-button color="primary">{{images.length !== (displayIndex+1)?'Next': 'Finish'}}</button>
  </div>
</div>

