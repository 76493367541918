import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ChangePasswordDialogComponent} from "./change-password-dialog.component";
import {ChangePasswordModule} from "../../forms/change-password/change-password.module";
import { MatSnackBarModule} from '@angular/material/snack-bar';



@NgModule({
  declarations: [ChangePasswordDialogComponent],
  exports: [ChangePasswordDialogComponent],
  imports: [
    CommonModule,
    ChangePasswordModule,
    MatSnackBarModule
  ]
})
export class ChangePasswordDialogModule { }
