<div class="flex-col" style="max-height: 580px" *ngIf="notifications?.length > 0">
  <div *ngFor="let notification of notifications">
    <app-small-noti-component [notification]="notification"></app-small-noti-component>
  </div>
</div>
<div class="flex-col" style="align-items: center; text-align: center;padding: 1.5em" *ngIf="notifications?.length === 0">
  <div class="flex" style="background: #efefef; border-radius: 50%;padding: 2em">
    <img [src]="'/assets/images/empty.svg'" style="width: 50px">
  </div>

  <h1 class="secondary">{{'No notifications yet'| translate}}</h1>
</div>
