import {Component, Input, OnInit} from '@angular/core';
import {ASSETS_DIR} from '../../../../config/URI.config';
import {StorageService} from "../../../../core/services/storage.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-business-tumbniel',
  templateUrl: './business-tumbniel.component.html',
  styleUrls: ['./business-tumbniel.component.scss']
})
export class BusinessTumbnielComponent implements OnInit {
  @Input() business;
  imageUrl = ASSETS_DIR + 'images/shopper.svg';
  constructor(    translate: TranslateService , private storageService: StorageService) {

    translate.use(this.storageService.getLanguage('lang'));
  }

  ngOnInit(): void {
  }

}
