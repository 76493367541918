import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmallNotiComponentComponent } from './small-noti-component.component';



@NgModule({
    declarations: [SmallNotiComponentComponent],
    exports: [
        SmallNotiComponentComponent
    ],
    imports: [
        CommonModule
    ]
})
export class SmallNotiComponentModule { }
