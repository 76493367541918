import {Component, Input, OnInit} from '@angular/core';
import { FormControl } from '@angular/forms';
import {PaymentOrderService} from '../../service/payment-order.service';
import {MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from "@ngx-translate/core";
import {StorageService} from "../../../../core/services/storage.service";

@Component({
  selector: 'app-coupon-component',
  templateUrl: './coupon-component.component.html',
  styleUrls: ['./coupon-component.component.scss']
})
export class CouponComponentComponent implements OnInit {
  @Input() price;
  couponInput = new FormControl(null);
  loading = false;
  $price;
  constructor(private paymentOrderService: PaymentOrderService, private matDialogRef: MatDialogRef<any>,
              translate: TranslateService , private storageService: StorageService
  ) {
    translate.use(this.storageService.getLanguage('lang'));
  }

  ngOnInit(): void {
    this.$price = this.price;
    this.couponInput.valueChanges.subscribe(
      (value) => {
        this.updatePrice(value);
      }
    );
  }

  updatePrice($coupon) {
    this.loading = true;
    this.paymentOrderService.getCoupon($coupon).subscribe(
      (coupon) => {
        this.loading = false;
        const discount = (coupon.percentile) ? this.price * coupon.discount / 100 : coupon.discount;
        this.$price = this.price - discount;
      }, (error) => {this.loading = false; }
    );
  }

  confirm() {
    this.matDialogRef.close({coupon: this.couponInput.value});
    // console.log("confirm");
  }
}
