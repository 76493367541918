<h1 style="margin-left: 2em" class="mat-h1">{{title}}</h1>
<div
  class="flex"
  style="
  flex-flow: wrap;
  justify-content: space-around;
  overflow-y: scroll;
  padding-bottom: 1em;">
  <mat-card
    class="mat-elevation-z12"
    *ngFor="let $package of packages"
    id="tumb"
    style="border-radius: 24px"
  >
      <app-package-tumbniel
        [package]="$package"
      (click)="onSelectPackage($package)"
      style="margin-top: 1em"
    >
    </app-package-tumbniel>
  </mat-card>
  <mat-spinner *ngIf="loading"></mat-spinner>
</div>
