<app-top-nav-bar (menu)="drawer.open()" style="z-index: 2">
  <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
  <div *ngIf="!search" class="flex" left style="align-items: center">
    <img [src]="'/assets/icons/icon.svg'" style="width:36px;height:36px;" alt="Serdo icon"/>
    <div class="flex-col" style="margin-left: .5em">
      <span routerLink="/" style="cursor: pointer">{{'Serdo' | translate}}</span>
      <i style="font-size: 11px; color: #333;line-height: 11px; margin-bottom: 3px">Anywhere, Anytime!</i>
    </div>
  </div>

  <span center>
    <div *ngIf="!search" class="flex" id="nav-container">
      <ul id="nav-ul">
        <li class="nav" [class.active]="this.router.url == '/home'"><a routerLink="/home">Home</a> </li>
        <li class="nav" [class.active]="this.router.url == '/product/categories'"><a routerLink="/product/categories">Product & service</a> </li>
        <li class="nav" [class.active]="this.router.url == '/job'"><a routerLink="/job">Job</a> </li>
        <li class="nav" [class.active]="this.router.url == '/tender/categories/list'"><a routerLink="/tender/categories/list">Tender</a> </li>
        <li class="nav" [class.active]="this.router.url == '/about'"><a routerLink="/about">About</a> </li>
          <li><button mat-icon-button  (click)="search = !search"><mat-icon style="font-size: 2em; font-weight: 600">search</mat-icon></button></li>
      </ul>
    </div>
    <div class="flex" *ngIf="search">
      <button style="flex: 1" mat-icon-button (click)="search = !search"><mat-icon>arrow_back</mat-icon> Back to menu</button>
      <app-search style="flex: 5"></app-search>
      <div style="flex: 1"></div>
    </div>

  </span>
  <span right><app-main-nav></app-main-nav></span>
</app-top-nav-bar>
<app-left-nav-drawer #drawer>
  <div navigation>
    <app-drawer-nav></app-drawer-nav>
  </div>
  <div content>
    <div class="flex-col">
      <div class="flex-col" style="min-height: 900px; background: #fafafa">
        <router-outlet (activate)="onActivate($event)" #top></router-outlet>
      </div>

      <app-footer></app-footer>
    </div>
    <div style="position: fixed; bottom: 1em; right: 1em; z-index: 10000">
      <mat-card style="border-radius: 25px;padding: 8px" class="mat-elevation-z12" (click)="call()">
        <button mat-button>
          <mat-icon class="primary">{{'phone'}}</mat-icon>
          <h2 style="display: inline; margin-left: 1em"><b>{{'Call' | translate}} 9596</b></h2></button>
      </mat-card>
    </div>
  </div>
</app-left-nav-drawer>


