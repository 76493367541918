<div>
  <mat-form-field appearance="outline">
    <mat-label>{{'Select business'| translate}}</mat-label>
    <mat-select (selectionChange)="onChangeBusinessSelection($event)">
      <mat-option *ngFor="let business of businesses" [value]="business">{{business.node.name}}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div id="container">
  <app-product-list
    [products]="products" action="select"
    (changeSelection)="onChangeProductSelection($event)"
  ></app-product-list>
</div>

<div class="flex" style="flex-flow: row-reverse">
  <button
    mat-flat-button
    color="primary"
    (click)="onSelect()"
  >{{'Select'| translate}}</button>
</div>

