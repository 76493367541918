<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
  <mat-tree-node
    *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding
    [queryParams]="{category: node.id}"
    [routerLink]="'/product/categories'">
    <button mat-icon-button disabled></button>
    <app-icon
      [icon]="node.icon"
      size="1.15em"
      class="type-icon"
      [attr.aria-label]="node.icon + 'icon'"
      style="color: gray">
    </app-icon>
    {{node.name}}
  </mat-tree-node>

  <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
    <button mat-icon-button matTreeNodeToggle
            [attr.aria-label]="'toggle ' + node.name">
      <mat-icon class="mat-icon-rtl-mirror">
        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
      </mat-icon>
    </button>
    <mat-icon class="type-icon" [attr.aria-label]="node.icon + 'icon'">
      {{ node.icon }}
    </mat-icon>
    {{node.name}}
  </mat-tree-node>
</mat-tree>
