<h1 class="mat-h1" style="margin-left: 2em">{{'Category property'| translate}}</h1>
<form [formGroup]="propertyForm" (ngSubmit)="submit()" class="flex-col">
  <mat-form-field appearance="outline" >
    <mat-label>{{'Name'| translate}}</mat-label>
    <input matInput type="text" formControlName="name">
  </mat-form-field>
  <div class="flex" style="justify-content: space-between" >
    <mat-form-field appearance="outline">
      <mat-label>{{'Unit'| translate}}</mat-label>
      <input matInput type="text" formControlName="unit">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{'Data type'| translate}}</mat-label>
      <mat-select formControlName="dataType">
        <mat-option
          *ngFor="let dataType of dataTypes | async"
          [value]="dataType.node.id"
        >{{dataType.node.name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="flex" style="justify-content: space-around">
      <mat-checkbox formControlName="isRequired">{{'Required'| translate}}</mat-checkbox>
      <mat-checkbox formControlName="isEnumerated">{{'Enumerated'| translate}}</mat-checkbox>
  </div>
  <mat-form-field
    *ngIf="propertyForm.controls.isEnumerated.value"
    style="max-height: 280px; overflow-y: scroll;overflow-x:hidden">
    <mat-chip-list #chipList aria-label="Fruit selection">
      <mat-chip
        *ngFor="let num of possibleValues"
        [selectable]="true"
        [removable]="true"
        (removed)="removed($event)"
      >
        {{num}}
        <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
      </mat-chip>
      <input
        placeholder="Enter all possible values..."
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
        (matChipInputTokenEnd)="add($event)">
    </mat-chip-list>

  </mat-form-field>
  <button mat-flat-button type="submit" color="primary" style="margin-top: 1em">{{'Add'| translate}}</button>
</form>
