import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {BusinessListComponent} from "./business-list.component";
import {MatCardModule} from "@angular/material/card";
import {BusinessTumbnielModule} from "../../components/business-tumbniel/business-tumbniel.module";
import {MatIconModule} from "@angular/material/icon";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {ProductModule} from "../../product.module";
import {MatButtonModule} from "@angular/material/button";
import {BusinessFormModule} from "../../form/business/business-form.module";
import {LangModule} from "../../../../lang.module";


@NgModule({
  declarations: [BusinessListComponent],
  exports: [BusinessListComponent],
  imports: [
    CommonModule,
    MatCardModule,
    BusinessTumbnielModule,
    MatIconModule,
    MatButtonModule,
    BusinessFormModule,
    MatProgressSpinnerModule,
    LangModule
  ]
})
export class BusinessListModule { }
