<div style="height: 100%">
<!--  // VERTICAL TUMB NIELL-->
  <mat-card *ngIf="!row" style="width:160px;height: inherit;padding: 0">
    <mat-card-content>
      <img [src]="imageUrl" [alt]="product?.node?.name"
           style="width:160px;height: 120px;border-radius: 3px 3px 0 0">
    </mat-card-content>
    <div style="padding: 0 1em" class="flex-col">
      <mat-card-title style="font-size: 1em">{{product?.node?.name | textLength:18}}</mat-card-title>
      <app-price [price]="product?.node?.price" style="text-align: right;"></app-price>
    </div>
  </mat-card>
<!--  // HORIZONTAL TUMBNIELL-->
  <div class="flex" *ngIf="row">
    <div class="tumbneil-container"
      [style.width]="'160px'"
      [style.min-width]="'160px'"
      [style.max-width]="'160px'"
      style="padding: 0;flex: 1">
      <img [src]="imageUrl" [alt]="product?.node?.name" style="width:160px;height: 120px;">
    </div>
    <div class="product-detail flex-col" style="flex: 2; margin-left: 1em">
      <h3 style="margin-bottom: 0.1em">{{product?.node?.name | textLength:36}}</h3>
      <h3 class="mat-accent flex" style="justify-content: space-between; margin-bottom: 0.1em">
        <app-price [price]="product?.node?.price" style="text-align: right;"></app-price>
        <span *ngIf="ratting">
          <app-product-ratting
            [rate]="product?.node?.rate"
            [productId]="product?.node?.id"
          ></app-product-ratting>
        </span>
      </h3>
      <mat-card-subtitle>{{product?.node?.description | textLength:48}}</mat-card-subtitle>
    </div>
  </div>
</div>
