import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ProductFormComponent} from "./product.component";
import {ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatInputModule} from "@angular/material/input";
import {NgxDropzoneModule} from "ngx-dropzone";
import {MatButtonModule} from "@angular/material/button";
import {LangModule} from "../../../../lang.module";



@NgModule({
  declarations: [ProductFormComponent],
  exports: [ProductFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCheckboxModule,
    MatInputModule,
    NgxDropzoneModule,
    MatButtonModule,
    LangModule
  ]
})
export class ProductModule { }
