import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-user-dialog',
  templateUrl: './confirm-user-dialog.component.html',
  styleUrls: ['./confirm-user-dialog.component.scss']
})
export class ConfirmUserDialogComponent implements OnInit {

  constructor(public matDialogRef: MatDialogRef<any>) { }

  ngOnInit(): void {
  }

}
