import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SelectResumeDialogComponent} from "./select-resume-dialog.component";
import {ResumeListModule} from "../../list/resume-list/resume-list.module";
import {MatButtonModule} from "@angular/material/button";
import {LangModule} from "../../../../lang.module";



@NgModule({
  declarations: [SelectResumeDialogComponent],
  exports: [SelectResumeDialogComponent],
  imports: [
    CommonModule,
    ResumeListModule,
    MatButtonModule,
    LangModule
  ]
})
export class SelectResumeDialogModule { }
