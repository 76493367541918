import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResumeSubscriptionDialogComponent } from './resume-subscription-dialog.component';
import {MatButtonModule} from '@angular/material/button';
import {LangModule} from "../../../../lang.module";



@NgModule({
  declarations: [ResumeSubscriptionDialogComponent],
  exports: [ResumeSubscriptionDialogComponent],
    imports: [
        CommonModule,
        MatButtonModule,
        LangModule
    ]
})
export class ResumeSubscriptionDialogModule { }
