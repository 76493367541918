import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-possible-values-dialog',
  templateUrl: './possible-values-dialog.component.html',
  styleUrls: ['./possible-values-dialog.component.scss']
})
export class PossibleValuesDialogComponent implements OnInit {
  propertyId = null;
  constructor(@Inject(MAT_DIALOG_DATA) private data) { }

  ngOnInit(): void {
    this.propertyId = this.data.property.id;
  }

}
