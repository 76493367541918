import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {LoginComponent} from '../../modules/auth/forms/login/login.component';
import {SignupComponent} from '../../modules/auth/forms/signup/signup.component';
import {LoginOrSignupComponent} from '../../modules/auth/login-or-signup/login-or-signup.component';
import {AuthService} from '../../modules/auth/service/auth.service';
import {LoginOrSignupDialogComponent} from '../../modules/auth/login-or-signup-dialog/login-or-signup-dialog.component';
import {RegisteredUserGuard} from '../../core/guards/auth/registered-user.guard';
import {MatSnackBar} from '@angular/material/snack-bar';
import {StorageService} from "../../core/services/storage.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss']
})
export class MainNavComponent implements OnInit {
  loggedIn = false;
  authUser: any;
  lang: string;
  constructor(

    private dialog: MatDialog,
    public registeredGuard: RegisteredUserGuard,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private  translate: TranslateService,
    private storageService: StorageService) { }
  ngOnInit(): void {
    this.me();
    this.lang = this.storageService.getLanguage('lang') || 'en';
    this.translate.use(this.lang);
  }
  private me() {
    this.authService.me().subscribe(
      (user) => {
        if (user?.name != null) {
          this.authUser = user;
          this.loggedIn = true;
        }
      }
    );
  }

  openDialog() {
    this.dialog.open(LoginOrSignupDialogComponent, {
      width: '380px',
      disableClose: true,
      id: 'login-or-signup-dialog',
    }).afterClosed().subscribe(
      (result) => {
        this.me();
        this.loggedIn = result?.login;
      }
    );
  }


  logout() {
    this.authService.logout().subscribe(
      (response) => {
        this.loggedIn = false;
      }
    );
  }

  loggedInWithTelegram() {
    this.snackBar.open(this.translate.instant('Your account is integrated with telegram. You can login with telegram next time.'),'',  {
      duration: 5000
    });
  }

  changeLang(value: any) {
    this.storageService.setLanguage(value);
    window.location.reload();
  }
}
