import {Injectable} from '@angular/core';
import {Apollo} from 'apollo-angular';
import {Observable} from 'rxjs';
import gql from 'graphql-tag';
import {map} from 'rxjs/operators';
import {AuthService} from '../../auth/service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class BusinessService {

  constructor(private apollo: Apollo, private authService: AuthService) {
  }

  searchBusiness(name: string, first = null) {
    const SEARCH_BUSINESS_QUERY = gql`
      query businesses($name: String, $first:  Int) {
        businesses(name_Icontains: $name, first: $first){
          edges{
            node{
              id
              name
              email
              isBroker
              telegram
              whatsapp
              latitude
              longitude
              logo
            }
          }
        }
      }
    `;
    return this.apollo.query({
      query: SEARCH_BUSINESS_QUERY,
      variables: {
        name: name,
        first: first
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.businesses.edges)
    );
  }

  getBusinessTypes(): Observable<any> {
    const businesTypesQuery = gql`
      query businessTypes{
        businessTypes{
          edges{
            node{
              id
              name
            }
          }
        }
      }`;
    // @ts-ignore
    return this.apollo.watchQuery({
      query: businesTypesQuery
    }).valueChanges
      .pipe(
        // @ts-ignore
        map(({data}) => data.businessTypes.edges)
      );
  }

  getBusinessSectors(): Observable<any> {
    const businessSectorsQuery = gql`
      query businessSectors{
        businessSectors{
          edges{
            node{
              id
              name
              businesstypeSet {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }`;
    // @ts-ignore
    return this.apollo.query({
      query: businessSectorsQuery
    }).pipe(
      // @ts-ignore
      map(({data}) => data.businessSectors.edges)
    );
  }

  createBusiness(input): Observable<any> {
    const businessMutation = gql`
      mutation createBusiness($input: BusinessCreateMutationInput!){
        createBusiness(input:$input){
          business{
            id
            name
          }
        }
      }`;
    return this.apollo.mutate({
      mutation: businessMutation,
      variables: {
        input: input
      }
    });
  }

  myBusiness(): Observable<any> {
    return this.authService.me(true).pipe(
      // @ts-ignore
      map((data) => data.businessSet.edges)
    );
  }

  getBusiness(id): Observable<any> {
    const BUSINESS_QUERY = gql`
      query business($id: ID!){
        business(id: $id) {
          id
          name
          logo
          banner
          email
          isBroker
          telegram
          whatsapp
          latitude
          longitude
          isOwner
          description
          remainingJobPosts
          canSearchResume
          employersubscriptionSet{
            edges{
              node{
                id
                employerSubscriptionPlan{
                  id
                  name
                  duration
                  price
                  numberOfJobs
                }
              }
            }
          }
          subscription{
            id
            expireAt
            startAt
          }
          businessType{
            id
            name
          }
          businessphoneSet{
            edges{
              node{
                id
                phone
              }
            }
          }
          productSet{
            edges{
              node{
                id
                name
                price
                productimageSet{
                  edges{
                    node{
                      id
                      image
                      imgUrl
                      imgTumbUrl
                    }
                  }
                }
              }
            }
          }
          users{
            edges{
              node{
                id
                name
                email
                phone
                profilePic
              }
            }
          }
        }
      }
    `;
    return this.apollo.query({
      query: BUSINESS_QUERY,
      variables: {
        id: id
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.business)
    );
  }

  getBusinesses(premium = null): Observable<any> {
    const BUSINESSES_QUERY = gql`
      query Businesses($businesspremiumsubscription_Order_VerifiedAt_Isnull: Boolean) {
        businesses(businesspremiumsubscription_Order_VerifiedAt_Isnull: $businesspremiumsubscription_Order_VerifiedAt_Isnull) {
          edges {
            node {
              id
              name
              logo
              banner
              email
              isBroker
              telegram
              whatsapp
              latitude
              longitude
              isOwner
              businessType {
                id
                name
              }
              businessphoneSet {
                edges {
                  node {
                    id
                    phone
                  }
                }
              }
            }
          }
        }
      }
    `;
    return this.apollo.query({
      query: BUSINESSES_QUERY,
      variables: {
        businesspremiumsubscription_Order_VerifiedAt_Isnull: !premium
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.businesses)
    );
  }

  uploadBusinessLogo(input): Observable<any> {
    const UPLOAD_BUSINESS_LOGO_MUTATION = gql`
      mutation uploadBusinessLogoMutation($input: UploadBusinessLogoMutationInput!){
        uploadBusinessLogo(input: $input){
          business{
            id
            name
            logo
            banner
            email
            isBroker
            telegram
            whatsapp
            latitude
            longitude
            isOwner
            businessType{
              id
              name
            }
            businessphoneSet{
              edges{
                node{
                  id
                  phone
                }
              }
            }
          }
        }
      }
    `;
    return this.apollo.mutate({
      mutation: UPLOAD_BUSINESS_LOGO_MUTATION,
      variables: {
        input: input
      },
      context: {
        useMultipart: true
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.uploadBusinessLogo.business)
    );
  }

  addBusinessPhone(phoneInput): Observable<any> {
    const ADD_BUSINESS_PHONE = gql`
      mutation addBussinesPhoneMuatation($input: AddBusinessPhoneMutationInput!){
        addBusinessPhone(input: $input){
          businessPhone{
            id
            phone
          }
        }
      }
    `;
    return this.apollo.mutate({
      mutation: ADD_BUSINESS_PHONE,
      variables: {
        input: phoneInput
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.addBusinessPhone.businessPhone)
    );
  }

  deleteBusinessPhone(phoneInput): Observable<any> {
    const DELETE_BUSINESS_PHONE = gql`
      mutation addBussinesPhoneMuatation($input: DeleteBusinessPhoneMutationInput!){
        deleteBusinessPhone(input: $input){
          businessPhone{
            id
            phone
          }
        }
      }
    `;
    return this.apollo.mutate({
      mutation: DELETE_BUSINESS_PHONE,
      variables: {
        input: phoneInput
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.deleteBusinessPhone.businessPhone)
    );
  }

  deleteBusiness(id): Observable<any> {
    const DELETE_BUSINESS_MUTATION = gql`
      mutation deleteBusiness($input: BusinessDeleteMutationInput!){
        deleteBusiness(input: $input){
          business {
            id
            name
          }
        }
      }
    `;
    const input = {
      id: id
    };
    return this.apollo.mutate({
      mutation: DELETE_BUSINESS_MUTATION,
      variables: {
        input: input
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.deleteBusiness.business)
    );
  }

  addBusinessUser(input): Observable<any> {
    const ADD_BUSINESS_USER = gql`
      mutation addBusinessUserMuation($input: AddBusinessUserMutationInput!) {
        addBusinessUser(input: $input){
          user{
            id
            name
            email
            phone
            profilePic
          }
          business{
            id
            name
            logo
            banner
            email
            isBroker
            telegram
            whatsapp
            latitude
            longitude
            isOwner
          }
        }
      }
    `;
    return this.apollo.mutate({
      mutation: ADD_BUSINESS_USER,
      variables: {
        input: input
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.addBusinessUser)
    );
  }

  advertiseBusiness(input): Observable<any> {
    const ADVERTISE_BUSINESS_MUTATION = gql`
      mutation advertiseBusiness($input: AdvertiseBusinessMutationInput!){
        advertiseBusiness(input: $input){
          businessAd{
            business{
              id
            }
          }
        }
      }
    `;
    return this.apollo.mutate({
      mutation: ADVERTISE_BUSINESS_MUTATION,
      variables: {
        input: input
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.advertiseBusiness)
    );
  }

  businessDescription(input) {
    const BUSINESS_DESCRIPTION = gql`
      mutation businessDescriptionMutation($input:  BusinessDescriptionMutationInput!){
        businessDescription(input: $input){
          business{
            id
            name
            description
          }
        }
      }
    `;
    return this.apollo.mutate({
      mutation: BUSINESS_DESCRIPTION,
      variables: {
        input: input
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.businessDescription.business)
    );
  }

  getPremiumBusinessPlans(): Observable<any> {
    const BUSINESS_PREMIUM_PLAN = gql`
      query businessPremiumPlans {
        businessPremiumPlans {
          edges {
            node {
              id
              name
              duration
              price
            }
          }
        }
      }
    `;
    return this.apollo.query({
      query: BUSINESS_PREMIUM_PLAN,
      variables: {}
    }).pipe(
      // @ts-ignore
      map(({data}) => data.businessPremiumPlans.edges)
    );
  }
}

