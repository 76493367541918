<div class="flex-col">
  <div style="flex: 1">
    <!--    <app-banner-->
    <!--      [dimension]="{width: '100%', height: '300px'}"-->
    <!--      [ratio]="3 / 4"-->
    <!--      [packageId]="'QWRQYWNrYWdlTm9kZTo5'"></app-banner>-->
    <app-banner [packageId]="sideAdPlan"></app-banner>
  </div>
  <div class="flex-col" style="flex: 3">

    <mat-list id="nav-list">
      <mat-list-item
        *ngFor="let nav of this.getNavigations()"
        (click)="!!nav.onClick? nav.onClick(): navClick(nav)">
        <img
          *ngIf="nav.isSvg"
          [src]="iconDir + nav.icon + '.svg'"
          style="width: 22px">
        <app-icon
          *ngIf="!nav.isSvg"
          size="1.8em"
          [gradient]="true"
          [icon]="nav.icon"></app-icon>
        <span style="margin-left: 1.5em; font-weight: 500">
          {{nav.name| translate}}
        </span>
      </mat-list-item>
    </mat-list>
    <div class="flex" style="margin-left: 2em;" [class.hidden]="expand">
      <app-category-tree-list></app-category-tree-list>
    </div>
    <mat-list>
      <mat-list-item routerLink="/about">
        <mat-icon
          class="primary"
          style="width: 22px">contact
        </mat-icon>
        <span style="margin-left: 1.5em">
        {{'About us'| translate}}
      </span>
      </mat-list-item>
    </mat-list>
  </div>
</div>


