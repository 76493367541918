<app-resume-list
  *ngIf="resumes?.length > 0"
  [resumes]="resumes"
  (resumeSelected)="select($event)"></app-resume-list>
<div *ngIf="resumes?.length === 0" class="flex-col" style="align-items: center; text-align: center; margin-top: 10%">
  <div class="flex-col" style="padding: 2em; border-radius: 50%; background: white;border: 1px solid #efefef">
    <img [src]="'/assets/images/empty.svg'" style="width: 160px"/>
  </div>
  <h2>{{'You have not created your resume yet.'| translate}}</h2>
  <button mat-stroked-button
          (click)="build()">{{'Build resume'| translate}}
  </button>
</div>

