<div class="flex-col" >
  <h2>{{'Confirm'| translate}}</h2>
  <mat-form-field appearance="outline">
    <mat-label>{{'Coupon'| translate}}</mat-label>
    <input matInput [formControl]="couponInput" type="number">
  </mat-form-field>
  <div class="flex-col">
    <app-price *ngIf="$price" [price]="$price"></app-price>
  </div>
  <div class="flex" style="flex-flow: row-reverse " [class.loading-form]="loading">
    <button mat-flat-button color="primary" (click)="confirm()">{{'Confirm'| translate}}</button>
    <button mat-flat-button  mat-dialog-close>{{'Cancel'| translate}}</button>
  </div>
</div>
