import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AdPlanListComponent} from "./ad-plan-list.component";
import {MatCardModule} from "@angular/material/card";
import {MatRippleModule} from "@angular/material/core";
import {AdPlanTumbneilModule} from "../../components/ad-plan-tumbneil/ad-plan-tumbneil.module";
import {LangModule} from "../../../../lang.module";



@NgModule({
  declarations: [AdPlanListComponent],
  exports: [AdPlanListComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatRippleModule,
    AdPlanTumbneilModule,
    LangModule
  ]
})
export class AdPlanListModule { }
