<h1 class="mat-h1" style="margin: 1em 0.5em">{{'Payment confirmation request'| translate}}</h1>
<p>{{'Please enter the transaction id from the bank receipt.It will take a while for us to confirm the payment.'| translate}}</p>
<form [formGroup]="payForm" (ngSubmit)="submit()" [class.loading-form]="isLoading">
  <div class="flex-col">
    <mat-form-field appearance="outline">
      <mat-label>{{'Bank'| translate}}</mat-label>
      <mat-select formControlName="bankAccountId" required>
        <mat-option *ngFor="let bank of banks | async" [value]="bank.node.id">{{bank.node.bankName}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{'Transaction ID'| translate}}</mat-label>
      <input matInput [placeholder]="'Enter your email or phone number'| translate" formControlName="transactionId" required>
      <mat-icon matSuffix>user</mat-icon>
      <mat-error *ngIf="payForm.controls['transactionId'].hasError('required')">
        {{'Transaction Id is required'| translate}}
      </mat-error>
    </mat-form-field>

    <button mat-flat-button type="submit" color="primary">{{'Pay'| translate}}</button>

  </div>
</form>
