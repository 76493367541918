import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {JobService} from '../../service/job.service';
import {TranslateService} from "@ngx-translate/core";
import {StorageService} from "../../../../core/services/storage.service";

@Component({
  selector: 'app-vacancy-form',
  templateUrl: './vacancy-form.component.html',
  styleUrls: ['./vacancy-form.component.scss']
})
export class VacancyFormComponent implements OnInit {
  @Input() business;
  @Output() vacancyCreated: EventEmitter<any> = new EventEmitter<any>();
  loading = false;
  vacancyForm = this.formBuilder.group({
    name: ['', Validators.required],
    postedAt: ['', Validators.required],
    deadline: ['', Validators.required]
  });
  constructor(private formBuilder: FormBuilder, private jobService: JobService,
              translate: TranslateService , private storageService: StorageService

) {
    translate.use(this.storageService.getLanguage('lang'));
  }

  ngOnInit(): void {
  }
  submit(){
    this.loading = true;
    const vacancy = this.vacancyForm.value;
    vacancy.business = this.business;
    this.jobService.createVacancy(vacancy).subscribe(
      ($vacancy) => {
        console.log($vacancy);
        this.vacancyCreated.emit($vacancy);
      }
    );
  }

}
