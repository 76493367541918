import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from "@ngx-translate/core";
import {StorageService} from "../../../../core/services/storage.service";

@Component({
  selector: 'app-advertise-dialog',
  templateUrl: './advertise-dialog.component.html',
  styleUrls: ['./advertise-dialog.component.scss']
})
export class AdvertiseDialogComponent implements OnInit {
  products = [];
  maxHeight = '1080px';
  constructor(
    translate: TranslateService , private storageService: StorageService,
    private matDialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    translate.use(this.storageService.getLanguage('lang'));
    console.log(data);
    this.products = this.data.products;
    this.maxHeight = this.data.maxHeight;
  }

  ngOnInit(): void {
  }
  close() {
    this.matDialogRef.close();
  }
}
