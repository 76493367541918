import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit {
  matIcon = null;
  fontawsomeIcon = null;
  @Input() gradient = false;
  @Input() size = null;
  @Input() set icon(value){
    if (value.substring(0, 2) === 'fa'){
      this.fontawsomeIcon = value.split(' ');
    } else {
      this.matIcon = value;
    }
  }
  constructor() { }

  ngOnInit(): void {
  }

}
