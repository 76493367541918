import {Component, EventEmitter, OnInit, Output, Input} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {BusinessService} from '../../service/business.service';
import {COMMA, ENTER, SPACE} from '@angular/cdk/keycodes';
import {MatChipEvent, MatChipInputEvent} from '@angular/material/chips';
import {MatDialog} from '@angular/material/dialog';
import {ImageCropperDialogComponent} from '../../dialogs/image-cropper-dialog/image-cropper-dialog.component';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {TranslateService} from "@ngx-translate/core";
import {StorageService} from "../../../../core/services/storage.service";

@Component({
  selector: 'app-business-form',
  templateUrl: './business.component.html',
  styleUrls: ['./business.component.scss']
})
export class BusinessComponent implements OnInit {
  @Output() created: EventEmitter<any> = new EventEmitter<any>();
  businessForm = this.builder.group({
    id: [null],
    name : ['', Validators.required],
    businessType: ['', Validators.required],
    email: ['', Validators.email],
    telegram: [''],
    whatsapp: [''],
    description: [''],
  });
  businessTypes: any = [];
  businessSectors: any = [];
  separatorKeyCodes = [ENTER, COMMA, SPACE];
  phoneNumbers = [];
  isLoading = false;
  coordinates = null;
  phoneNumberInputControl = new FormControl('');
  formErrors = [];
  zoom = 11;
  location = {
    lat: 8.994816,
    lng: 38.7743744
  };
  @Input() phone = false;
  @Input('business') set business(value){
    value.businessType = value.businessType.id;
    this.businessForm.patchValue(value);
  }
  public Editor = ClassicEditor;
  businessDescription: any;
  constructor(
    private builder: FormBuilder,
    private businessService: BusinessService,
    private matDialog: MatDialog,
    translate: TranslateService , private storageService: StorageService

  ) {

    translate.use(this.storageService.getLanguage('lang'));
  }

  ngOnInit(): void {
    this.businessTypes = this.businessService.getBusinessTypes();
    this.businessSectors = this.businessService.getBusinessSectors();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((location) => {
        this.location.lat = location.coords.latitude;
        this.location.lng = location.coords.longitude;
        if (location.coords.accuracy > 4000){
          this.zoom = 11;
        } else if (location.coords.accuracy > 2000){
          this.zoom = 12;
        } else if (location.coords.accuracy > 1000){
          this.zoom = 13;
        } else {
          this.zoom = 15;
        }
      });
    }
  }

  add($event: MatChipInputEvent|any) {
    if (this.phoneNumberInputControl.value !== ''){
      this.phoneNumbers.push(this.phoneNumberInputControl.value);
      this.phoneNumberInputControl.setValue('');
    }
  }
  removed($event: MatChipEvent) {
    const phone = $event.chip.value.replace(' cancel', '').trim().trimEnd();
    const index = this.phoneNumbers.indexOf(phone);
    this.phoneNumbers.splice(index, 1);
  }

  submit() {
    this.isLoading = true;
    const business = this.businessForm.value;
    if (this.phone){
      business.phoneNumbers = this.phoneNumbers.map(
        (phone) => {
          if (phone.slice(0, 2) === '09') {
            phone = '+251' + phone.slice(1, phone.length);
          }
          return phone;
        }
      );
    }
    if (business.telegram?.slice(1) === '@'){
      business.telegram = business.telegram.replace('@', 'https://t.me/');
    }
    if (business.phoneNumbers?.length === 0 &&
        business.email === '' && this.coordinates == null
      && business.telegram === '' && business.whatsapp === ''){
      this.isLoading = false;
      this.formErrors.push('No contact or address is provided. Please enter at list one contact for your customers.');
      return;
    }
    if (!!this.coordinates){
      business.latitude = this.coordinates.lat;
      business.longitude = this.coordinates.lng;
    }
    console.log(business);
    this.businessService.createBusiness(business).subscribe(({data}) => {
      this.isLoading = false;
      this.created.emit(data.createBusiness.business);
    }, (error) => {
      this.isLoading = false;
    });
  }
  selectLocation(coordinates) {
    this.coordinates = coordinates.coords;
  }
}
