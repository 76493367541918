import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {observable, Observable} from 'rxjs';
import {ProductService} from '../../service/product.service';
import {Router} from '@angular/router';
import {ProductListService} from '../../service/product-list.service';
import { isObservable } from 'rxjs';
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {map, shareReplay, switchMap} from "rxjs/operators";
import {AuthService} from "../../../auth/service/auth.service";

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit, OnChanges, AfterViewInit{
  @Input() title;
  @Input() products = null;
  @Input() flow = 'wrap';
  @Input() row = 5;
  @Input() action = null;
  @Input() loading = false;
  @Input() pageSize = 5;
  @Input() orderBy = null;
  @Input() categoryDescendantOf = null;
  @Input() business = null;
  @Input() filter = false;
  @Input() isSaved = null;
  @Input() productViewOf;
  @Output() changeSelection: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('productList') productList: ElementRef;
  @ViewChild('listContainer') listContainer: ElementRef;
  selectedProducts = [];
  scrollPos = 0;
  infiniteSchroll = true;
  rangeObserver$;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
  hideFilter = false;
  cities: any;

  constructor(
    private cdr: ChangeDetectorRef,
    private productService: ProductService,
    private router: Router,
    private authService: AuthService,
    private breakpointObserver: BreakpointObserver,
    private productListService: ProductListService) {
  }

  ngOnInit(): void {
    this.cities = this.authService.cities();
    this.isHandset$.subscribe((isHandset) => this.hideFilter = isHandset);
    this.productListService.init();
    const rangeObserver$ = new Observable<any>((observable)  => {
      this.rangeObserver$ = observable;
    });
    rangeObserver$.pipe(
      switchMap(range => {
        this.productListService.init();
        this.productListService.price_Lte = range.max;
        this.productListService.price_Gte = range.min;
        this.productListService.order = this.orderBy;
        this.productListService.productView = this.productViewOf;
        this.productListService.categoryDescendantOf = this.categoryDescendantOf;
        this.productListService.business = this.business;
        this.productListService.isSaved = this.isSaved;
        this.loading = true;
        return this.productListService.next();
      }),
    ).subscribe((products) => {
        this.loading = false;
        this.products = [];
        this.products = this.products.concat(products);
      },
      (error) => {
        this.loading = false;
      });
  }
  ngAfterViewInit(): void {
    this.loading = false;
    if (!this.products){
      this.productListService.pageSize = this.pageSize;
      this.products = [];
      this.listContainerResize();
      this.next();
    }else{
      this.infiniteSchroll = false;
    }
    this.cdr.detectChanges();
  }
  next() {
    this.productListService.order = this.orderBy;
    this.productListService.categoryDescendantOf = this.categoryDescendantOf;
    this.productListService.business = this.business;
    this.productListService.productView = this.productViewOf;
    this.productListService.isSaved = this.isSaved;
    this.loading = true;
    this.productListService.next().subscribe(
      (products) => {
        this.loading = false;
        this.products = this.products.concat(products);
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  click($event, product): void {
    switch (this.action) {
      case 'select':
        this.selectProduct(product);
        break;
      default:
        this.router.navigate(['product/view/' + product.node.id]);
    }
  }

  selectProduct(product): void {
    product.selected = !product.selected;
    if (product.selected) {
      this.selectedProducts.push(product);
    } else {
      this.selectedProducts = this.selectedProducts.filter((e) => {
        return e !== product;
      });
    }
    this.changeSelection.emit({
      // @ts-ignore
      product: product,
      selectedProducts: this.selectedProducts
    });
  }

  rightScroll() {
    let count = 0;
    const increment = 30;
    const animation = setInterval(() => {
      count += increment;
      if (count > 320) {
        clearInterval(animation);
      }
      this.productList.nativeElement.scrollLeft += increment;
    }, 1000 / 30);
  }

  leftScroll() {
    let count = 0;
    const increment = 30;
    const animation = setInterval(() => {
      count += increment;
      if (count > 320) {
        clearInterval(animation);
      }
      this.productList.nativeElement.scrollLeft -= increment;
    }, 1000 / 30);
  }

  getScrollLeft() {
    return this.productList?.nativeElement.scrollLeft;
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  onScroll() {
    if (this.infiniteSchroll){
      this.productListService.pageSize = 3;
      this.next();
    }
  }
  scroll(){
     this.scrollPos = this.productList?.nativeElement.scrollLeft;
  }
  listContainerResize() {
    this.productListService.pageSize = Math.round(this.listContainer?.nativeElement.offsetWidth / 180) + 1;
    if (this.flow === 'wrap'){
      this.productListService.pageSize *= this.row;
      this.pageSize = this.row * 2;
    }
  }

  categoryFilter($event: any) {
      this.productListService.init();
  }

  filterRange(range: any) {
    this.rangeObserver$.next(range);
  }

  cityFilter(city: any) {
    this.productListService.city = city.value;
    console.log(city.value);
    this.products = [];
    this.next();
  }

}
