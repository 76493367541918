import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AdvertiseDialogComponent} from "./advertise-dialog.component";
import {AdvertiseProductComponent} from "../../pages/advertise-product/advertise-product.component";
import {PackageListModule} from "../../list/package-list/package-list.module";
import {AdPlanListModule} from "../../list/ad-plan-list/ad-plan-list.module";
import {MatButtonModule} from "@angular/material/button";
import {AdvertiseProductModule} from "../../pages/advertise-product/advertise-product.module";
import {HttpClient} from "@angular/common/http";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";



export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AdvertiseDialogComponent],
  exports: [AdvertiseDialogComponent],
  imports: [
    CommonModule,
    PackageListModule,
    AdPlanListModule,
    MatButtonModule,
    AdvertiseProductModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ]
})
export class AdvertiseDialogModule { }
