import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SelectBusinessDialogComponent} from "./select-business-dialog.component";
import {BusinessListModule} from "../../../product/list/business-list/business-list.module";



@NgModule({
  declarations: [SelectBusinessDialogComponent],
  exports: [SelectBusinessDialogComponent],
  imports: [
    CommonModule,
    BusinessListModule
  ]
})
export class SelectBusinessDialogModule { }
