import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {RegisterService} from '../../service/register.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {of} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, switchMap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmUserComponent} from '../../components/confirm-user/confirm-user.component';
import {AuthService} from '../../service/auth.service';
import {UserService} from '../../service/user.service';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {TranslateService} from "@ngx-translate/core";
import {StorageService} from "../../../../core/services/storage.service";

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  @Output() signup: EventEmitter<any> = new EventEmitter<any>();
  @Output() goLogin: EventEmitter<any> = new EventEmitter<any>();

  step = 1;
  registeredUser = null;
  canFinish = false;
  filteredUsers = [];
  selectedUser = null;
  selectedUserError = null;
  confirm = {
    email: false,
    phone: false
  };
  emailExistValidator = (resisterService: RegisterService) => (c: AbstractControl) => {
    if (!c || String(c.value).length === 0) {
      return of(null);
    }
    return this.registerService.emailExist(c.value).pipe(
      map((users) => {
        return users ? {exist: true} : null;
      })
    );
  }
  phoneExistValidator = (resisterService: RegisterService) => (c: AbstractControl) => {
    if (!c || String(c.value).length === 0) {
      return of(null);
    }
    let phone = c.value;
    if (phone.slice(0, 1) === '0'){
      phone = '+251' + phone.slice(1);
    }
    return this.registerService.phoneExist(phone).pipe(
      map((users) => {
        return users ? {exist: true} : null;
      })
    );
  }
  signupForm = this.builder.group({
    name: ['', Validators.required],
    email: new FormControl('', Validators.email, this.emailExistValidator(this.registerService)),
    phone: new FormControl('', Validators.pattern('^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$'), this.phoneExistValidator(this.registerService)),
    password: ['', Validators.required, Validators.minLength(6)],
    referredBy: [null],
    confirmPassword:
      new FormControl(null, [Validators.required])
  }, {validator: this.passwordConfirming});
  isLoading = false;
  error = null;

  constructor(
    private auth: AuthService,
    private builder: FormBuilder,
    private registerService: RegisterService,
    private userService: UserService,
    public router: Router,
    public dialog: MatDialog,
    private activeRoute: ActivatedRoute,
    private snackBar: MatSnackBar, private translate: TranslateService , private storageService: StorageService ) {
    translate.use(this.storageService.getLanguage('lang'));
  }

  passwordConfirming(c: AbstractControl): { match: boolean } {
    if (c.get('password').value !== c.get('confirmPassword').value) {
      c.get('confirmPassword').setErrors({
        match: true
      });
      return {match: true};
    }
  }

  ngOnInit(): void {
    this.activeRoute.queryParams.subscribe(
      (queryParam) => {
        if (queryParam?.confrim){
          this.step = 2;
        }
      }
    );
    this.signupForm.controls['referredBy'].valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        map( phone => {
          if (phone.slice(0, 1) === '0'){
            phone = '+251' + phone.slice(1);
          }
          return phone;
        }),
        switchMap(phone => {
          return this.userService.searchUsers(phone);
        }),
      )
      .subscribe(
      (users) => {
        this.selectedUserError = null;
        this.filteredUsers = users;
      });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.signupForm.controls;
  }

  submit() {
    const user = this.signupForm.value;
    if (
      !!user.email ||
      !!user.phone ||
      user.email !== '' ||
      user.phone !== ''
    ) {
      this.isLoading = true;
      this.registerService.registerUser(user.name, user.password, user.email.toLowerCase(), user.phone, this.selectedUser?.id)
        .subscribe(
        ({data}) => {
                this.isLoading = false;
                this.signup.emit();
                this.confirmation();
                // this.step = 2 ;
                // @ts-ignore
                this.registeredUser = data.registerUser.user;
              }, (error) => {
                this.snackBar.open(this.translate.instant('Sorry some error occurred! We are working on it...'),'', {
                                    duration: 3000
                                  });
                this.isLoading = false;
              });
            } else {
      this.error = 'Either email or password is required';
      this.signupForm.get('email').setErrors({
        required: true
      });
      this.signupForm.get('phone').setErrors({
        required: true
      });
    }
  }

  finish() {
    this.signup.emit();
  }
  confirmation() {
    if (!!this.auth.isLogedIn() && !this.auth.decodeToken()?.isUnregistered){
      if (!this.auth.userVerified()) {
        this.dialog.open(ConfirmUserComponent, {
          width: '380px',
          disableClose: true,
        });
      }
    }
  }

  referredBy($event: MatAutocompleteSelectedEvent  ) {
    this.selectedUser = $event.option.value;
    this.signupForm.controls['referredBy'].setValue(this.selectedUser.name);
  }

  referredByFocusOut($event: any) {
    let phone = this.signupForm.value.referredBy;
    if (phone.slice(0, 1) === '0'){
      phone = '+251' + phone.slice(1);
    }
    this.userService.searchUsers(phone).subscribe(
      (users) => {
        if (users.length === 1){
          this.selectedUser = users[0].node;
          this.signupForm.controls['referredBy'].setValue(this.selectedUser.name);
        }else{
          this.selectedUserError = 'User dose not exist';
        }
      }
    );
  }
}
