<h1 class="mat-h1">{{title| translate}}</h1>
<form [formGroup]="productForm" (ngSubmit)="submit()" [class.loading-form]="isLoading">
  <div class="flex-col">
    <div class="flex">
      <div class="flex-col" style="width: 100%">
        <mat-form-field appearance="outline">
          <mat-label>{{'Name'| translate}}</mat-label>
          <input matInput type="text" formControlName="name"/>
        </mat-form-field>
        <mat-hint style="margin-top: -18px; font-size: .95em; margin-bottom: 1em; margin-left: 1.5em">
          {{'Please make the name of the product as descriptive as possible for your customers.'| translate}}</mat-hint>
      </div>
    </div>
    <div class="flex">
      <mat-form-field appearance="outline" style="width: 50%">
        <mat-label>{{'Price'| translate}}</mat-label>
        <input matInput type="number" formControlName="price"/>
      </mat-form-field>
      <mat-form-field appearance="outline" style="width: 50%">
        <mat-label>{{'Product is for...'| translate}}</mat-label>
        <mat-select required formControlName="isRental">
          <mat-option [value]="false">{{'Sell'| translate}}</mat-option>
          <mat-option [value]="true">{{'Rent'| translate}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="flex" style="justify-content: space-between;text-align: center">
      <mat-checkbox formControlName="isNegotiable">{{'Price is negotiable'| translate}}</mat-checkbox>
      <mat-checkbox formControlName="isTobeImported">{{'Is to be imported'| translate}}</mat-checkbox>
      <mat-checkbox formControlName="isService">{{'It is service ?'| translate}}</mat-checkbox>
    </div>
    <mat-form-field appearance="outline">
      <mat-label>{{'City'| translate}}</mat-label>
      <mat-select formControlName="city">
        <mat-option
          *ngFor="let city of cities | async"
          [value]="city.node.id">{{city.node.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <div class="flex">
      <mat-form-field appearance="outline">
        <mat-label>{{'Description'| translate}}</mat-label>
        <textarea matInput formControlName="description"></textarea>
      </mat-form-field>
    </div>
    <div class="flex" *ngIf="uploadImage">
      <ngx-dropzone (change)="onSelect($event)" [accept]="'image/*'" style="width: 100%">
        <ngx-dropzone-label>{{'Drag and drop or click hear to upload pictures.'| translate}}</ngx-dropzone-label>
        <ngx-dropzone-image-preview
          ngProjectAs="ngx-dropzone-preview"
          *ngFor="let f of files" [file]="f" [removable]="true" (removed)="onRemove(f)">
          <ngx-dropzone-label>{{ f.name }}</ngx-dropzone-label>
        </ngx-dropzone-image-preview>
      </ngx-dropzone>
    </div>
    <mat-hint style="font-size: .95em;" >{{'Please try to show all dimensions of your product with clear pictures.'| translate}}</mat-hint>
    <mat-error *ngIf="files.length > 5">{{'Maximum allowed files are 5, please remove'| translate}} {{files.length - 5}} {{'images'| translate}}.</mat-error>
    <button type="submit" mat-flat-button color="primary"
            [disabled]="files.length > 5"
    style="margin-top: 1em">{{action | translate}}</button>
  </div>
</form>
