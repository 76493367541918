import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textLength'
})
export class TextLengthPipe implements PipeTransform {

  transform(value: string, ...args: number[]): unknown {

    const length = args[0];
    if (length < value?.length) {
      return value.slice(0, length) + '...';
    }else{
      return value;
    }
  }

}
