import {Component, OnInit, Input, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  title = 'Confirmation';
  text = 'Do you really want to delete?';
  constructor(public matDialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {

    if (!!this.data?.title) {
      this.title = this.data?.title;
    }
    if (!!this.data?.text){
      this.text = this.data?.text;
    }
  }

}
