<h1 class="mat-h1" style="margin-left: 2em">{{'Advertisement plans'| translate}}</h1>
<div class="flex"
     [style.max-height]="maxHeight" style="justify-content: center; flex-flow:wrap; overflow-y: scroll">
  <mat-card
    *ngFor="let plan of plans"
    matRipple
    style="margin: 8px;width: 160px;border-top: 4px solid Orange">
    <app-ad-plan-tumbneil
      [plan]="plan"
      (click)="onSelectPlan(plan)"
    >
    </app-ad-plan-tumbneil>
  </mat-card>

</div>
