import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ProductImageDialogComponent} from "./product-image-dialog.component";
import {ProductImagesModule} from "../../components/product-images/product-images.module";



@NgModule({
  declarations: [ProductImageDialogComponent],
  exports: [ProductImageDialogComponent],
  imports: [
    CommonModule,
    ProductImagesModule
  ]
})
export class ProductImageDialogModule { }
