import {Component, EventEmitter, Input, Output, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {MatChipEvent, MatChipInputEvent} from '@angular/material/chips';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {CategoryService} from '../../service/category.service';
import {Observable} from 'rxjs';
import {TranslateService} from "@ngx-translate/core";
import {StorageService} from "../../../../core/services/storage.service";

@Component({
  selector: 'app-category-property-form',
  templateUrl: './category-property-form.component.html',
  styleUrls: ['./category-property-form.component.scss']
})
export class CategoryPropertyFormComponent implements OnInit {
  @Input() categoryId;
  @Output() created: EventEmitter<any> = new EventEmitter<any>();
  propertyForm = this.formBuilder.group({
    name: ['', Validators.required],
    unit: [''],
    dataType: ['', Validators.required],
    isRequired: [''],
    isEnumerated: [''],
    possibleValues: ['']
  });
  possibleValues = [];
  separatorKeyCodes = [ENTER, COMMA];
  dataTypes: Observable<any>;
  constructor(private formBuilder: FormBuilder, private categoryService: CategoryService,
              translate: TranslateService , private storageService: StorageService
  ) {
    translate.use(this.storageService.getLanguage('lang'));
  }

  ngOnInit(): void {
    this.dataTypes = this.categoryService.getDataTypes();
  }

  submit() {
    const input = this.propertyForm.value;
    if (input.isEnumerated){
      input.possibleValues = this.possibleValues;
    }
    input.categoryId = this.categoryId;
    return this.categoryService.addProperty(input).subscribe(
      (property) => {
        this.created.emit(property);
      }
    );
  }

  add($event: MatChipInputEvent) {
    this.possibleValues.push($event.value);
    $event.input.value = '';
  }

  removed($event: MatChipEvent) {
    const phone = $event.chip.value.replace(' cancel', '').trim().trimEnd();
    const index = this.possibleValues.indexOf(phone);
    this.possibleValues.splice(index, 1);
  }

}
