import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmUserComponent } from './confirm-user.component';
import {MatButtonModule} from '@angular/material/button';
import {ConfirmUserFormModule} from "../../forms/confirm-user-form/confirm-user-form.module";
import {LangModule} from "../../../../lang.module";



@NgModule({
  declarations: [ConfirmUserComponent],
  exports: [
    ConfirmUserComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    ConfirmUserFormModule,
    LangModule
  ]
})
export class ConfirmUserModule { }
