import {Injectable} from '@angular/core';
import {Apollo} from 'apollo-angular';
import gql from 'graphql-tag';
import {map} from 'rxjs/operators';
import {Observable, pipe} from 'rxjs';
import {AuthService} from '../../auth/service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentOrderService {

  constructor(private apollo: Apollo, private authService: AuthService) {
  }

  myPaymentOrders(): Observable<any> {
    return this.authService.me(false, true).pipe(
      // @ts-ignore
      map((data) => data.orderedBy.edges)
    );
  }

  paymentConfirmationRequest(input): Observable<any> {
    const paymentConfirmationRequest = gql`
      mutation paymentConfirmationRequest($input: PaymentConfirmationRequestMutationInput!){
        paymentConfirmationRequest(input: $input){
          paymentOrder{
            id
            transactionId
            price
            isChecked
            verifiedAt
            verification
            bankAccount{
              id
              bankName
            }
            verifiedAt
            verifiedBy{
              id
              name
            }
            createdAt
          }
        }
      }
    `;
    return this.apollo.mutate({
      mutation: paymentConfirmationRequest,
      variables: {
        input: input
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.paymentConfirmationRequest)
    );
  }

  paymentOrders(isChecked = false, verified = null): Observable<any> {
    const paymentOrdersQuery = gql`
      query paymentOrders($isChecked: Boolean, $verified: Boolean){
        paymentOrders (isChecked: $isChecked, verification:  $verified){
          edges{
            node{
              id
              idInt
              transactionId
              verifiedAt
              price
              verification
              createdAt
              isChecked
              bankAccount{
                id
                bankName
              }
              orderedBy{
                id
                name
              }
            }
          }
        }
      }
    `;
    return this.apollo.query({
      query: paymentOrdersQuery,
      variables: {
        isChecked: isChecked,
        verified: verified,
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.paymentOrders.edges)
    );
  }

  getCoupon(coupon): Observable<any> {
    const couponQuery = gql`
      query coupon($id: ID!){
        coupon (id: $id){
          id,
          discount,
          percentile,
          active,
          title
        }
      }
    `;
    return this.apollo.query({
      query: couponQuery,
      variables: {
        id: coupon,
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.coupon)
    );
  }

  premiumSubscription(input): Observable<any> {
    const paymentConfirmationRequest = gql`
      mutation businessPremiumSubscription($input: BusinessPremiumSubscriptionMutationInput!){
        businessPremiumSubscription(input: $input){
          businessPremiumSubscription{
            id
          }
        }
      }
    `;
    return this.apollo.mutate({
      mutation: paymentConfirmationRequest,
      variables: {
        input: input
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.businessPremiumSubscription)
    );
  }

  validatePayment(input: any): Observable<any> {
    const validatePaymentMutation = gql`
      mutation validatePaymentMuation($input: ValidatePaymentMutationInput!){
        validatePayment(input: $input){
          paymentOrder{
            id
            transactionId
            price
            isChecked
            verifiedAt
            verification
            bankAccount{
              id
              bankName
            }
            verifiedAt
            verifiedBy{
              id
              name
            }
            createdAt
          }
        }
      }
    `;
    return this.apollo.mutate({
      mutation: validatePaymentMutation,
      variables: {
        input: input
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.validatePayment.paymentOrder)
    );
  }
}
