<mat-toolbar style="background-color: white; border-bottom:1px solid #eee; padding: 0px" [class.mat-toolbar-large]="!(isHandset$ | async)">
  <button
    type="button"
    aria-label="Toggle sidenav"
    mat-icon-button
    (click)="menu.emit(null)">
<!--    <button-->
<!--      type="button"-->
<!--      aria-label="Toggle sidenav"-->
<!--      mat-icon-button-->
<!--      (click)="menu.emit(null)"-->
<!--      *ngIf="isHandset$ | async">-->
    <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
  </button>
  <span class="left"><ng-content select="[left]"></ng-content></span>
  <span class="center"><ng-content select="[center]"></ng-content></span>
  <span class="right"><ng-content select="[right]"></ng-content></span>
</mat-toolbar>
