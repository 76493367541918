<mat-sidenav-container class="sidenav-container" id="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport
               [class.sidenav-large-screen]="!(isHandset$ | async)"
               [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
               [mode]="(isHandset$ | async) ? 'over' : 'side'"
               [opened]="false"
               style="top: 50px;"
  >
<!--    <mat-sidenav #drawer class="sidenav" fixedInViewport-->
<!--                 [class.sidenav-large-screen]="!(isHandset$ | async)"-->
<!--                 [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"-->
<!--                 [mode]="(isHandset$ | async) ? 'over' : 'side'"-->
<!--                 [opened]="(isHandset$ | async) === true"-->
<!--                 style="top: 50px;"-->
<!--    >-->
    <ng-content select="[navigation]"></ng-content>
  </mat-sidenav>
  <mat-sidenav-content class="sidenav-content">
    <ng-content select="[content]"></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
