import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VacancyFormComponent } from './vacancy-form.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatButtonModule} from '@angular/material/button';
import {ReactiveFormsModule} from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import {LangModule} from "../../../../lang.module";



@NgModule({
  declarations: [VacancyFormComponent],
  exports: [
    VacancyFormComponent
  ],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatButtonModule,
        ReactiveFormsModule,
        MatNativeDateModule,
        LangModule
    ],
  providers: [
    MatDatepickerModule,
    MatNativeDateModule,
  ]
})
export class VacancyFormModule { }
