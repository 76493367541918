import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {Router} from '@angular/router';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnChanges {
  @Output() search: EventEmitter<any> = new EventEmitter<any>();
  @Input() key: string;
  searchForm = this.fromGroup.group({
    search: ['']
  });
  constructor(private fromGroup: FormBuilder, private router: Router) { }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.searchForm.controls.search.setValue(this.key);
  }
  submit(): void{
    const key = this.searchForm.value.search;
    const goto = '/search/' + key;
    console.log(goto);
    this.search.emit(key);
    this.router.navigate([goto]);
  }
}
