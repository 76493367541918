import { Component, Input, OnInit } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {StorageService} from "../../../../core/services/storage.service";

@Component({
  selector: 'app-ad-plan-tumbneil',
  templateUrl: './ad-plan-tumbneil.component.html',
  styleUrls: ['./ad-plan-tumbneil.component.scss']
})
export class AdPlanTumbneilComponent implements OnInit {
  @Input() plan;
  constructor(translate: TranslateService , private storageService: StorageService) {
    translate.use(this.storageService.getLanguage('lang'));
  }

  ngOnInit(): void {
  }

}
