import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CouponDialogComponent } from './coupon-dialog.component';
import {CouponComponentModule} from '../../components/coupon-component/coupon-component.module';



@NgModule({
  declarations: [
    CouponDialogComponent
  ],
    imports: [
        CommonModule,
        CouponComponentModule,
    ]
})
export class CouponDialogModule { }
