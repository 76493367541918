import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { BankAccountsDataSource, BankAccountsItem } from './bank-accounts-datasource';
import {BankService} from '../../service/bank.service';
import {TranslateService} from "@ngx-translate/core";
import {StorageService} from "../../../../core/services/storage.service";

@Component({
  selector: 'app-bank-accounts',
  templateUrl: './bank-accounts.component.html',
  styleUrls: ['./bank-accounts.component.scss']
})
export class BankAccountsComponent implements AfterViewInit, OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<BankAccountsItem>;
  dataSource: BankAccountsDataSource;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['bankName', 'accountName', 'accountNumber'];
  constructor(private bankService: BankService,
              translate: TranslateService , private storageService: StorageService
  ){
    translate.use(this.storageService.getLanguage('lang'));
  }
  ngOnInit() {
    this.dataSource = new BankAccountsDataSource([]);
    this.bankService.getBankAccounts()
      .subscribe(
        (accounts) => {
          console.log(accounts);
          this.table.dataSource = new BankAccountsDataSource(accounts);
        }
      );
  }

  ngAfterViewInit() {
    this.table.dataSource = this.dataSource;
  }
}
