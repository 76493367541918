import { Injectable } from '@angular/core';
import {Apollo} from 'apollo-angular';
import gql from 'graphql-tag';
import {StorageService} from '../../../core/services/storage.service';
import {map, tap} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {
  createUnregisteredUserMutation = gql`
    mutation createUnregisteredUser{
      createUnregisteredUser{
        user{
          id
          isUnregistered
        }
        token
        refreshToken
      }
    }`;
  constructor(private apollo: Apollo, private storage: StorageService) { }
  createUnregisteredUser(){
    return this.apollo.mutate({
      mutation: this.createUnregisteredUserMutation,
    }).pipe(
      tap(({data}) => {
        // @ts-ignore
        this.storage.setToken(data.createUnregisteredUser.token, data.createUnregisteredUser.refreshToken);
      })
    );
  }
  registerUser(name, password, email, phone, referredBy){
    const registerUserMutation = gql`
      mutation registerUser($name: String!, $email: String, $password: String!,$phone: String,$referredBy: ID ){
        registerUser(
          email: $email,
          phone: $phone,
          name: $name,
          password: $password,
          referredBy: $referredBy
        ) {
          user{
            id
            email
            phone
          }
          token
          refreshToken
        }
      }`;
    // @ts-ignore
    return this.apollo.mutate({
      mutation: registerUserMutation,
      variables: {
        name: name,
        password: password,
        email: email,
        phone: phone,
        referredBy: referredBy
      }
    }).pipe(
      // @ts-ignore
      tap(({data}) => this.storage.setToken(data.registerUser.token, data.registerUser.refreshToken))
    );
  }
  emailExist(email): Observable<any> {
    const emailExist = gql`
      query emailExist($email: String!){
        users(email:$email){
          edges{
            node{
              id
            }
          }
        }
      }
    `;
    return this.apollo.query({
      query: emailExist,
      variables: {
        email: email
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.users.edges.length > 0)
    );
  }
  phoneExist(phone): Observable<any> {
    const emailExist = gql`
      query emailExist($phone: String!){
        users(phone: $phone){
          edges{
            node{
              id
            }
          }
        }
      }
    `;
    return this.apollo.query({
      query: emailExist,
      variables: {
        phone: phone
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.users.edges.length > 0)
    );
  }
  confirm(input): Observable<any> {
    const confirmUserMutation = gql`
      mutation confirmUser($input: ConfirmUserMutationInput!){
        confirmUser(input: $input){
          emailConfirmation
          phoneConfirmation
          token
          refreshToken
        }
      }
    `;
    return this.apollo.mutate({
      mutation: confirmUserMutation,
      variables: {
        input: input
      }
    }).pipe(
      // @ts-ignore
      tap(({data}) => this.storage.setToken(data.confirmUser.token, data.confirmUser.refreshToken)),
      // @ts-ignore
      map(({data}) => data.confirmUser)
    );
  }
  sendConfirmation(input): Observable<any> {
    const confirmUserMutation = gql`
      mutation sendUserConfirmation($input: SendUserConfirmationMutationInput!){
        sendUserConfirmation(input: $input){
          emailConfirmation
          phoneConfirmation
        }
      }
    `;
    return this.apollo.mutate({
      mutation: confirmUserMutation,
      variables: {
        input: input
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.sendUserConfirmation)
    );
  }

  resetPassword(input): Observable<any> {
    const RESET_PASSWORD_MUTATION = gql`
        mutation RESET_PASSWORD_MUTATION($input: ChangePasswordMutationInput!){
          resetPassword(input: $input){
            user{
              id
              name
              email
              phone
            }
          }
        }
    `;
    return this.apollo.mutate({
      mutation: RESET_PASSWORD_MUTATION,
      variables: {
        input: input
      }
    });
  }
}
