import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmallNotiListComponent } from './small-noti-list/small-noti-list.component';
import {SmallNotiComponentModule} from '../components/small-noti-component/small-noti-component.module';



@NgModule({
  declarations: [SmallNotiListComponent],
  exports: [
    SmallNotiListComponent
  ],
  imports: [
    CommonModule,
    SmallNotiComponentModule
  ]
})
export class ListsModule { }
