import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-category-property-dialog',
  templateUrl: './category-property-dialog.component.html',
  styleUrls: ['./category-property-dialog.component.scss']
})
export class CategoryPropertyDialogComponent implements OnInit {
  categoryId = null;

  constructor(@Inject(MAT_DIALOG_DATA) private data, private matDialogRef: MatDialogRef<any>) { }

  ngOnInit(): void {
    this.categoryId = this.data.category.id;
  }

  close($event: any) {
    this.matDialogRef.close($event);
  }
}
