import { Injectable } from '@angular/core';
import {Apollo} from 'apollo-angular';
import {Observable} from 'rxjs';
import gql from 'graphql-tag';
import {debounceTime, map, throttleTime} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductListService {
  private $pageInfo = null;
  private $order: string;
  private $pageSize: number;
  private $categoryDescendantOf: string;
  private $business: string;
  private $price_Lte: number;
  private $price_Gte: number;
  private $city: string;
  private $productView: String;
  private $isSaved: boolean;
  private $categories: [string];

  private PRODUCTS_QUERY = gql`
    query products(
      $first: Int,
      $after: String,
      $order: String,
      $city: ID,
      $categoryDecendent: ID,
      $price_Lte: Float,
      $price_Gte: Float,
      $productView: [ID],
      $isSaved: Boolean,
      $business: ID){
      products(
        first: $first,
        after: $after,
        orderBy: $order,
        city: $city,
        business: $business,
        price_Lte: $price_Lte,
        price_Gte: $price_Gte,
        categoryDecendent: $categoryDecendent,
        productView: $productView,
        userproductview_IsSaved: $isSaved
        ){
        pageInfo{
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges{
          node{
            id
            name
            price
            views
            rate
            description
            productimageSet{
              edges{
                node{
                  id
                  image
                  imgUrl
                  imgTumbUrl
                  imgWebpUrl
                  imgTumbWebpUrl
                }
              }
            }
          }
        }
      }
    }
  `;
  constructor(private apollo: Apollo) { }
  init(): void{
    this.$pageInfo = null;
  }
  next(): Observable<any>{
    return this.apollo.query({
      query: this.PRODUCTS_QUERY,
      variables: {
        first: this.$pageSize,
        after: (this.$pageInfo) ? this.$pageInfo.endCursor : null,
        order: this.$order,
        city: this.$city,
        business: this.$business,
        price_Lte: this.$price_Lte,
        price_Gte: this.$price_Gte,
        productView: this.$productView,
        isSaved: this.$isSaved,
        categoryDecendent: this.categoryDescendantOf
      }
    }).pipe(
      debounceTime(1000),
      map(({data}) => {
        // @ts-ignore
        this.$pageInfo = data.products.pageInfo;
        // @ts-ignore
        return data.products.edges;
      })
    );
  }
  public get pageSize() {
    return this.$pageSize;
  }
  public set pageSize(pageSize: number) {
    this.$pageSize = pageSize;
  }
  public get isSaved() {
    return this.$isSaved;
  }
  public set isSaved(pageSize: boolean) {
    this.$isSaved = pageSize;
  }
  public get order() {
    return this.$order;
  }
  public get price_Lte() {
    return this.$price_Lte;
  }
  public get price_Gte() {
    return this.$price_Gte;
  }
  public get productView() {
    return this.$productView;
  }
  public set productView(productView: String) {
    this.$productView = productView;

  }public set price_Lte(price_Lte: number) {
    this.$price_Lte = price_Lte;
  }
  public get city() {
    return this.$city;
  }
  public set city(city: string) {
    this.$city = city;
  }
  public set price_Gte(price_Gte: number) {
    this.$price_Gte = price_Gte;
  }
  public set order(order: string) {
    this.$order = order;
  }
  public get business() {
    return this.$business;
  }
  public set business(business: string) {
    this.$business = business;
  }
  public get categoryDescendantOf(){
    return this.$categoryDescendantOf;
  }
  public set categoryDescendantOf(categoryDescendantOf: string){
    this.$categoryDescendantOf = categoryDescendantOf;
  }
}
