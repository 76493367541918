import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {Apollo} from 'apollo-angular';
import {map} from 'rxjs/operators';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root'
})
export class PackageService {

  constructor(private apollo: Apollo) { }
  getPackages(packageCategory): Observable<any>{
    const packagesQuery = gql`
        query adPackages($packageCategory: String!){
          adPackages(packageCategory: $packageCategory){
            edges{
              node{
                id
                name
                description
                adplanSet{
                  edges{
                    node{
                      id
                      name
                      duration
                      price
                      package{
                        id
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
    `;
    return this.apollo.query({
      query: packagesQuery,
      fetchPolicy: 'network-only',
      variables: {
        packageCategory: packageCategory
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.adPackages.edges)
    );
  }
  getPackage(id, category = null): Observable<any>{
    const AD_PACKAGE_QUERY = gql`
      query adPackageMuatition($id: ID!, $category: ID){
        adPackage(id: $id){
          id
          name
          description
          adplanSet(category: $category){
            edges{
              node{
                id
                name
                duration
                price
                package{
                  id
                  name
                }
              }
            }
          }
        }
      }
    `;
    return this.apollo.query({
      query: AD_PACKAGE_QUERY,
      variables: {
        id: id,
        category: category,
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.adPackage )
    );
  }
  getAdPlan(id): Observable<any> {
    const AD_PLAN_QUERY = gql`
      query adPlanQuery($id: ID!) {
        adPlan(id: $id){
          id
          name
          duration
          price
        }
      }
    `;
    return this.apollo.query({
      query: AD_PLAN_QUERY,
      variables: {
        id: id
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.adPlan)
    );
  }

}
