<div class="aspect-ratio-box"
    [style.padding-top] = "(ratio*100)+'%'"
     *ngIf="bannerImgs.length > 0"
     style="border-radius: 12px;"
>
  <gallery
    class="aspect-ratio-box-inside"
    [id]="id"
    [items]="bannerImgs"
    [counter]="false"
    [dots]="true"
    [thumb]="false"
    [autoPlay]="true"
    [playerInterval]="5000"
    [nav]="false"
    [imageSize]="'contain'"
    (indexChange)="onIndexChange($event)"
    (itemClick)="onItemClick($event)"
    style="border-radius: 12px;"
  ></gallery>
</div>

<!--    *ngIf="bannerImgs.length > 0"-->
