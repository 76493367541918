<div class="mat-elevation-z0" style="max-height: 580px; overflow-y: scroll">
  <h2 style="margin-bottom: 0.2em;">{{'Serdo bank accounts'| translate}}</h2>
  <p>{{'You can make payments to serdo through any of the following bank accounts.'| translate}}</p>
  <table mat-table class="full-width-table" matSort aria-label="Elements">
    <!-- Name Column -->
    <ng-container matColumnDef="bankName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Bank name'| translate}}</th>
      <td mat-cell *matCellDef="let row">{{row.node.bankName}}</td>
    </ng-container>
    <!-- Id Column -->
    <ng-container matColumnDef="accountName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Account name'| translate}}</th>
      <td mat-cell *matCellDef="let row">{{row.node.accountName}}</td>
    </ng-container>
    <!-- Id Column -->
    <ng-container matColumnDef="accountNumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Account number'| translate}}</th>
      <td mat-cell *matCellDef="let row">{{row.node.accountNumber}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

</div>
