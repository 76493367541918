import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, Validators} from '@angular/forms';
import {RegisterService} from '../../service/register.service';
import {of} from 'rxjs';
import {map} from 'rxjs/operators';
import {MatSnackBar, SimpleSnackBar} from '@angular/material/snack-bar';
import {AuthService} from '../../service/auth.service';
import {log} from "util";
import {StorageService} from "../../../../core/services/storage.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-confirm-user-form',
  templateUrl: './confirm-user-form.component.html',
  styleUrls: ['./confirm-user-form.component.scss']
})
export class ConfirmUserFormComponent implements OnInit {
  constructor(
    private registerService: RegisterService,
    private snackBar: MatSnackBar,
    public authService: AuthService,
    private fromBuilder: FormBuilder,
    private translate: TranslateService , private storageService: StorageService

) {
    translate.use(this.storageService.getLanguage('lang'));
  }
  @Input() confirm = 'email';
  @Input() to = null;
  @Output() confirmed: EventEmitter<any> = new EventEmitter<any>();
  loading = false;
  $confirmed = null;
  resend = true;
  codeValidator = (resisterService: RegisterService) => (c: AbstractControl) => {
    if (!c || String(c.value).length < 6) {
      return of(null);
    }
    let confirmationInput;
    if (this.confirm === 'email'){
      confirmationInput = {
        emailConfirmationCode: c.value,
        phoneConfirmationCode: null,
        email: this.to
      };
    }else {
      confirmationInput = {
        emailConfirmationCode: null,
        phoneConfirmationCode: c.value,
        phone: this.to
      };
    }
    this.loading = true;
    return this.registerService.confirm(confirmationInput).pipe(
      map((confirmation) => {
        this.loading = false;
        if (this.confirm === 'email') {
          // @ts-ignore
          if (!confirmation.emailConfirmation) {
            return {confirmed: true};
          } else {
            this.$confirmed = true;
            this.confirmed.emit(confirmation);
            return null;
          }
        }else{
          // @ts-ignore
          if (!confirmation.phoneConfirmation) {
            return {confirmed: true};
          } else {
            this.$confirmed = true;
            this.confirmed.emit(confirmation);
            return null;
          }
        }

      })
    );
  }
  confirmationForm = this.fromBuilder.group({
    // @ts-ignore
    code: new FormControl('', Validators.min(6), this.codeValidator(this.registerService)),
  });
  ngOnInit(): void {
    console.log(this.to);
  }

  sendConfirmation() {
    let input;
    if (this.confirm === 'email'){
      input = {
        emailConfirmation: true,
        phoneConfirmation: false,
        email: this.to
      };
    }else {
      input = {
        emailConfirmation: false,
        phoneConfirmation: true,
        phone: this.to
      };
    }
    if (this.resend){
      this.resend = false;
      this.registerService.sendConfirmation(input).subscribe(
        (response) => {
          this.snackBar.open(this.translate.instant('Confirmation code is sent to your ') + this.confirm, '', {
            duration: 3000
          });
        },
        (err) => {
          this.snackBar.open(this.translate.instant('Sorry we could not send...'), '', {
            duration: 3000
          });
        }
      );
    }
    setTimeout(() => {
      this.resend = true;
    }, 60000);
  }
}
