import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-image-cropper-dialog',
  templateUrl: './image-cropper-dialog.component.html',
  styleUrls: ['./image-cropper-dialog.component.scss']
})
export class ImageCropperDialogComponent implements OnInit {
  images: any;
  ratio: any;
  imageWidth = 780;
  constructor(
    private matDialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) { }

  ngOnInit(): void {
    this.images = this.data.images;
    if (!!this.data?.ratio){
      this.ratio = this.data?.ratio.w / this.data?.ratio.h;
    }else{
      this.ratio = 4 / 3;
    }
    if (!!this.data?.imageWidth){
      this.imageWidth = this.data?.imageWidth;
    }else{
      this.imageWidth = 780;
    }
  }

  close($event: any) {
    this.matDialogRef.close({croppedImages: $event});
  }
}
