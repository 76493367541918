import {Component, OnInit, ViewChild, Input, Output, EventEmitter, Inject} from '@angular/core';
import {MatStepper} from '@angular/material/stepper';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {SelectProductsComponent} from '../../../product/dialogs/select-products/select-products.component';
import {ProductService} from '../../../product/service/product.service';
import {BankService} from '../../../payment/service/bank.service';
import {AdService} from '../../service/ad.service';
import {Router} from '@angular/router';
import {ImageCropperDialogComponent} from '../../../product/dialogs/image-cropper-dialog/image-cropper-dialog.component';
import {BankAccountsComponent} from '../../../payment/components/bank-accounts/bank-accounts.component';

@Component({
  selector: 'app-advertise-product',
  templateUrl: './advertise-product.component.html',
  styleUrls: ['./advertise-product.component.scss']
})
export class AdvertiseProductComponent implements OnInit {
  // @ViewChild('stepper') stepper: MatStepper;
  plans = null;
  selectedPlan = null;
  @Input() selectedProducts = [];
  @Input() maxHeight = '1080px';
  products;
  bankAccounts;
  files: any;
  price = 0;
  totalPrice = 0;
  priceOffer = 0;
  @Output() submit: EventEmitter<any> = new EventEmitter<any>();
  constructor(private dialog: MatDialog,
              public productService: ProductService,
              private bankService: BankService,
              private adService: AdService,
              private router: Router,
  ) { }

  ngOnInit(): void {
    this.bankAccounts = this.bankService.getBankAccounts();
    // this.products = this.productService.getProducts().subscribe(
    //   (data) => {
    //     this.selectedProducts = data;
    //   }
    // );
  }

  onSelectPackage($package: any) {
    this.plans = $package.node.adplanSet.edges;
  }
  OnSelectPlan(plan) {
    this.selectedPlan = plan;
    this.price = this.selectedPlan.node.price;
    // this.stepper.next();
  }

  addProduct() {
    this.dialog.open(SelectProductsComponent).afterClosed().subscribe(
      (response) => {
        if (!!response.selectedProducts && response.selectedProducts.length > 0){
          this.selectedProducts = this.selectedProducts.concat(response.selectedProducts.map(
            (e) => {
              e.selected = false;
              return e;
            }
          ));
          this.totalPrice += (this.price * this.selectedProducts.length);
          this.priceOffer = this._priceOffer(this.totalPrice);
        }
      }
    );
  }
  private _priceOffer(price) {
    return (10 * price ^ (-3 / 7)) + price / 3;
  }
  sumbit() {
    const $input = {
      plan: this.selectedPlan.node.id,
      products: this.selectedProducts.map((e) => e.node.id),
      banners: this.selectedProducts.map((e) => e.node.banner)
    };
    this.adService.advertise($input).subscribe(
      ({data}) => {
        this.submit.emit();
        this.router.navigate(['/payment/order']);
      }
    );
  }

  onSelect(event: any, product) {
    this.dialog.open(ImageCropperDialogComponent,
      {
        width: '580px',
        data: {
          images: event.addedFiles,
          ratio: {
            w: this.selectedPlan.node.bannerWRatio,
            h: this.selectedPlan.node.bannerHRatio
          }
        }
      }
    ).afterClosed().subscribe(
      (data) => {
        // this.files.push(...data.croppedImages);
        product.node.banner = data.croppedImages[0];
      }
    );
  }

  onRemove(f: any) {

  }

  bankAccountsDialog() {
    this.dialog.open(BankAccountsComponent);
  }
}
