
  <div style="margin: 0.5em">
    <mat-card-content style="text-align: center">
      <app-profile-pic size="100px"
                       [rounded]="!!business?.logo"
                       [pic]="business?.logo?business?.logo:'/assets/images/shopper.svg'"></app-profile-pic>
    </mat-card-content>
    <div style="padding: 0 1em">
      <mat-card-title style="font-size: 1.2em">{{business.name}}</mat-card-title>
      <mat-card-subtitle>{{business.businessType.name}} {{'business' | translate}}</mat-card-subtitle>
    </div>
  </div>
