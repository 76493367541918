import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FormBuilder, FormGroupDirective, Validators} from '@angular/forms';
import {BusinessService} from '../../../product/service/business.service';
import {TranslateService} from "@ngx-translate/core";
import {StorageService} from "../../../../core/services/storage.service";

@Component({
  selector: 'app-edit-business-phone-dialog',
  templateUrl: './edit-business-phone-dialog.component.html',
  styleUrls: ['./edit-business-phone-dialog.component.scss']
})
export class EditBusinessPhoneDialogComponent implements OnInit {
  phones = [];
  phoneForm = this.formBuilder.group({
    phone: ['', Validators.required]
  }) ;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private formBuilder: FormBuilder,
    private businessService: BusinessService,
    translate: TranslateService,
    private storageService: StorageService
  ) {

    translate.use(this.storageService.getLanguage('lang'));
  }

  ngOnInit(): void {
    this.phones = this.data.business.businessphoneSet.edges;
  }

  addPhone(formDirective: FormGroupDirective) {
    const businessPhoneInput = {
      phone: this.phoneForm.value.phone,
      business: this.data.business.id
    };
    this.businessService.addBusinessPhone(businessPhoneInput).subscribe((businessPhone) => {
      this.phones.push({
        node: businessPhone
      });
    });
    this.phoneForm.reset();
    formDirective.resetForm();
    this.phoneForm.get('phone').setErrors(null);
  }
  removePhone(businessPhone){
    const input = {
      phone: businessPhone.id
    };
    this.businessService.deleteBusinessPhone(input).subscribe(
      ($businessPhone) => {
        this.phones = this.phones.filter( e => e.node.phone !== $businessPhone.phone);
      }
    );
  }
}
