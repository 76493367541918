import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {BannerComponent} from './banner.component';
import {GalleryModule} from '@ngx-gallery/core';


@NgModule({
  declarations: [BannerComponent],
  exports: [BannerComponent],
  imports: [
    CommonModule,
    GalleryModule,
  ]
})
export class BannerModule { }
