<div class="container">
  <form [formGroup]="searchForm" (ngSubmit)="submit()">
    <mat-card class="mat-elevation-z0" style="margin-left: 1rem; margin-left: 1rem">
      <input name="search" class="search-input" style="background: transparent" #key [placeholder]="'Search Serdo' | translate" formControlName="search"/>
      <button mat-icon-button type="submit" name="search" aria-label="Search serdo" style="min-width: 50px; margin-left: 1em">
        <mat-icon style="opacity: .6">search</mat-icon>
      </button>

    </mat-card>
  </form>

</div>
