<h1>{{'Confrim your account'| translate}}</h1>
<div *ngIf="!!authService.phone() || verifyPhone">
  <app-confirm-user-form confirm="phone" (confirmed)="_confirmed('phone')"></app-confirm-user-form>
</div>
<div *ngIf="!!authService.email() || verifyEmail">
  <app-confirm-user-form confirm="email" (confirmed)="_confirmed('email')"></app-confirm-user-form>
</div>
<button
  mat-flat-button
  color="primary"
  *ngIf="canFinish"
  (click)="finish.emit(); matDialogRef.close(); dialog.getDialogById('confirmation')">{{'Finish'| translate}}</button>
