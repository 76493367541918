import { Injectable } from '@angular/core';
import {Apollo} from 'apollo-angular';
import {Observable} from 'rxjs';
import gql from 'graphql-tag';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdService {

  constructor(private apollo: Apollo) { }
  advertise(input): Observable<any>{
    const crateAdMutation = gql`
      mutation createAd($input: AdCreateMutationInput!){
        createAd(input: $input){
            ad{
              id
            }
        }
      }
    `;
    return this.apollo.mutate({
      mutation: crateAdMutation,
      variables: {
        input: input
      },
      context: {
        useMultipart: true
      }
    });
  }
  getBannerAd(pakage, categoryId = null): Observable<any>{
    const bannerAdQuery = gql`
      query adPaln($plan: String, $category: ID){
          adPlans(name: $plan, category: $category){
            edges{
              node{
                id
                name
                activeAds{
                  id
                  product{
                    id
                    name
                  }
                }
              }
            }
          }
        }
    `;
    return this.apollo.query({
      query: bannerAdQuery,
      variables: {
        plan: pakage,
        category: categoryId
      }
    }).pipe(
      map(({data}) => {
        // @ts-ignore
        const plans = data.adPlans.edges;
        if (plans.length === 0) {
          return [];
        }
        return plans.node.activeAds;
      })
    );
  }
  getPackageBannerAd(packageId, categoryId = null): Observable<any>{
    const bannerAdQuery = gql`
      query getPakageBannerAd($packageId: ID!){
        adPackage(id: $packageId){
                id
                name
                activeAds{
                  id
                  product{
                    id
                    name
                  }
                }
          }
        }
    `;
    return this.apollo.query({
      query: bannerAdQuery,
      variables: {
        packageId: packageId
      }
    }).pipe(
      map(({data}) => {
        // @ts-ignore
        return data.adPackage.activeAds.map((e) => {
          // if (e.banner.slice(0, 7) !== 'http://' && e.banner.slice(0, 8) !== 'https://'){
          //   e.banner = e.banner + "";
          // }
          // console.log(e);
          return e;
        });
      } )
    );
  }
  getAdPackageProducts($package, $category = null, product = true): Observable<any>{
    const adPackageProductsQuery = gql`
      query adPackageProductsQuery($name: String!, $category: ID, $product: Boolean!){
        adPackages(name: $name, category: $category){
          edges{
            node{
              id
              name
              products @include(if: $product){
                id
                name
                price
                productimageSet{
                  edges{
                    node{
                      id
                      image
                      imgUrl
                      imgTumbUrl
                    }
                  }
                }
              }
              activeAds{
                id
                product{
                  id
                  name
                  price
                  productimageSet{
                    edges{
                      node{
                        id
                        image
                        imgUrl
                        imgTumbUrl
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `;
    return this.apollo.query({
      query: adPackageProductsQuery,
      variables: {
        name: $package,
        category: $category,
        product: product
      }
    }).pipe(
      map(({data}) => {
            // @ts-ignore
            const adPackage = data.adPackages.edges[0].node;
            // console.log(data);
            if (product){
              adPackage.products = adPackage.products.map((e) => {
                e.node = e;
                return e;
              });
            }
            return adPackage;
        })
    );
  }
  getAds(adPackage = null, categoryDescendants = null): Observable<any>{
    const adPackageQuery = gql`
      query ads ($adPackage: ID, $categoryDescendants: ID){
        ads (adPackage: $adPackage, categoryDescendants: $categoryDescendants) {
          edges{
            node{
              id
              product{
                id
                name
              }

            }
          }
        }
      }
    `;
    return this.apollo.query({
      query: adPackageQuery,
      variables: {
        categoryDescendants: categoryDescendants,
        adPackage: adPackage
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.ads.edges)
    );
  }
  getBusinessAds(adPackage = null, category = null): Observable<any>{
    const BUSINESS_ADS_QUERY = gql `
      query businessAds($adPackage: ID!, $category: ID) {
        businessAds(plan_Package: $adPackage, plan_Category: $category){
          edges{
            node{
              id
              business{
                id
                name
                banner
              }
            }
          }
        }
      }
    `;
    return this.apollo.query({
      query: BUSINESS_ADS_QUERY,
      variables: {
        adPackage: adPackage,
        category: category,
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.businessAds.edges)
    );
  }
  getProductAds(adPackage = null, category = null): Observable<any>{
    const PRODUCT_ADS_QUERY = gql `
      query productAds($adPackage: ID, $category: ID) {
        productAds(plan_Package: $adPackage, plan_Category: $category){
          edges{
            node{
              id
              product{
                id
                name
                price
                productimageSet{
                  edges{
                    node{
                      id
                      image
                      imgUrl
                      imgTumbUrl
                    }
                  }
                }
              }
            }
          }
        }
      }
    `;
    return this.apollo.query({
      query: PRODUCT_ADS_QUERY,
      variables: {
        adPackage: adPackage,
        category: category
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.productAds.edges)
    );
  }
}
