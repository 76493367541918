import {Injectable} from '@angular/core';
import {Apollo} from 'apollo-angular';
import {Observable} from 'rxjs';
import gql from 'graphql-tag';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private apollo: Apollo) {
  }

  postProduct(input): Observable<any> {
    const postProductMutation = gql`
      mutation postProduct($input: ProductCreateMutationInput!){
        postProduct(input: $input){
          product{
            id
            name
          }
        }
      }`;
    return this.apollo.mutate({
      mutation: postProductMutation,
      variables: {
        input: input
      },
      context: {
        useMultipart: true
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.postProduct)
    );
  }

  productRatting(input): Observable<any> {
    const ratingtMutation = gql`
      mutation productRatting($input: RattingMutationInput!){
        productRatting(input: $input){
          productRatting{
              product{
                id
              }
              user{
                id
              }
              rate
            }
        }
      }`;
    return this.apollo.mutate({
      mutation: ratingtMutation,
      variables: {
        input: input
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.productRatting.productRatting)
    );
  }

  productReview(input): Observable<any> {
    const reviewMutation = gql`
      mutation productReview($input: ReviewMutationInput!){
        productReview(input: $input) {
          productReview{
            id
            review
            createdAt
            user{
              id
              name
            }
          }
        }
      }`;
    return this.apollo.mutate({
      mutation: reviewMutation,
      variables: {
        input: input
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.productReview.productReview)
    );
  }

  addProductImage(input): Observable<any> {
    const addProductImageMutation = gql `
      mutation addProductImage($input: AddProductImageMutationInput!) {
        addProductImage(input: $input){
          product{
            id
            name
            price
            description
            views
            rate
            isNegotiable
            isService
            isRental
            isOwner
            isTobeImported
            createdAt
            productimageSet{
              edges{
                node{
                  id
                  image
                  imgUrl
                  imgTumbUrl
                  imgWebpUrl
                  imgTumbWebpUrl
                }
              }
            }
          }
        }
      }
    `;
    return this.apollo.mutate({
      mutation: addProductImageMutation,
      variables: {
        input: input
      },
      context: {
        useMultipart: true
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.addProductImage)
    );
  }

  removeProductImage(input): Observable<any> {
    const addProductImageMutation = gql `
      mutation addProductImage($input: RemoveProductImageMutationInput!) {
        removeProductImage(input: $input){
          removedImages{
            id
            imgTumbUrl
            imgUrl
            imgWebpUrl
            imgTumbWebpUrl
            product{
              id
              name
            }
          }
        }
      }
    `;
    return this.apollo.mutate({
      mutation: addProductImageMutation,
      variables: {
        input: input
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.removeProductImage)
    );
  }

  productCategories(): Observable<any> {
    const productCategories = gql`
      query productCategories{
        productCategories{
          edges{
            node{
              id
              name
              productcategorySet{
                edges{
                  node{
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }`;
    return this.apollo.query({
      query: productCategories,
    }).pipe(
      // @ts-ignore
      map(({data}) => data.productCategories.edges)
    );
  }

  getProducts(first = 25, orderBy = null, categoryDescendent = null): Observable<any> {
    const productsQuery = gql`
      query($first: Int, $orderBy: String, $categoryDecendent: ID){
        products(first: $first, orderBy: $orderBy, categoryDecendent: $categoryDecendent){
          edges{
            node{
              id
              name
              price
              views
              rate
              description
              productimageSet{
                edges{
                  node{
                    id
                    image
                    imgUrl
                    imgTumbUrl
                    imgWebpUrl
                    imgTumbWebpUrl
                  }
                }
              }
            }
          }
        }
      }`;
    return this.apollo.query({
      query: productsQuery,
      variables: {
        first: first,
        orderBy: orderBy,
        categoryDecendent: categoryDescendent
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.products.edges)
    );
  }

  getProduct(id: string): Observable<any> {
    const productsQuery = gql`
      query getProduct($id: ID!){
        product(id: $id){
              id
              name
              price
              description
              views
              rate
              isNegotiable
              isService
              isRental
              isOwner
              isTobeImported
              createdAt
              category{
                id
                name
                parentCategory{
                  id
                  name
                }
              }
              productpropertySet{
                edges{
                  node{
                    id
                    categoryProperty{
                      id
                      name
                      unit
                    }
                    value
                  }
                }
              }
              productimageSet{
                edges{
                  node{
                    id
                    image
                    imgUrl
                    imgTumbUrl
                    imgWebpUrl
                    imgTumbWebpUrl
                  }
                }
              }
              business{
                id
                name
                email
                telegram
                whatsapp
                latitude
                longitude
                logo
                banner
                businessType{
                  id
                  name
                }
                businessphoneSet{
                  edges{
                    node{
                      id
                      phone
                    }
                  }
                }
                users{
                  edges{
                    node{
                      id
                      name
                      referredBy {
                        id
                        name
                      }
                    }
                  }
                }
              }
              userproductreviewSet{
                edges{
                  node{
                    id
                    review
                    createdAt
                    user {
                      id
                      name
                    }
                  }
                }
              }
        }
      }`;
    return this.apollo.query({
      query: productsQuery,
      variables: {
        id: id
      }
    }).pipe(
      map(({data}) => {
        // console.log(data);
        // @ts-ignore
        return {node: data.product};
      })
    );
  }

  getBusinessProduct(businessId, orderBy = null): Observable<any> {
    const businessProductQuery = gql`
      query getBusinessProducts($business: ID!, $orderBy: String,){
        products(business: $business,  orderBy: $orderBy){
          edges{
            node{
              id
              name
              price
              productimageSet{
                edges{
                  node{
                    id
                    image
                    imgUrl
                    imgTumbUrl
                  }
                }
              }
            }
          }
        }
      }
    `;
    return this.apollo.query({
      query: businessProductQuery,
      variables: {
        business: businessId,
        orderBy: orderBy,
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.products.edges)
    );
  }

  getCategoryProduct(categoryId): Observable<any> {
    const categoryProductQuery = gql`
      query($category: ID!){
        productCategory(id: $category){
              id
              name
              icon
              productSet{
                edges{
                  node{
                    id
                    name
                    price
                    productimageSet{
                      edges{
                        node{
                          id
                          image
                          imgUrl
                          imgTumbUrl
                          imgWebpUrl
                          imgTumbWebpUrl
                        }
                      }
                    }
                  }
                }
              }
            }
        }
    `;
    return this.apollo.query({
      query: categoryProductQuery,
      variables: {
        category: categoryId
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.productCategory)
    );
  }

  deleteProduct(input): Observable<any> {
    const deleteProductMutation = gql`
      mutation deleteProduct($input: DeleteProductMutationInput!){
        deleteProduct(input:$input){
          product{
            id
            name
          }
        }
      }
    `;
    return this.apollo.mutate({
      mutation: deleteProductMutation,
      variables: {
        input: input
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.product )
    );
  }

  updateProduct(product: any) {
    const postProductMutation = gql`
      mutation postProduct($input: UpdateProductMutationInput!){
        updateProduct(input: $input){
          product{
            id
            name
          }
        }
      }`;
    return this.apollo.mutate({
      mutation: postProductMutation,
      variables: {
        input: product
      },
      context: {
        useMultipart: true
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.updateProduct)
    );
  }

  advertiseProducts(input): Observable<any> {
    const ADVERTISE_PRODUCT_MUTATION = gql `
        mutation advertiseProduct($input: AdvertiseProductMutationInput!){
          advertiseProduct(input: $input){
            productAds {
              id
            }
          }
        }
    `;
    return this.apollo.mutate({
      mutation: ADVERTISE_PRODUCT_MUTATION,
      variables: {
        input: input
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.advertiseProduct.productAds)
    );
  }

  changeCategory(product, category): Observable<any>{
    const CHANGE_CATEGORY_MUTATION = gql`
      mutation changeProductCategory($input: ChangeProductCategoryMutationInput!){
        changeProductCategory(input: $input){
          product{
            id
            name
          }
        }
      }
    `;
    const input = {
      product: product,
      category: category
    };
    return this.apollo.mutate( {
      mutation: CHANGE_CATEGORY_MUTATION,
      variables: {
        input: input
      }
    }).pipe(
      // @ts-ignore
      map(({data}) => data.changeProductCategory.product)
    );
  }

  saveProduct(id: any) {
    const SAVE_PRODUCT_MUTATION = gql`
      mutation SaveProductMutaion($input: SaveProductMutationInput!){
        saveProduct(input: $input){
          productReview{
            id
            isSaved
          }
        }
      }
    `;
    return this.apollo.mutate({
      mutation: SAVE_PRODUCT_MUTATION,
      variables: {input: {product: id}}
    });
  }
}
