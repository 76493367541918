<h1 class="mat-h1" style="margin-left: 2em">{{title}}</h1>
<p>{{text}}</p>
<div class="flex" style="justify-content: space-around">
  <button mat-flat-button
          (click)="matDialogRef.close({
                      confirmation: false
                    });">Cancel</button>
  <button mat-flat-button color="primary"
          (click)="matDialogRef.close({
                confirmation: true
              });">Ok</button>
</div>
