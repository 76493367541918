import {Component, EventEmitter, Output, OnInit, ViewChild, AfterViewInit, ElementRef} from '@angular/core';
import {Observable} from 'rxjs';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {map, shareReplay} from 'rxjs/operators';
import {Router} from '@angular/router';

@Component({
  selector: 'app-left-nav-drawer',
  templateUrl: './left-nav-drawer.component.html',
  styleUrls: ['./left-nav-drawer.component.scss']
})
export class LeftNavDrawerComponent implements OnInit, AfterViewInit {
  @ViewChild('drawer') drawer;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(private elt: ElementRef, private breakpointObserver: BreakpointObserver, private router: Router) {
    this.router.events.subscribe(event => {
      this.isHandset$.subscribe((isHandset) => {
        if (isHandset){
          this.drawer.close();
        }
      });
    });
  }

  ngOnInit(): void {
  }
  ngAfterViewInit() {
    const textNode = this.elt.nativeElement.childNodes[0].childNodes;
  }
  public open(): void{
    this.drawer.toggle();
  }
}
