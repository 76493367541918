<div class="flex" style="flex-flow: row-reverse">
  <button mat-button (click)="dropzone.showFileSelector()"><mat-icon>backup</mat-icon> Upload</button>
</div>
<div class="flex" id="profile-pic-container">
  <ngx-dropzone
    #dropzone
    (change)="onSelect($event)"
    [accept]="'image/*'"
    [disableClick]="true"
    class="flex-col"
    id="dropzone"
    style="width: 100%; height: 100%; padding: 1.5em 0">
  </ngx-dropzone>
  <div class="flex" id="images-container" style="flex-flow: wrap; justify-content: center;z-index: 1000">
    <div class="flex-col" *ngFor="let image of product?.node.productimageSet.edges" style="margin: 0 8px">
      <img [src]="image.node.imgTumbUrl" style="min-height: 140px; height: 140px;margin: 10px">
      <button mat-stroked-button (click)="remove(image.node.id)"><mat-icon>delete</mat-icon> Remove</button>
    </div>
    <div class="flex-col" *ngFor="let f of files" style="margin: 0 8px">
      <ngx-dropzone-image-preview
        ngProjectAs="ngx-dropzone-preview"
        [file]="f">
        <ngx-dropzone-label>{{ f.name }}</ngx-dropzone-label>
      </ngx-dropzone-image-preview>
<!--      <img [src]="f" style="min-height: 140px; height: 140px;margin: 10px">-->
      <button mat-stroked-button style="opacity: .65"><mat-icon>backup</mat-icon> Loading...</button>
    </div>
  </div>
</div>
