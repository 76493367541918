<div class="flex" style="justify-content: space-evenly; margin-top: 1em;">
  <mat-card
    *ngFor="let resume of resumes"
    (click)="selected(resume)"
    class="flex-col"
    style="align-items: center; width: 120px; text-align: center">
    <img [src]="'/assets/images/resume.svg'" style="width: 80px">
    <h3><b>{{resume.node.name}}</b></h3>
  </mat-card>
</div>

