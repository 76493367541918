<div class="flex-col">
  <p style="margin-bottom: 0">{{'Category'| translate}}</p>
  <mat-chip-list>
    <div style="display: flex; flex-flow: nowrap; overflow-x: scroll">
      <mat-chip (click)="selectAll()"><span style="white-space: nowrap">{{'All Categories'| translate}}</span></mat-chip>
      <mat-chip
        (click)="selectCategory(category)"
        [class.selected]="category.selected"
        [class.not-selected]="!category.selected"
        *ngFor="let category of categories">
        <span style="white-space: nowrap">{{category.node.name}}</span>
      </mat-chip>
    </div>
  </mat-chip-list>
  <div class="flex-col" id="price-range">
    <p style="margin-top: 2.5em">{{'Price'|translate}}</p>
    <div class="flex custom-slider">
      <ngx-slider [(value)]="range.min" [(highValue)]="range.max" [options]="options" (valueChange)="rangeChange()" (highValueChange)="rangeChange()"></ngx-slider>
    </div>
  </div>

</div>

