import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PossibleValuesDialogComponent} from "./possible-values-dialog.component";
import {PossibleValuesListModule} from "../../list/possible-values-list/possible-values-list.module";



@NgModule({
  declarations: [PossibleValuesDialogComponent],
  exports: [PossibleValuesDialogComponent],
  imports: [
    CommonModule,
    PossibleValuesListModule
  ]
})
export class PossibleValuesDialogModule { }
