import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TelegramLoginComponent } from './telegram-login.component';
import {AngularTelegramLoginWidgetModule} from 'angular-telegram-login-widget';



@NgModule({
    declarations: [
        TelegramLoginComponent
    ],
    exports: [
        TelegramLoginComponent
    ],
    imports: [
        CommonModule,
        AngularTelegramLoginWidgetModule,
    ]
})
export class TelegramLoginModule { }
