import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {VacancyFormDialogComponent} from "./vacancy-form-dialog.component";
import {VacancyFormModule} from "../../form/vacancy-form/vacancy-form.module";
import {LangModule} from "../../../../lang.module";



@NgModule({
  declarations: [VacancyFormDialogComponent],
  exports: [VacancyFormDialogComponent],
  imports: [
    CommonModule,
    VacancyFormModule,
    LangModule
  ]
})
export class VacancyFormDialogModule { }
