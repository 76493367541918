import {Component, Inject, OnInit} from '@angular/core';
import {ResumeService} from '../../service/resume.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {TranslateService} from "@ngx-translate/core";
import {StorageService} from "../../../../core/services/storage.service";

@Component({
  selector: 'app-select-resume-dialog',
  templateUrl: './select-resume-dialog.component.html',
  styleUrls: ['./select-resume-dialog.component.scss']
})
export class SelectResumeDialogComponent implements OnInit {
  resumes;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public router: Router,
    private resumeService: ResumeService,
    private matDialogRef: MatDialogRef<any>,
    translate: TranslateService , private storageService: StorageService
  ) {

    translate.use(this.storageService.getLanguage('lang'));
  }

  ngOnInit(): void {
    this.resumeService.myResume().subscribe(
      (me) => {
        this.resumes = me.resumeSet.edges;
      }
    );
  }

  select($event: any) {
    this.matDialogRef.close($event);
  }

  build() {
    this.router.navigate(
      ['/resume/build'],  { queryParams: {plan: this.data.plan}});
    this.matDialogRef.close();
  }
}
