<div>
  <h1 class="mat-h1" style="margin-bottom: .4em;margin-right: 0;font-family: 'Lobster', cursive;text-align: center;">{{'Sign up' | translate}}</h1>
  <form [formGroup]="signupForm" (ngSubmit)="submit()" [class.loading-form]="isLoading">
    <div class="flex-col">
      <h3>
        <mat-error>{{error}}</mat-error>
      </h3>
      <mat-form-field appearance="outline">
        <mat-label>{{'Name'| translate}}</mat-label>
        <input matInput placeholder="Name" formControlName="name" required>
        <mat-icon matSuffix>user</mat-icon>
        <mat-error *ngIf="signupForm.controls['name'].hasError('required')">
          {{'Name is required.'| translate}}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{'Phone number'| translate}}</mat-label>
        <input matInput placeholder="Enter your phone number" formControlName="phone" required>
        <mat-icon matSuffix>phone</mat-icon>
        <mat-error *ngIf="signupForm.controls['phone'].hasError('required')">
          {{'Phone number is required.'| translate}}
        </mat-error>
        <mat-error *ngIf="signupForm.controls['phone'].hasError('exist')">
          {{'This phone number already exist'| translate}}
        </mat-error>
        <mat-error *ngIf="signupForm.controls['phone'].hasError('pattern')">
          {{'Invalid phone number.'| translate}}
        </mat-error>
      </mat-form-field>
      <div class="flex" id="password">
        <mat-form-field style="width: 48%" appearance="outline">
          <mat-label>{{'Password'| translate}}</mat-label>
          <input matInput minLength='6' type="password" [placeholder]="'Enter your password'| translate" formControlName="password" required>
          <mat-icon matSuffix>key</mat-icon>
          <mat-error *ngIf="signupForm.controls['password'].hasError('required')">
            {{'Password is required.'| translate}}
          </mat-error>
          <mat-error *ngIf="signupForm.controls['password'].hasError('minLength')">
            {{'Password must be more than 6 characters' | translate}}
          </mat-error>
        </mat-form-field>

        <mat-form-field style="width: 48%" appearance="outline">
          <mat-label>{{'Confirm password'| translate}}</mat-label>
          <input matInput type="password" [placeholder]="'Confirm password'| translate" formControlName="confirmPassword" required>
          <mat-icon matSuffix>key</mat-icon>
          <mat-error *ngIf="signupForm.controls['confirmPassword'].hasError('required')">
            {{'Confirm password is required.' | translate}}
          </mat-error>
          <mat-error *ngIf="signupForm.controls['confirmPassword'].hasError('match')">
            {{'Confirmation doesn\'t match.'| translate}}
          </mat-error>
        </mat-form-field>
      </div>
      <mat-form-field class="example-full-width">
        <mat-label>{{'Who told you about serdo ? Enter phone number.(optional)'| translate}}</mat-label>
        <input
          type="text"
          matInput
          [matAutocomplete]="auto"
          formControlName="referredBy"
          (focusout)="referredByFocusOut($event)">
        <mat-error *ngIf="!!selectedUserError">{{selectedUserError}}</mat-error>
        <mat-hint>{{'Please mention who told you about serdo.Your friend will get credits.'| translate}}</mat-hint>
        <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption (optionSelected)="referredBy($event)">
          <mat-option *ngFor="let user of filteredUsers" [value]="user.node">
            <div class="flex" style="justify-content: space-between">
              <span>{{user.node.name}}</span>
              <span class="secondery">{{user.node.phone}}</span>
            </div>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <div class="flex flat-card" style="margin-top: 1em" *ngIf="selectedUser">
        <div class="flex">
          <app-profile-pic [upload]="false" [pic]="selectedUser?.profilePic" size="30px"></app-profile-pic>
          <div class="flex-col" style="margin-left: 1em">
            <b>{{selectedUser?.name}}</b>
            <i>{{selectedUser?.phone}}</i>
          </div>
        </div>
      </div>
      <div class="flex" style="flex-flow: row-reverse; margin-top: .5em">
        <button mat-flat-button type="submit" color="primary" [disabled]="signupForm.invalid">{{'Sign up'| translate}}</button>
        <button mat-flat-button (click)="dialog.getDialogById('login-or-signup-dialog').close({login:false})">{{'Cancel'| translate}}</button>
      </div>
      <a mat-button (click)="goLogin.emit()" color="link">{{'Login'| translate}}</a>
    </div>
  </form>
</div>

