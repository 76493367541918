<footer class="flex-col" style="background-color: #282828; padding: 1.5rem 0.5rem; margin-top: 1.3rem; padding-bottom: 2.5rem">
  <div class="flex" style="flex-flow: wrap">
<!--    <div class="flex-col" style="flex: 1; border-right: 1px solid #ccc; padding-right: 3rem">-->
<!--      <h1 style="margin-bottom: 0">{{'Serdo'| translate}}</h1>-->
<!--      <i style="margin-bottom: 1.3rem">{{'Any time, Any where!'| translate}}</i>-->
<!--      <p>{{'We are trusted, reliable and cost-effective classified site for the widest range of quality product and services. Serdo.com is all about you- our aim is to empower every person in the country to independently connect with buyers and sellers online.'| translate}}</p>-->
<!--    </div>-->
    <div class="flex-col" style="flex: 4; margin-left: 1.3rem; margin-bottom: 2rem" >
      <div class="flex" style="justify-content: space-between; flex-flow: wrap">
        <div class="flex-col">
          <h2>{{'Company info'| translate}}</h2>
          <ul style="list-style: none">
            <li><a routerLink="/about">{{'About us' | translate}}</a></li>
            <li><a routerLink="/about/terms and conditions">{{'Terms and conditions' | translate}}</a></li>
            <li><a routerLink="/about/contact us">{{'Contact us' | translate}}</a></li>
            <li><a routerLink="/about/faq">{{'FAQ' | translate}}</a></li>
          </ul>
        </div>
        <div class="flex-col">
          <h2>{{'Sell on Serdo'| translate}}</h2>
          <ul style="list-style: none">
            <li><a routerLink="/product/post ad">{{'Post a product'| translate}}</a></li>
            <li><a routerLink="/product/categories">{{'Product categories'| translate}}</a></li>
          </ul>
          <h2>{{'Tender'| translate}}</h2>
          <ul style="list-style: none">
            <li><a routerLink="/tender">{{'All tender'| translate}}</a></li>
            <li><a routerLink="/pricing/tender">{{'Subscription'| translate}}</a></li>
          </ul>
          <h2>{{'Job seeker'| translate}}</h2>
          <ul style="list-style: none">
            <li><a routerLink="/job/category/list/all">{{'Find jobs'| translate}}</a></li>
            <li><a routerLink="/pricing/job">{{'Subscription'| translate}}</a></li>
            <li><a routerLink="/resume">{{'Build resume' | translate}}</a></li>
          </ul>
          <h2>{{'Employer'| translate}}</h2>
          <ul style="list-style: none">
            <li><a routerLink="/job/post">{{'Post a job'| translate}}</a></li>
            <li><a routerLink="/pricing/job">{{'Subscription'| translate}}</a></li>
          </ul>
        </div>
        <div class="flex-col">
          <h2>{{'Advertise with us'| translate}}</h2>
          <ul style="list-style: none">
            <li><a routerLink="/ad/package/business?redirectTo=business">{{'Business advertisement subscription'| translate}}</a></li>
            <li><a routerLink="/ad/package/product?redirectTo=product">{{'Product advertisement subscription'| translate}}</a></li>
            <li><a routerLink="/pricing/tender">{{'Tender subscription'| translate}}</a></li>
            <li><a routerLink="/pricing/job">{{'Job subscription'| translate}}</a></li>
          </ul>
        </div>
        <div class="flex-col">
          <h2>{{'Contacts'| translate}}</h2>
          <ul style="list-style: none">
            <li><button mat-button><mat-icon style="margin-right: .8em">mail</mat-icon> info@serdo.com</button></li>
            <li><button mat-button><mat-icon style="margin-right: .8em">phone</mat-icon> +251983252525</button></li>
            <li><button mat-button><mat-icon style="margin-right: .8em">phone</mat-icon> 9596</button></li>
          </ul>

          <h2>{{'Join us on (links)' | translate}}</h2>
          <ul style="list-style: none">
            <li><button mat-button><mat-icon style="margin-right: .8em">facebook</mat-icon> <a href="https://www.facebook.com/serdo.com.ads">
              {{'Facebook'| translate}}</a></button></li>
            <li><button mat-button><mat-icon style="margin-right: .8em">send</mat-icon> <a href="https://t.me/serdojobs">{{'Telegram'| translate}}</a></button></li>
            <li><button mat-button><mat-icon fontSet="fab" fontIcon="fa-instagram" style="margin-right: .8em"></mat-icon> <a href="https://www.instagram.com/serdo_com/">{{'Instagram'| translate}}</a></button></li>
            <li>
              <a href="https://play.google.com/store/apps/details?id=com.serdo.app">
                <img style="width: 180px; border-radius: 15px" rel="download app" src="/assets/images/playstore.png"/>
              </a>
            </li>
          </ul>
        </div>

        </div>
    </div>
  </div>
</footer>
