import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfilePicComponent } from './profile-pic.component';
import {MatRippleModule} from '@angular/material/core';
import {NgxDropzoneModule} from 'ngx-dropzone';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';



@NgModule({
  declarations: [ProfilePicComponent],
  exports: [
    ProfilePicComponent
  ],
  imports: [
    CommonModule,
    MatRippleModule,
    NgxDropzoneModule,
    MatIconModule,
    MatProgressSpinnerModule
  ]
})
export class ProfilePicModule { }
