<div class="flex-col">
  <div class="flex-col mat-flat-button mat-primary">
    <div class="flex" style="justify-content: space-around;height: 24px">
      <img src="/assets/images/ad.svg" style="width: 50px; height: 50px;transform: translateY(-36px)"/>
    </div>
    <mat-card-title style="white-space: pre-wrap;">{{package.node.name}}</mat-card-title>
  </div>
  <mat-card-subtitle style="padding: 1.1em">
    <p>
      {{ package.node.description }}
    </p>
  </mat-card-subtitle>
</div>
