import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CategoryService} from '../../../product/service/category.service';
import { Options } from '@m0t0r/ngx-slider';
import {AuthService} from "../../../auth/service/auth.service";
import {TranslateService} from "@ngx-translate/core";
import {StorageService} from "../../../../core/services/storage.service";
@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss']
})
export class SearchFilterComponent implements OnInit {
  @Output() categoryFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() filterRange: EventEmitter<any> = new EventEmitter<any>();
  @Output() cityChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() range = {
    min: 0,
    max: 100000
  };
  $filterRange = {
    min: this.range.min,
    max: this.range.max
  };
  categories;
  selectedCategories = [];
  all = false;
  customStyleRange: any;
  minValue: number = 0;
  maxValue: number = 100000;
  options: Options = {
    floor: 0,
    ceil: 100000
  };
  cities: any;

  constructor(private categoryService: CategoryService, private authService: AuthService,
              translate: TranslateService , private storageService: StorageService
  ) {
    this.cities = this.authService.cities();
    translate.use(this.storageService.getLanguage('lang'));
  }

  ngOnInit(): void {
    this.categoryService.getCategories(false).subscribe(
      (categories) => {
        this.categories = categories;
        this.selectAll();
      }
    );
  }
  selectCategory(category): void{
    if (this.all){
      this.selectAll(false);
    }
    const filteredCategories = this.selectedCategories.filter(
      (e) => {
        if (e.node.id === category.node.id){
          category.selected = false;
          return false;
        }else{
          return true;
        }
      }
    );
    if (this.selectedCategories.length === filteredCategories.length){
      category.selected = true;
      this.selectedCategories.push(category);
    }else{
      this.selectedCategories = filteredCategories;
    }
    this.categoryFilter.emit(this.selectedCategories);
  }

  selectAll(select = true) {
    if (select){
      this.selectedCategories = [];
      this.categoryFilter.emit(this.selectedCategories);
    }
    this.all = select;
    this.categories.map(
      (e) => {
        e.selected = select;
      }
    );
  }
  formatLabel(value){
    return value;
  }

  min(value: number) {
    this.$filterRange.min = value;
    this.filterRange.emit(this.$filterRange);
  }
  max(value: number) {
    this.$filterRange.max = value;
    this.filterRange.emit(this.$filterRange);
  }

  rangeChange() {
    this.filterRange.emit(this.range);
  }

  onSelectChange($event: any) {
    this.cityChange.emit($event);
  }
}
