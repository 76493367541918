import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ProductDialogComponent} from "./product-dialog.component";
import {ProductModule} from "../../form/product/product.module";



@NgModule({
  declarations: [ProductDialogComponent],
  exports: [ProductDialogComponent],
  imports: [
    CommonModule,
    ProductModule
  ]
})
export class ProductDialogModule { }
