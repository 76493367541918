import {Component, EventEmitter, OnInit, Output, Input} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {AuthService} from '../service/auth.service';

@Component({
  selector: 'app-login-or-signup',
  templateUrl: './login-or-signup.component.html',
  styleUrls: ['./login-or-signup.component.scss']
})
export class LoginOrSignupComponent implements OnInit {
  @Input() isLogin = true;
  @Output() success: EventEmitter<any> = new EventEmitter<any>();
  constructor() { }
  ngOnInit(): void {
  }

  goSignUp() {
    this.isLogin = false;
  }

  goLogin() {
    this.isLogin = true;
  }
}
